import {useTranslation} from "react-i18next";
import {Grid, Stack, Typography} from "@mui/material";
import {useCallback, useMemo, useState} from "react";

import {MakerReportableSummaryFragment} from "~/api/generated";
import {BmSecondaryDynamicSelect} from "~/components/inputs";
import {BmPrimaryButton, BmSecondaryButton} from "~/components/buttons";

import {geMonthOptions, getYearOptions} from "./helpers";

export interface SalesReportDateFormProps {
  makerReporttables: MakerReportableSummaryFragment[];
  onFiltersChange: (value: string) => void;
  onCancel: VoidFunction;
}

export const SalesReportDateFilter: React.FC<SalesReportDateFormProps> = ({
  makerReporttables,
  onFiltersChange,
  onCancel,
}) => {
  const {t} = useTranslation();

  const [year, setYear] = useState<string>("");
  const [month, setMonth] = useState<string>("");

  const yearOptions = useMemo(() => getYearOptions(makerReporttables), [makerReporttables]);
  const monthOptions = useMemo(() => geMonthOptions(makerReporttables), [makerReporttables]);

  const showReportHandler = useCallback(() => {
    onFiltersChange(`${year} – ${month}`);
  }, [year, month, onFiltersChange]);

  return (
    <Stack spacing={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <BmSecondaryDynamicSelect
            name="year"
            label={t("labels.year")}
            value={year}
            options={yearOptions}
            onChange={(e) => setYear(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BmSecondaryDynamicSelect
            name="month"
            label={t("labels.month")}
            value={month}
            options={monthOptions}
            onChange={(e) => setMonth(e.target.value)}
          />
        </Grid>
      </Grid>

      <Typography variant="body0">{t("reports.tax_warehouse.sales_report_modal.helper_text")}</Typography>

      <Stack direction="row" spacing={2} useFlexGap>
        <BmPrimaryButton onClick={showReportHandler}>{t("buttons.show_report")}</BmPrimaryButton>
        <BmSecondaryButton onClick={onCancel}>{t("buttons.cancel")}</BmSecondaryButton>
      </Stack>
    </Stack>
  );
};
