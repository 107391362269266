import {omit} from "ramda";
import {utcToZonedTime} from "date-fns-tz";
import {isValid} from "date-fns";

import {OrderInvoiceSearchInput, OrderKind} from "~/api/generated";
import {CUSTOMER_COUNTRIES} from "~/constants/countries";
import {getEnumValues} from "~/utils/enums";

import {useBackendFiltering} from "../useBackendFiltering";

const allowedFilters: (keyof OrderInvoiceSearchInput)[] = ["fromDate", "toDate", "kind", "country"];

export const searchParamsToAppliedInvoicesFilters = (searchParams: URLSearchParams): OrderInvoiceSearchInput => {
  const nextAppliedInvoicesFilters = ([...searchParams] as [keyof OrderInvoiceSearchInput, any][])
    .filter(([searchParamKey, searchParamValue]) => {
      switch (searchParamKey) {
        case "fromDate":
        case "toDate":
          return isValid(utcToZonedTime(searchParamValue, "GMT"));
        case "kind":
          return getEnumValues(OrderKind).includes(searchParamValue);
        case "country":
          return CUSTOMER_COUNTRIES.map(({COUNTRY_CODE}) => COUNTRY_CODE).includes(searchParamValue);
        default:
          return false;
      }
    })
    .reduce((filters, [searchParamKey, searchParamValue]) => {
      return {
        ...filters,
        [searchParamKey]: ["kind", "country"].includes(searchParamKey)
          ? [...(filters[searchParamKey] || []), searchParamValue]
          : searchParamValue,
      };
    }, {} as OrderInvoiceSearchInput);

  const {fromDate, toDate} = nextAppliedInvoicesFilters;
  return [fromDate, toDate].every(Boolean)
    ? nextAppliedInvoicesFilters
    : omit(["fromDate", "toDate"], nextAppliedInvoicesFilters);
};

export const useBackendInvoicesFiltering = (): [
  OrderInvoiceSearchInput,
  (filters: OrderInvoiceSearchInput) => void,
] => {
  const [appliedFilters, onFiltersChange] = useBackendFiltering<OrderInvoiceSearchInput>(
    allowedFilters,
    searchParamsToAppliedInvoicesFilters,
  );

  return [appliedFilters, onFiltersChange];
};
