import get from "lodash.get";
import {useController} from "react-hook-form";
import {EditorComponent, Remirror, useEditorEvent, useRemirror} from "@remirror/react";
import Box from "@mui/material/Box";
import {AnyExtension, RemirrorEventListenerProps, isDocNodeEmpty} from "remirror";
import {cx} from "@emotion/css";
import {useCallback, useMemo, useRef} from "react";
import "remirror/styles/all.css";

import {BmHelperText} from "~/components/helperText";
import {useToggle} from "~/hooks";

import {ControlledInputProps} from "../_common/types";
import {wysiwygPreset} from "./helpers/remirrorWysiwygPreset";
import {BmRichTextToolbar} from "./private/RichTextToolbar/RichTextToolbar";
import {styles} from "./RichTextEditor.styles";
import {BmRichTextEditorContextProvider, useBmRichTextEditorContext} from "./RichTextEditor.context";
import {BmFloatingLinkToolbar} from "./private/FloatingLinkToolbar/FloatingLinkToolbar";
import {getCustomSerializer} from "./helpers/customSerializer";

const hooks = [
  () => {
    const {toggleFocus} = useBmRichTextEditorContext();
    useEditorEvent("focus", () => toggleFocus(true));
    useEditorEvent("blur", () => toggleFocus(false));
  },
];

export type RichTextEditorParsedValueType = {
  htmlDescription?: string;
  jsonDescription?: string;
};

export interface BmRichTextEditorProps {
  initialValue?: string;
  label: string;
  helperText?: string;
  error?: boolean;
  onChange: (value: string | null) => void;
}

export const BmRichTextEditor: React.FC<BmRichTextEditorProps> = ({
  initialValue,
  label,
  helperText,
  error,
  onChange,
}) => {
  const jsonDescription = initialValue ? JSON.parse(JSON.parse(initialValue).jsonDescription) : undefined;
  const [isFocused, , toggleFocus] = useToggle(false);
  const {manager, state, setState} = useRemirror({
    extensions: () => [...wysiwygPreset({selectTextOnClick: true, placeholder: label})],
    content: jsonDescription,
    selection: "start",
    stringHandler: "html",
  });

  const isInitialRender = useRef(true);
  const isEmpty = useMemo(() => isDocNodeEmpty(state.doc), [state.doc]);

  const handleChange = useCallback(
    (parameter: RemirrorEventListenerProps<AnyExtension>) => {
      if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
      }

      setState(parameter.state);
      const customSerializer = getCustomSerializer(parameter.state.schema);
      const nextValue: RichTextEditorParsedValueType = {
        jsonDescription: JSON.stringify(parameter.state.doc.toJSON()),
        htmlDescription: (
          customSerializer.serializeFragment(
            parameter.state.doc.content,
            {},
            document.createElement("div"),
          ) as HTMLElement
        ).innerHTML,
      };
      onChange(isDocNodeEmpty(parameter.state.doc) ? null : JSON.stringify(nextValue));
    },
    [onChange, setState],
  );

  return (
    <BmRichTextEditorContextProvider value={{toggleFocus}}>
      <Box>
        <Box className={cx("remirror-theme", styles.root(isFocused, isEmpty, !!error))}>
          {/* the className is used to define css variables necessary for the editor */}
          <Remirror manager={manager} state={state} onChange={handleChange} hooks={hooks}>
            <BmRichTextToolbar />
            <BmFloatingLinkToolbar />
            <EditorComponent />
          </Remirror>
        </Box>
        {helperText && <BmHelperText error={error}>{helperText}</BmHelperText>}
      </Box>
    </BmRichTextEditorContextProvider>
  );
};

export interface BmControlledRichTextEditorProps
  extends ControlledInputProps,
    Omit<BmRichTextEditorProps, "onChange" | "value" | "error"> {}

export const BmControlledRichTextEditor: React.FC<BmControlledRichTextEditorProps> = ({
  name,
  control,
  rules,
  helperText,
  ...restProps
}) => {
  const {
    field: {ref: _, value, ...field},
    fieldState: {error},
  } = useController({name, control, rules});

  const handleOnChange = useCallback(
    (value: string | null) => {
      field.onChange(value);
    },
    [field],
  );

  return (
    <BmRichTextEditor
      {...field}
      {...restProps}
      initialValue={value}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
