import {TablePagination} from "@mui/material";
import {useCallback, useEffect, useMemo, useState} from "react";

import {noop} from "~/utils/noop";
import {getClosestNumber} from "~/utils/numbers";

import {useBmTablePaginationContext} from "./TablePagination.context";
import {styles} from "./TablePagination.styles";

export const PER_PAGE_OPTIONS = [10, 25, 50];
export const DEFAULT_PER_PAGE = PER_PAGE_OPTIONS[0];
export const FAKE_PAGE_NUMBERS = [0, 1, 2];
export const [FIRST_PAGE, MIDDLE_PAGE, LAST_PAGE] = FAKE_PAGE_NUMBERS;

export const perPageProtector = (perPageParam: any): number => {
  const perPage = parseInt(perPageParam!, 10);
  if (Number.isNaN(perPage)) return DEFAULT_PER_PAGE;
  if (PER_PAGE_OPTIONS.includes(perPage)) return perPage;
  return getClosestNumber(perPage, PER_PAGE_OPTIONS);
};

export const BmTablePagination: React.FC = () => {
  const {pageInfo, rowsPerPage, isLoading, onPageChange, onRowsPerPageChange} = useBmTablePaginationContext();
  const protectedRowsPerPage = useMemo(() => perPageProtector(rowsPerPage), [rowsPerPage]);
  const isNextBtnDisabled = !pageInfo.hasNextPage;
  const isPrevBtnDisabled = !pageInfo.hasPreviousPage;

  const count = [isNextBtnDisabled, isPrevBtnDisabled].every(Boolean) ? protectedRowsPerPage : protectedRowsPerPage * 3;

  const [currentPage, setCurrentPage] = useState(FIRST_PAGE);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      const direction: "next" | "prev" = currentPage > newPage ? "prev" : "next";
      onPageChange(direction, direction === "next" ? pageInfo.endCursor! : pageInfo.startCursor!);
    },
    [currentPage, onPageChange, pageInfo.endCursor, pageInfo.startCursor],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const nextRowsPerPage = perPageProtector(event.target.value);
      onRowsPerPageChange(nextRowsPerPage);
    },
    [onRowsPerPageChange],
  );

  useEffect(() => {
    let nextPage;
    if (isPrevBtnDisabled) {
      nextPage = FIRST_PAGE;
    } else if (isNextBtnDisabled) {
      nextPage = LAST_PAGE;
    } else {
      nextPage = MIDDLE_PAGE;
    }
    setCurrentPage(nextPage);
  }, [isNextBtnDisabled, isPrevBtnDisabled]);

  return (
    <TablePagination
      className={styles.root}
      component="div"
      count={count}
      page={currentPage}
      onPageChange={isLoading ? noop : handleChangePage}
      rowsPerPage={protectedRowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelDisplayedRows={() => null}
      rowsPerPageOptions={PER_PAGE_OPTIONS}
    />
  );
};
