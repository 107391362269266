import {useTranslation} from "react-i18next";
import {Stack} from "@mui/material";
import {useCallback, useEffect, useMemo, useState} from "react";

import {useOpenClose} from "~/hooks";
import {FrozenMakerReport, FrozenMakerReportSummaryFragment, MakerReportableSummaryFragment} from "~/api/generated";
import {BmBaseModal, BmBigTitleModalContent} from "~/components/modals";
import {BmToggleButtonGroup} from "~/components/inputs";
import {BmFiltersPanel} from "~/modules/filtering";

import {SalesReportViewEnum, SalesReportsEnum, getPeriodOptions, getSalesReportViewOptions} from "./helpers";
import {SalesReportDateFilter} from "./SalesReportDateFilter/SalesReportDateFilter";

interface TaxWarehouseFilterPanelProps {
  makerReporttables: MakerReportableSummaryFragment[];
  onFiltersChange: (data: FrozenMakerReportSummaryFragment) => void;
}

export const TaxWarehouseFilterPanel: React.FC<TaxWarehouseFilterPanelProps> = ({
  makerReporttables,
  onFiltersChange,
}) => {
  const {t} = useTranslation();

  const [isSalesReportModalOpen, openSalesReportModal, closeSalesReportsModal] = useOpenClose(false);

  const defaultDateValue = useMemo(() => makerReporttables[0]?.period, [makerReporttables]);

  const [period, setPeriod] = useState<string | null>(defaultDateValue);
  const [newPeriod, setNewPeriod] = useState<string | null>(null);
  const [view, setView] = useState<string | null>(SalesReportViewEnum.Simple);

  const periodOptions = useMemo(
    () => getPeriodOptions(t, makerReporttables, newPeriod),
    [t, makerReporttables, newPeriod],
  );
  const salesReportViewOptions = useMemo(() => getSalesReportViewOptions(t), [t]);

  const handlePeriodChange = useCallback(
    (value: string | null) => {
      if (value === SalesReportsEnum.Older) {
        openSalesReportModal();
        return;
      }
      setPeriod(value);
    },
    [openSalesReportModal],
  );

  const handleDateFiltersChange = useCallback(
    (value: string) => {
      setPeriod(value);
      setNewPeriod(value);
      closeSalesReportsModal();
    },
    [closeSalesReportsModal],
  );

  const handleDateFiltersClose = useCallback(() => {
    setPeriod(defaultDateValue);
    closeSalesReportsModal();
  }, [defaultDateValue, closeSalesReportsModal]);

  const handleFilterChange = useCallback(() => {
    if (!period) return;
    if (!view) return;

    const currentMakerReport = makerReporttables
      .find((makerReporttable) => makerReporttable.period === period)
      ?.reports.find((report) => report.view === view);

    currentMakerReport && onFiltersChange(currentMakerReport as FrozenMakerReport);
  }, [period, view, makerReporttables, onFiltersChange]);

  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]);

  return (
    <>
      <Stack direction="row" flexWrap="wrap" spacing={1} useFlexGap>
        <BmFiltersPanel title={`${t("labels.select_report")}:`}>
          <BmToggleButtonGroup
            name="period"
            value={period}
            options={periodOptions}
            onSelectOption={handlePeriodChange}
          />
        </BmFiltersPanel>
        <BmFiltersPanel title={`${t("labels.view")}:`}>
          <BmToggleButtonGroup
            name="view"
            value={view}
            options={salesReportViewOptions}
            onSelectOption={(value) => setView(value)}
          />
        </BmFiltersPanel>
      </Stack>

      {makerReporttables && (
        <BmBaseModal
          open={isSalesReportModalOpen}
          onClose={closeSalesReportsModal}
          title={t("labels.select_report")}
          bodySx={{width: "100%", maxWidth: "512px"}}
          ModalContentComponent={BmBigTitleModalContent}
        >
          <SalesReportDateFilter
            makerReporttables={makerReporttables}
            onFiltersChange={handleDateFiltersChange}
            onCancel={handleDateFiltersClose}
          />
        </BmBaseModal>
      )}
    </>
  );
};
