import {cx} from "@emotion/css";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import {isNil} from "ramda";
import React, {ChangeEventHandler, useCallback, useMemo, useRef, useState} from "react";
import {useController} from "react-hook-form";
import get from "lodash.get";

import {BmTooltip} from "~/components/tooltip";
import {useCombinedRefs, useDidUpdate} from "~/hooks";

import {ControlledInputProps} from "../../_common/types";
import {BmTextarea, BmTextareaProps} from "../Textarea";
import {styles} from "./ResetableTextArea.styles";

export interface BmResetableTextAreaProps extends Omit<BmTextareaProps, "endAdornment"> {
  // if value is set on BE
  systemValue?: string | null;
  resetButtonTooltip?: string;
}

export const BmResetableTextArea = React.forwardRef<HTMLTextAreaElement, BmResetableTextAreaProps>(
  function BmResetableTextArea({className, value, systemValue, resetButtonTooltip = "", onChange, ...props}, ref) {
    const innerRef = useRef(null);
    const combinedRef = useCombinedRefs<HTMLTextAreaElement>(innerRef, ref);
    const [localValue, setLocalValue] = useState(value || systemValue);

    const isValueEqualSystemValue = useMemo(() => {
      if (!localValue) return false;
      return localValue === systemValue;
    }, [localValue, systemValue]);

    const handleResetClick = useCallback(() => {
      if (!combinedRef?.current) return;

      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLTextAreaElement.prototype,
        "value",
      )!.set!;
      nativeInputValueSetter.call(combinedRef.current, systemValue);

      const onChangeEvent = new Event("input", {bubbles: true});
      combinedRef.current.dispatchEvent(onChangeEvent);
    }, [combinedRef, systemValue]);

    useDidUpdate(() => {
      setLocalValue(value!);
    }, [value, setLocalValue]);

    return (
      <BmTextarea
        {...props}
        ref={combinedRef}
        className={cx(styles.root, isValueEqualSystemValue && styles.greyText, className)}
        value={value || localValue || ""}
        onChange={onChange}
        endAdornment={
          !isNil(systemValue) &&
          !isValueEqualSystemValue && (
            <BmTooltip title={resetButtonTooltip}>
              <IconButton aria-label="reset value to init" edge="end" onClick={handleResetClick}>
                <CachedIcon id="reset-icon" />
              </IconButton>
            </BmTooltip>
          )
        }
      />
    );
  },
);

export interface BmControlledResetableTextAreaProps
  extends ControlledInputProps,
    Omit<BmResetableTextAreaProps, "onChange" | "value" | "error"> {
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

export const BmControlledResetableTextArea: React.FC<BmControlledResetableTextAreaProps> = ({
  name,
  control,
  rules,
  defaultValue,
  helperText = "",
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleOnChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      onChange?.(e);
      field.onChange(e);
    },
    [field, onChange],
  );

  return (
    <BmResetableTextArea
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
