import React, {useCallback} from "react";
import TextField, {TextFieldProps} from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import get from "lodash.get";
import {useController} from "react-hook-form";
import {cx} from "@emotion/css";

import {CommonInputType, ControlledInputProps} from "../../_common/types";
import {commonStyles} from "../../_common/styles";

export interface BmTimePickerProps extends CommonInputType {
  value: Date | null;
  inputFormat?: string;
  minTime?: any;
  maxTime?: any;
  ampm?: boolean;
  ampmInClock?: boolean;
  inputClassName?: string;

  onChange: (value: Date | null, keyboardInputValue?: string | undefined) => void;
}

export const BmTimePicker = React.forwardRef<HTMLInputElement, BmTimePickerProps>(function BmTimePicker(
  {value, inputFormat = "HH:mm", helperText, name, error, inputClassName, onChange, ...props},
  ref,
) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        {...props}
        inputFormat={inputFormat}
        value={value || null}
        onChange={onChange}
        renderInput={(params: TextFieldProps) => (
          <TextField
            {...params}
            ref={ref}
            name={name}
            className={cx(commonStyles.input(), inputClassName)}
            helperText={helperText}
            error={error}
            fullWidth={true}
          />
        )}
      />
    </LocalizationProvider>
  );
});

export interface BmControlledTimePickerProps
  extends ControlledInputProps,
    Omit<BmTimePickerProps, "onChange" | "value" | "error"> {
  onChange?: (value: Date | null, keyboardInputValue?: string | undefined) => void;
}

export const BmControlledTimePicker: React.FC<BmControlledTimePickerProps> = ({
  name,
  control,
  rules,
  helperText,
  defaultValue,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleChange = useCallback(
    (value) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmTimePicker
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleChange}
    />
  );
};
