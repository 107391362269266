import {useSortable, rectSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

import {BmDefaultImage} from "~/components/images";

import {IImageItem} from "../types";
import {styles} from "./ImageItem.styles";

export interface ImageItemProps {
  image: IImageItem;
  removeImage: VoidFunction;
}

export const ImageItem: React.FC<ImageItemProps> = ({image, removeImage}) => {
  const {attributes, listeners, setNodeRef, transform, transition, isSorting, isDragging} = useSortable({
    id: image.id,
    strategy: rectSortingStrategy,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      key={image.id}
      className={styles.imageColumn(isDragging)}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <BmDefaultImage src={image.src} alt={image.alt} onRemoveClick={removeImage} isSorting={isSorting} />
    </div>
  );
};
