import {Box, SxProps, Theme} from "@mui/material";
import {isEqual, isValid} from "date-fns";
import get from "lodash.get";
import {compose, equals} from "ramda";
import React, {useCallback, useMemo} from "react";
import {DateRange, RangeKeyDict} from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {useController} from "react-hook-form";

import {BmHelperText} from "~/components/helperText";
import {noop} from "~/utils/noop";

import {ControlledInputProps} from "../../_common/types";
import {styles} from "./DateRange.styles";
import {
  BmDateRangeValue,
  convertBmDateRangeValueToRangeKeyDict,
  convertRangeKeyDictToBmDateRangeValue,
} from "./converters";

export * from "./converters";

export interface BmDateRangeProps {
  wrapperSx?: SxProps<Theme>;
  name: string;
  minDate?: Date;
  maxDate?: Date;
  startDatePlaceholder?: string;
  endDatePlaceholder?: string;
  value: BmDateRangeValue;
  error?: boolean;
  helperText?: string;
  onChange: (value: BmDateRangeValue) => void;
}

export const BmDateRange: React.FC<BmDateRangeProps> = React.memo(
  ({wrapperSx, minDate, maxDate, startDatePlaceholder, endDatePlaceholder, value, error, helperText, onChange}) => {
    const rangeKeyDictSelection = useMemo(() => convertBmDateRangeValueToRangeKeyDict(value).selection, [value]);

    const handleChange = useCallback(
      (rangesByKey: RangeKeyDict) => compose(onChange, convertRangeKeyDictToBmDateRangeValue)(rangesByKey),
      [onChange],
    );

    return (
      <Box sx={wrapperSx}>
        <DateRange
          className={styles.dateRange}
          months={1}
          minDate={minDate}
          maxDate={maxDate}
          startDatePlaceholder={startDatePlaceholder}
          endDatePlaceholder={endDatePlaceholder}
          direction="horizontal"
          ranges={[rangeKeyDictSelection]}
          shownDate={isValid(rangeKeyDictSelection.startDate) ? rangeKeyDictSelection.startDate : new Date()}
          onChange={handleChange}
          onRangeFocusChange={noop}
        />
        {helperText && <BmHelperText error={error}>{helperText}</BmHelperText>}
      </Box>
    );
  },
  (prevProps, nextProps) =>
    [
      equals(prevProps.wrapperSx, nextProps.wrapperSx),
      prevProps.name === nextProps.name,
      isEqual(prevProps.minDate || new Date(), nextProps.minDate || new Date()),
      isEqual(prevProps.maxDate || new Date(), nextProps.maxDate || new Date()),
      prevProps.startDatePlaceholder === nextProps.startDatePlaceholder,
      prevProps.endDatePlaceholder === nextProps.endDatePlaceholder,
      prevProps.error === nextProps.error,
      prevProps.helperText === nextProps.helperText,
      equals(prevProps.value, nextProps.value),
      prevProps.onChange.toString() === nextProps.onChange.toString(),
    ].every(Boolean),
);

export interface BmControlledDateRangeProps
  extends ControlledInputProps,
    Omit<BmDateRangeProps, "onChange" | "value" | "error"> {
  onChange?: (value: RangeKeyDict) => void;
}

export const BmControlledDateRange: React.FC<BmControlledDateRangeProps> = ({
  name,
  control,
  rules,
  helperText,
  onChange,
  ...restProps
}) => {
  const {
    field: {ref: _, ...field},
    fieldState: {error},
  } = useController({name, control, rules});

  const handleChange = useCallback(
    (value) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmDateRange
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleChange}
    />
  );
};
