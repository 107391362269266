import Box from "@mui/material/Box";
import {isNil} from "ramda";

interface InputHelperTextProps {
  helperText?: string;
  charLimit?: number;
  value?: string;
}

export const BmInputHelperText: React.FC<InputHelperTextProps> = ({helperText, charLimit, value}) => {
  switch (true) {
    case isNil(helperText) && isNil(charLimit):
      return null;
    case !isNil(helperText) && !isNil(charLimit):
      return (
        <Box component="span" display="flex" justifyContent="space-between" gap={2}>
          <Box component="span" dangerouslySetInnerHTML={{__html: helperText!}} />
          <Box component="span">
            {value?.length || 0}/{charLimit}
          </Box>
        </Box>
      );
    case !isNil(helperText) && isNil(charLimit):
      return <Box component="span" dangerouslySetInnerHTML={{__html: helperText!}} />;
    case isNil(helperText) && !isNil(charLimit):
      return (
        <Box component="span" display="flex" justifyContent="flex-end">
          {value?.length || 0}/{charLimit}
        </Box>
      );
    default:
      throw new Error("InputHelperText: unknown case");
  }
};
