import React, {useCallback} from "react";
import {useController} from "react-hook-form";
import {cx} from "@emotion/css";
import {
  Box,
  FormControl,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  FormControlLabel as MuiFormControlLabel,
} from "@mui/material";
import get from "lodash.get";

import {RadioType} from "~/components/inputs";
import {BmHelperText} from "~/components/helperText";
import {BmRadioCheckedIcon, BmRadioEmptyIcon} from "~/components/icons/simpleIcons";
import {noop} from "~/utils/noop";

import {ControlledInputProps} from "../_common/types";
import {styles} from "./RadioGroup.styles";

export interface BmRadioGroupProps {
  id?: string;
  name: string;
  className?: string;
  options: RadioType[];
  ariaLabel?: string;
  value?: string | null;
  disabled?: boolean;
  horizontal?: boolean;
  fitContent?: boolean;
  error?: boolean;
  helperText?: string;

  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  outlinedOptions?: boolean;
}

export const BmRadioGroup = React.forwardRef<HTMLInputElement, BmRadioGroupProps>(function BmRadioGroup(
  {
    id,
    name,
    className,
    options: radioButtons,
    ariaLabel,
    value,
    disabled,
    horizontal = false,
    fitContent = true,
    error,
    helperText,

    onChange,
    outlinedOptions = false,
  },
  ref,
) {
  return (
    <FormControl id={id} className={cx(styles.root(fitContent))} error={error} variant="standard">
      <MuiRadioGroup
        ref={ref}
        aria-label={ariaLabel}
        name={name}
        className={cx(styles.input, className)}
        onChange={disabled ? noop : onChange}
        value={value ?? null}
        row={horizontal}
        sx={{gap: 1}}
      >
        {radioButtons.map((radioButton: RadioType) => {
          return (
            <Box
              key={radioButton.value}
              className={
                outlinedOptions
                  ? cx(styles.outlinedOption, value === radioButton.value && styles.outlinedOptionActive)
                  : ""
              }
            >
              <MuiFormControlLabel
                value={radioButton.value}
                className={styles.label}
                control={
                  <MuiRadio
                    icon={<BmRadioEmptyIcon id="radio-empty" />}
                    checkedIcon={<BmRadioCheckedIcon id="radio-checked" />}
                    disabled={disabled || radioButton.disabled}
                    className={cx(styles.radio, !!error && styles.error)}
                  />
                }
                label={radioButton.label}
                disabled={disabled || radioButton.disabled}
              />
              {radioButton.helperText && <BmHelperText sx={{pl: 5}}>{radioButton.helperText}</BmHelperText>}
              {radioButton.labelDescription}
              {value === radioButton.value && radioButton.underLabel}
            </Box>
          );
        })}
      </MuiRadioGroup>
      {helperText && <BmHelperText error={error}>{helperText}</BmHelperText>}
    </FormControl>
  );
});

export interface BmControlledRadioGroupProps
  extends ControlledInputProps,
    Omit<BmRadioGroupProps, "onChange" | "value" | "error"> {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const BmControlledRadioGroup: React.FC<BmControlledRadioGroupProps> = ({
  name,
  defaultValue,
  control,
  rules,
  helperText,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
      onChange?.(e, value);
      field.onChange(e);
    },
    [field, onChange],
  );

  return (
    <BmRadioGroup
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
