import {ProductFilter, ProductKind, WarehouseSummaryFragment} from "~/api/generated";
import {CUSTOMER_COUNTRIES} from "~/constants/countries";
import {getEnumValues} from "~/utils/enums";

import {useBackendFiltering} from "../useBackendFiltering";

const allowedFilters: (keyof ProductFilter)[] = ["search", "warehouseId", "kind", "shopCountries"];

export const searchParamsToAppliedProductsFilters = (
  searchParams: URLSearchParams,
  warehouses: WarehouseSummaryFragment[] | null | undefined,
): ProductFilter => {
  const nextAppliedProductsFilters = ([...searchParams] as [keyof ProductFilter, any][])
    .filter(([searchParamKey, searchParamValue]) => {
      switch (searchParamKey) {
        case "search":
          return typeof searchParamValue === "string" && searchParamValue.length > 0;
        case "warehouseId":
          return warehouses?.map(({id}) => id).includes(searchParamValue);
        case "kind":
          return getEnumValues(ProductKind).includes(searchParamValue);
        case "shopCountries":
          return CUSTOMER_COUNTRIES.map(({COUNTRY_CODE}) => COUNTRY_CODE).includes(searchParamValue);
        default:
          return false;
      }
    })
    .reduce((filters, [searchParamKey, searchParamValue]) => {
      return {
        ...filters,
        [searchParamKey]: ["kind", "shopCountries"].includes(searchParamKey)
          ? [...(filters[searchParamKey] || []), searchParamValue]
          : searchParamValue,
      };
    }, {} as ProductFilter);

  return nextAppliedProductsFilters;
};

export const useBackendProductsFiltering = (
  warehouses: WarehouseSummaryFragment[] | null | undefined,
): [ProductFilter, (filters: ProductFilter) => void] => {
  const [appliedFilters, onFiltersChange] = useBackendFiltering<ProductFilter>(allowedFilters, (searchParams) =>
    searchParamsToAppliedProductsFilters(searchParams, warehouses),
  );

  return [appliedFilters, onFiltersChange];
};
