import {css} from "@emotion/css";

import {designColors, designUtils} from "~/ui";

export const styles = {
  group: css`
    gap: 3px;
  `,
  button: css`
    &.MuiToggleButton-root {
      ${designUtils.baseFontStyles};
      color: ${designColors.colorInk};

      text-transform: none;
      padding: 3px 6px;
      border: none;

      &:hover,
      &.Mui-selected,
      &.Mui-selected:hover {
        background: ${designColors.blue_1};
      }
    }
  `,
};
