import React, {useCallback} from "react";
import {useController} from "react-hook-form";
import get from "lodash.get";

import {ControlledInputProps} from "~/components/inputs";

import {BmTextInput, BmTextInputProps} from "../../TextInput";

export interface BmUpperCaseTextInputProps extends Omit<BmTextInputProps, "onChange"> {
  onChange: (value: string) => void;
}

export const BmUpperCaseTextInput: React.FC<BmUpperCaseTextInputProps> = ({value, onChange, ...props}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value.toUpperCase());
  };

  return <BmTextInput {...props} value={value} onChange={handleChange} />;
};

export interface BmControlledUpperCaseTextInputProps
  extends ControlledInputProps,
    Omit<BmUpperCaseTextInputProps, "onChange" | "value" | "error"> {
  onChange?: (value: string) => void;
}

export const BmControlledUpperCaseTextInput: React.FC<BmControlledUpperCaseTextInputProps> = ({
  name,
  control,
  rules,
  defaultValue,
  helperText = "",
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleOnChange = useCallback(
    (value: string) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmUpperCaseTextInput
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
