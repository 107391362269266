import {css} from "@emotion/css";

import {designColors, designTokens, designUtils} from "~/ui";

export const styles = {
  buttonGroup: css`
    &.MuiButtonGroup-root {
      display: flex;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    }
  `,
  option: (selected: boolean, error: boolean) => css`
    &.MuiButton-root.MuiButtonGroup-grouped {
      ${designUtils.baseFontStyles};
      flex: 1;
      height: 42px;
      padding: 12px 6px;
      min-width: fit-content;

      border: 1.5px solid ${error ? designColors.error : designColors.grey_2};
      background: ${designColors.white};

      color: ${designColors.black};
      font-weight: ${designTokens.fontWeight.semiBold};
      text-transform: none;

      &:hover {
        background-color: ${designColors.grey_1};
        border: 1.5px solid ${designColors.grey_2};
      }

      &,
      &:hover {
        opacity: 1;
        &:not(:last-of-type) {
          border-right-color: transparent;
        }
      }

      &.Mui-disabled {
        color: ${designColors.grey_4};
      }

      ${selected &&
      css`
        &,
        &:hover {
          border: 1.5px solid ${designColors.primary};
          box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1) inset;
          & + .MuiButton-root {
            border-left: 1.5px solid ${designColors.primary};
          }
        }
      `}
    }
  `,
};
