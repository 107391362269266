import {flagUrl, ALL_COUNTRIES, CONFIG} from "@bemakers/countries";
import {pick} from "ramda";

import {getFlagComponent} from "~/components/icons/flagIcons";
import {CountryDataObjectType, ICountry} from "~/model/helperTypes/ICountries";

const CUSTOMER_COUNTRY_CODES = [...CONFIG.MARKET_COUNTRIES];

export const EU_CURRENCY = "EUR";

export const ALL_COUNTRIES_DATA_OBJECT: CountryDataObjectType = Object.entries(ALL_COUNTRIES).reduce(
  (accum, [countryCode, countryData]) => ({
    ...accum,
    [countryCode]: {
      COUNTRY_CODE: countryCode,
      NAME: countryData.name,
      LOCALE: countryData.locale,
      FLAG: getFlagComponent(countryCode, flagUrl(countryCode)),
      DIAL_CODE: countryData.dialcode,
      VAT: countryData.vat || 1,
      CURRENCY: countryData.currency,
      TIMEZONE: countryData.timezone,
    },
  }),
  {},
);

const countrySortByNameFn = (a: ICountry, b: ICountry) => a.NAME.localeCompare(b.NAME);

const allPossibleCountries = Object.values(ALL_COUNTRIES_DATA_OBJECT).sort(countrySortByNameFn);

export const CUSTOMER_COUNTRIES = Object.values(pick(CUSTOMER_COUNTRY_CODES, ALL_COUNTRIES_DATA_OBJECT)).sort(
  countrySortByNameFn,
);
export const DISTRIBUTOR_COUNTRIES = allPossibleCountries;
export const MAKER_COUNTRIES = allPossibleCountries;
