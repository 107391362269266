/* eslint-disable no-fallthrough */
import {CountryOption} from "~/components/inputs/Select";
import {ALL_COUNTRIES_DATA_OBJECT} from "~/constants/countries";
import {ICountry} from "~/model/helperTypes/ICountries";
import {CountryCode} from "~/constants/countryCode";

export const allCountryCodes: string[] = Object.values(ALL_COUNTRIES_DATA_OBJECT).map<string>(
  ({COUNTRY_CODE}) => COUNTRY_CODE,
);

export const onlyCountries = allCountryCodes.map((country) => country.toLowerCase());

export const findCountryByCode = (code: string): ICountry | undefined =>
  Object.values(ALL_COUNTRIES_DATA_OBJECT).find(({COUNTRY_CODE}) => COUNTRY_CODE === code);

export const getCountryCodeOptions = (passedCountries: ICountry[]) =>
  passedCountries
    .filter(({COUNTRY_CODE}) => allCountryCodes.includes(COUNTRY_CODE))
    .map(({COUNTRY_CODE, DIAL_CODE, FLAG}) => ({
      value: COUNTRY_CODE,
      label: (
        <CountryOption FlagComponent={FLAG} countryCode={COUNTRY_CODE as CountryCode}>
          {" "}
          +{DIAL_CODE}
        </CountryOption>
      ),
    }));

export const getInitCountry = (
  parsedCode: string | undefined,
  defaultCountry: CountryCode | undefined,
  passedCountries: ICountry[],
): ICountry => {
  let initCountry;
  switch (true) {
    // @ts-expect-error: Fallthrough case in switch
    case !!parsedCode: {
      initCountry = findCountryByCode(parsedCode!);
      if (initCountry) break;
    }
    // @ts-expect-error: Fallthrough case in switch
    case !!defaultCountry: {
      initCountry = findCountryByCode(defaultCountry!);
      if (initCountry) break;
    }
    default:
      initCountry = passedCountries[0];
      break;
  }

  return initCountry;
};
