import React, {ChangeEventHandler, SyntheticEvent, useCallback} from "react";
import {get, useController} from "react-hook-form";
import {cx} from "@emotion/css";
import {Box, MenuItem, List as MuiList, Autocomplete as MuiAutocomplete, TextField} from "@mui/material";

import {BmDefaultChip} from "~/components/chips";
import {BmChevronIcon} from "~/components/icons/simpleIcons";
import {noop} from "~/utils/noop";

import {BmCheckbox} from "../Checkbox/Checkbox";
import {styles} from "../Autocomplete/Autocomplete.styles";
import {CommonInputType, ControlledInputProps} from "../_common/types";

export interface BmMultipleAutocompleteProps extends CommonInputType {
  options: string[];
  value?: string[];
  defaultValue?: string[];
  onChange?: (event: SyntheticEvent, value: string[]) => void;
  onDelete?: (value: string) => void;
  onInputChange?: (changeEvent: ChangeEventHandler<HTMLInputElement>) => ChangeEventHandler<HTMLInputElement>;
  freeSolo?: boolean;
  noOptionsText?: string;
  renderTagLabel?: (label: string) => string;
  autoSelect?: boolean;
}

export const BmMultipleAutocomplete = React.forwardRef<HTMLDivElement, BmMultipleAutocompleteProps>(
  function BmMultipleAutocomplete(
    {
      className,
      options,
      defaultValue,
      value,
      helperText,
      placeholder,
      onDelete,
      onInputChange,
      freeSolo,
      renderTagLabel,
      autoSelect = true,
      ...restProps
    },
    ref,
  ) {
    return (
      <MuiAutocomplete
        {...restProps}
        className={cx(styles.autocomplete, className)}
        sx={{".MuiInputBase-root": {gap: 1}}}
        multiple
        options={options.map((option) => option)}
        value={value}
        defaultValue={defaultValue}
        ref={ref}
        freeSolo={freeSolo}
        autoSelect={autoSelect}
        renderOption={(props, option, {selected}) => (
          <MenuItem {...props} className={cx(styles.option)} key={option}>
            <BmCheckbox label={option} checked={selected} />
          </MenuItem>
        )}
        popupIcon={<BmChevronIcon id="chevron-icon" />}
        forcePopupIcon={true}
        PaperComponent={({children}) => (
          <MuiList className={cx(styles.list)} onMouseDown={(e) => e.preventDefault()}>
            {children}
          </MuiList>
        )}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Box key={option} sx={{maxWidth: "100%"}}>
              <BmDefaultChip
                label={renderTagLabel ? renderTagLabel(option) : option}
                onDelete={(event: any) => {
                  getTagProps({index})["onDelete"](event);
                  onDelete?.(option);
                }}
              />
            </Box>
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={helperText}
            placeholder={placeholder}
            onChange={onInputChange?.(params.inputProps.onChange || noop)}
          />
        )}
      />
    );
  },
);

export interface BmControlledMultipleAutocompleteProps
  extends ControlledInputProps,
    Omit<BmMultipleAutocompleteProps, "onChange" | "value" | "error" | "defaultValue"> {
  onChange?: (event: SyntheticEvent, value: string[]) => void;
}

export const BmControlledMultipleAutocomplete: React.FC<BmControlledMultipleAutocompleteProps> = ({
  name,
  defaultValue,
  control,
  rules,
  onChange,
  helperText,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleChange = useCallback(
    (event, value) => {
      onChange?.(event, value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmMultipleAutocomplete
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      onChange={handleChange}
    />
  );
};
