import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Stack} from "@mui/material";

import {useErrorCatch, useIsMobile, useProtectedMaker} from "~/hooks";
import {CREATE_REPORT_PATH} from "~/modules/routing";
import {
  FrozenMakerReportSummaryFragment,
  ReportableKind,
  useLazyGetFrozenMakerReportQuery,
  useLazyGetMakerReportablesQuery,
} from "~/api/generated";
import {BmDefaultPageContent} from "~/layouts/pageContents";
import {BmDefaultPageLayout} from "~/layouts/pageLayouts";
import {BmPrimaryButton} from "~/components/buttons";
import {BmFileDownIcon} from "~/components/icons/simpleIcons";
import {designColors} from "~/ui";
import {BmTableSkeleton, BmTableSkeletonMobile} from "~/components/skeleton";
import {BmEmptyLayout} from "~/layouts/emptyLayout";

import {TaxWarehousList} from "./createReport/containers/TaxWarehouseList/TaxWarehouseList";
import {TaxWarehouseFilterPanel} from "./createReport/containers/TaxWarehouseFilterPanel/TaxWarehouseFilterPanel";

enum ContentStateEnum {
  mobileSkeleton = "mobileSkeleton",
  desktopSkeleton = "desktopSkeleton",
  taxWarehouseReport = "taxWarehouseReport",
  emptyLayout = "emptyLayout",
}

const TaxWarehouseReport: React.FC = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const [, isMobile] = useIsMobile();

  const [maker] = useProtectedMaker();

  const [currentFrozenMakerReport, setCurrentFrozenMakerReport] = useState<FrozenMakerReportSummaryFragment | null>(
    null,
  );

  const [
    getMakerReporttables,
    {data: makerReporttables, isLoading: isMakerReporttablesLoading, error: makerReporttablesError},
  ] = useLazyGetMakerReportablesQuery();
  const [
    getFrozenMakerReport,
    {data: frozenMakerReport, isLoading: isFrozenMakerReportLoading, error: frozenMakerReportError},
  ] = useLazyGetFrozenMakerReportQuery();

  useErrorCatch(makerReporttablesError);
  useErrorCatch(frozenMakerReportError);

  const contentState = useMemo(() => {
    switch (true) {
      case isMakerReporttablesLoading || isFrozenMakerReportLoading:
      case !makerReporttables?.makerReportables:
        return isMobile ? ContentStateEnum.mobileSkeleton : ContentStateEnum.desktopSkeleton;
      case makerReporttables!.makerReportables.length > 0:
        return ContentStateEnum.taxWarehouseReport;
      case makerReporttables!.makerReportables!.length === 0:
      default:
        return ContentStateEnum.emptyLayout;
    }
  }, [isMakerReporttablesLoading, isFrozenMakerReportLoading, isMobile, makerReporttables]);

  const goToReports = useCallback(() => {
    history.push(CREATE_REPORT_PATH);
  }, [history]);

  const openXlsUrl = useCallback(() => {
    if (!frozenMakerReport?.frozenMakerReport) return;
    window.open(frozenMakerReport.frozenMakerReport.xlsUrl, "_current");
  }, [frozenMakerReport]);

  useEffect(() => {
    if (!maker) return;
    getMakerReporttables({makerId: maker.id, kind: ReportableKind.ExciseDuty});
  }, [getMakerReporttables, maker]);

  useEffect(() => {
    if (!currentFrozenMakerReport) return;
    getFrozenMakerReport({id: currentFrozenMakerReport.id});
  }, [getFrozenMakerReport, currentFrozenMakerReport]);

  return (
    <BmDefaultPageLayout>
      <BmDefaultPageContent
        title={t("reports.create_report.excise_duty_options.tax_warehouse.title")}
        variant="plain"
        isArrow
        onArrowClick={goToReports}
        contentWidthVariant="fitScreen"
      >
        {
          {
            [ContentStateEnum.mobileSkeleton]: <BmTableSkeletonMobile rows={2} />,
            [ContentStateEnum.desktopSkeleton]: <BmTableSkeleton rows={3} />,
            [ContentStateEnum.emptyLayout]: <BmEmptyLayout title={t("reports.tax_warehouse.empty_result.title")} />,
            [ContentStateEnum.taxWarehouseReport]: (
              <>
                {makerReporttables?.makerReportables && (
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    useFlexGap
                    mb={4}
                  >
                    <TaxWarehouseFilterPanel
                      makerReporttables={makerReporttables.makerReportables}
                      onFiltersChange={setCurrentFrozenMakerReport}
                    />
                    <BmPrimaryButton onClick={openXlsUrl}>
                      <Stack direction="row" spacing={0.5} useFlexGap>
                        {t("buttons.download_exel")}
                        <BmFileDownIcon color={designColors.white} id="file-down-icon" />
                      </Stack>
                    </BmPrimaryButton>
                  </Stack>
                )}
                {frozenMakerReport?.frozenMakerReport && (
                  <TaxWarehousList html={frozenMakerReport.frozenMakerReport.html} />
                )}
              </>
            ),
          }[contentState]
        }
      </BmDefaultPageContent>
    </BmDefaultPageLayout>
  );
};

export default TaxWarehouseReport;
