import {Stack} from "@mui/material";

import {BmLabel} from "~/components/labels";
import {designColors, designConstants} from "~/ui";

interface BmFiltersPanelProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

export const BmFiltersPanel: React.FC<BmFiltersPanelProps> = ({title, children, className}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        px: "14px",
        py: "9px",
        borderRadius: designConstants.borderRadius,
        border: `1px solid${designColors.grey_3}`,
        width: "max-content",
        background: designColors.white,
      }}
      className={className}
    >
      <BmLabel variant="semiBold">{title}</BmLabel>
      {children}
    </Stack>
  );
};
