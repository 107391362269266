import {createContext, useContext} from "react";

import {noop} from "~/utils/noop";

export interface IBmRichTextEditorContext {
  toggleFocus: (args: any) => void;
}

const BmRichTextEditorContext = createContext<IBmRichTextEditorContext>({
  toggleFocus: noop,
});

export const BmRichTextEditorContextProvider = BmRichTextEditorContext.Provider;

export const useBmRichTextEditorContext = () => useContext(BmRichTextEditorContext);
