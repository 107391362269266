import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  label: css`
    &.MuiFormControlLabel-root {
      margin-right: 0;
      margin-left: -6px;
    }
  `,
  checkbox: css`
    &.MuiButtonBase-root.MuiCheckbox-root {
      padding: 6px;
    }
    &.Mui-disabled svg {
      *[stroke^="\#"] {
        stroke: rgba(0, 0, 0, 0.26);
      }
    }
    &.Mui-disabled.Mui-checked svg {
      *[stroke^="\#"] {
        stroke: ${designColors.white};
      }
      *[fill^="\#"] {
        fill: rgba(0, 0, 0, 0.26);
      }
    }
  `,
  error: css`
    &.MuiCheckbox-root svg {
      *[stroke^="\#"] {
        stroke: ${designColors.error};
      }
    }
    &.Mui-checked svg {
      *[stroke^="\#"] {
        stroke: ${designColors.white};
      }
      *[fill^="\#"] {
        fill: ${designColors.error};
      }
    }
  `,
};
