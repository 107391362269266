import {useController} from "react-hook-form";
import React, {useCallback} from "react";

import {ControlledInputProps} from "../../_common/types";
import {styles} from "./UploadPDF.styles";

export interface BmUploadPDFProps {
  id?: string;
  name: string;
  buttonText: string;
  onChange: (file: File) => void;
}

export const BmUploadPDF = React.forwardRef<HTMLInputElement, BmUploadPDFProps>(function BmUploadPDF(
  {id, name, buttonText, onChange, ...rest},
  ref,
) {
  const handleUploadPDF = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];

      if (!file) return;
      else onChange(file);
    },
    [onChange],
  );

  return (
    <label htmlFor={id || name} className={styles.uploadInputLabel}>
      {buttonText}
      <input
        {...rest}
        ref={ref}
        name={name}
        id={id || name}
        className={styles.input}
        accept="application/pdf"
        type="file"
        onChange={handleUploadPDF}
      />
    </label>
  );
});

export interface BmControlledUploadPDFProps extends ControlledInputProps, Omit<BmUploadPDFProps, "onChange"> {
  onChange?: (file: File) => void;
}

export const BmControlledUploadPDF: React.FC<BmControlledUploadPDFProps> = ({
  name,
  control,
  rules,
  onChange,
  ...restProps
}) => {
  const {
    field: {value: _, ...field},
  } = useController({name, control, rules});

  const handleOnChange = useCallback(
    (value: File) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return <BmUploadPDF {...field} {...restProps} onChange={handleOnChange} />;
};
