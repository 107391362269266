import {css} from "@emotion/css";

import {designColors} from "~/ui";
import {getHoverColor} from "~/utils/colors";

export const styles = {
  errorButton: css`
    &.MuiButton-root {
      color: ${designColors.error};

      &,
      &.MuiLoadingButton-loading {
        background-color: ${designColors.red_1};
      }
      &.MuiLoadingButton-loading .MuiCircularProgress-svg circle {
        stroke: ${designColors.error};
      }

      &:hover {
        background-color: ${getHoverColor(designColors.red_1, "darker")};
      }
      &,
      &:hover {
        box-shadow: 0px 2px 0px 0px rgba(183, 35, 34, 0.33);
      }
    }
    &.MuiLoadingButton-loading {
      min-width: 100px;
    }
  `,
};
