import MuiIconButton from "@mui/material/IconButton";
import {Box, SxProps, Theme} from "@mui/material";
import {cx} from "@emotion/css";
import React from "react";

import {BmRemoveIcon} from "~/components/icons/simpleIcons";

import {styles} from "./DefaultImage.styles";

export interface BmDefaultImageProps {
  src: string;
  alt?: string;
  imgClassName?: string;
  imgSx?: SxProps<Theme>;
  wrapperClassName?: string;
  wrapperSx?: SxProps<Theme>;
  isSorting?: boolean;
  objectFit?: CSSStyleDeclaration["objectFit"];
  withShadow?: boolean;
  onRemoveClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const BmDefaultImage = React.forwardRef<HTMLDivElement, BmDefaultImageProps>(function BmDefaultImage(
  {
    src,
    alt,
    imgClassName,
    imgSx,
    wrapperClassName,
    wrapperSx,
    isSorting,
    objectFit = "contain",
    withShadow = true,
    onRemoveClick,
  },
  ref,
) {
  return (
    <Box
      ref={ref}
      className={cx("BmDefaultImageWrapper", styles.imageWrapper(withShadow), wrapperClassName)}
      sx={wrapperSx}
    >
      {onRemoveClick && (
        <MuiIconButton size="medium" onClick={onRemoveClick} className={styles.imageRemove(!!isSorting)}>
          <BmRemoveIcon id="remove-logo-icon" />
        </MuiIconButton>
      )}
      <Box component="img" src={src} alt={alt} className={cx(styles.image(objectFit), imgClassName)} sx={imgSx} />
    </Box>
  );
});
