import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  root: (error: boolean) => css`
    .MuiSelect-icon {
      top: calc(50% - 12px);
    }

    .MuiInputBase-root {
      padding: 0;
      .MuiInputBase-input {
        padding: 11px 12px;
      }
    }

    ${error &&
    css`
      .MuiSelect-select {
        color: ${designColors.error};
      }
      .MuiSelect-icon {
        fill: ${designColors.error};
      }
    `}
  `,
};
