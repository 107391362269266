import {useController} from "react-hook-form";
import React, {ChangeEventHandler, HTMLAttributes, useCallback} from "react";
import NumberFormat, {FormatInputValueFunction} from "react-number-format";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import get from "lodash.get";
import {cx} from "@emotion/css";

import {CommonInputType, ControlledInputProps} from "../_common/types";
import {commonStyles} from "../_common/styles";
import {hundredPercentFormat} from "./helpers";

export interface BmPrecentageInputProps extends Omit<CommonInputType, "type"> {
  value: string;
  allowNegative?: boolean;
  decimalSeparator?: string;
  decimalScale?: number;
  alwaysShowDecimals?: boolean;
  inputMode?: HTMLAttributes<any>["inputMode"];
  focusOnMount?: boolean;
  error?: boolean;
  format?: string | FormatInputValueFunction | undefined;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const BmPrecentageInput = React.forwardRef<HTMLInputElement, BmPrecentageInputProps>(function BmNumberInput(
  {className, value, allowNegative = false, startAdornment, endAdornment = "%", onChange, ...restProps},
  ref,
) {
  return (
    <NumberFormat
      {...restProps}
      customInput={TextField}
      value={value}
      allowNegative={allowNegative}
      InputProps={{
        startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
        endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
      }}
      inputProps={{ref}}
      fullWidth={true}
      className={cx(commonStyles.input(), className)}
      variant="outlined"
      onChange={onChange}
      format={hundredPercentFormat}
    />
  );
});

export interface BmControlledPercentageInputProps
  extends ControlledInputProps,
    Omit<BmPrecentageInputProps, "onChange" | "value" | "error"> {
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const BmControlledPercentageInput: React.FC<BmControlledPercentageInputProps> = ({
  name,
  control,
  rules,
  defaultValue,
  helperText = "",
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleChange = useCallback(
    (e) => {
      onChange?.(e);
      field.onChange(e);
    },
    [field, onChange],
  );

  return (
    <BmPrecentageInput
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleChange}
    />
  );
};
