import {
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfQuarter,
  addQuarters,
  endOfQuarter,
  startOfYear,
  addYears,
  endOfYear,
} from "date-fns";
import {zonedTimeToUtc} from "date-fns-tz";
import {TFunction} from "react-i18next";

import {ICheckboxedSelectOption, ISelectOption} from "~/components/inputs";
import {CUSTOMER_COUNTRIES} from "~/constants/countries";
import {ICustomDateRangeValue} from "~/pages/orders/containers/CustomDateRangeForm/converters";
import {getCountryName} from "~/utils/countries";

export const CUSTOM_DATE = "customDate";

export const defaultDateOptions = {
  thisMonth: {
    fromDate: zonedTimeToUtc(startOfMonth(new Date()), "UTC").toISOString(),
    toDate: zonedTimeToUtc(endOfMonth(new Date()), "UTC").toISOString(),
  },
  lastMonth: {
    fromDate: zonedTimeToUtc(startOfMonth(addMonths(new Date(), -1)), "UTC").toISOString(),
    toDate: zonedTimeToUtc(endOfMonth(addMonths(new Date(), -1)), "UTC").toISOString(),
  },
  thisQuarter: {
    fromDate: zonedTimeToUtc(startOfQuarter(new Date()), "UTC").toISOString(),
    toDate: zonedTimeToUtc(endOfQuarter(new Date()), "UTC").toISOString(),
  },
  lastQuarter: {
    fromDate: zonedTimeToUtc(startOfQuarter(addQuarters(new Date(), -1)), "UTC").toISOString(),
    toDate: zonedTimeToUtc(endOfQuarter(addQuarters(new Date(), -1)), "UTC").toISOString(),
  },
  thisYear: {
    fromDate: zonedTimeToUtc(startOfYear(new Date()), "UTC").toISOString(),
    toDate: zonedTimeToUtc(endOfYear(new Date()), "UTC").toISOString(),
  },
  lastYear: {
    fromDate: zonedTimeToUtc(startOfYear(addYears(new Date(), -1)), "UTC").toISOString(),
    toDate: zonedTimeToUtc(endOfYear(addYears(new Date(), -1)), "UTC").toISOString(),
  },
};

export const getDateOptions = (
  t: TFunction<"translations">,
  customDateEntity: ICustomDateRangeValue,
): ISelectOption[] => {
  return [
    {
      label: t("filters.date.this_month"),
      entity: defaultDateOptions.thisMonth,
    },
    {
      label: t("filters.date.last_month"),
      entity: defaultDateOptions.lastMonth,
    },
    {
      label: t("filters.date.this_quarter"),
      entity: defaultDateOptions.thisQuarter,
    },
    {
      label: t("filters.date.last_quarter"),
      entity: defaultDateOptions.lastQuarter,
    },
    {
      label: t("filters.date.this_year"),
      entity: defaultDateOptions.thisYear,
    },
    {
      label: t("filters.date.last_year"),
      entity: defaultDateOptions.lastYear,
    },
    {
      label: t("filters.date.all_time"),
      entity: {
        fromDate: null,
        toDate: null,
      },
    },
    customDateEntity && {
      label: t("filters.date.custom_date_range"),
      entity: customDateEntity,
    },
  ].filter(Boolean) as ISelectOption[];
};

export const getCountriesOptions = (
  t: TFunction<"translations">,
  selectedCountries: string[],
  countryConstraint: string[],
): ICheckboxedSelectOption[] => {
  const FILTERED_CUSTOMER_COUNTRIES = countryConstraint.length
    ? CUSTOMER_COUNTRIES.filter(({COUNTRY_CODE}) => countryConstraint.includes(COUNTRY_CODE))
    : CUSTOMER_COUNTRIES;

  return FILTERED_CUSTOMER_COUNTRIES.map(({COUNTRY_CODE}) => ({
    label: getCountryName(COUNTRY_CODE),
    id: COUNTRY_CODE,
    checked: selectedCountries.includes(COUNTRY_CODE),
  }));
};
