import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  switch: css`
    &.MuiSwitch-root {
      width: 54px;
      height: 30px;
      padding: 0;
      .MuiSwitch-switchBase {
        margin: 3px;
        padding: 0;
        transition-duration: 0.3s;
        &.Mui-checked {
          color: ${designColors.white};
          transform: translateX(24px);
          & + .MuiSwitch-track {
            background-color: ${designColors.primary};
            opacity: 1;
          }
          .MuiSwitch-thumb {
            background-color: ${designColors.white};
            &:before {
              background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath d='M4.70710678%2c12.2928932 C4.31658249%2c11.9023689 3.68341751%2c11.9023689 3.29289322%2c12.2928932 C2.90236893%2c12.6834175 2.90236893%2c13.3165825 3.29289322%2c13.7071068 L8.29289322%2c18.7071068 C8.68341751%2c19.0976311 9.31658249%2c19.0976311 9.70710678%2c18.7071068 L20.7071068%2c7.70710678 C21.0976311%2c7.31658249 21.0976311%2c6.68341751 20.7071068%2c6.29289322 C20.3165825%2c5.90236893 19.6834175%2c5.90236893 19.2928932%2c6.29289322 L9%2c16.5857864 L4.70710678%2c12.2928932 Z' id='check-path-1'%3e%3c/path%3e%3c/defs%3e%3cg id='check-Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='check'%3e%3cmask id='check-mask-2' fill='white'%3e%3cuse xlink:href='%23check-path-1'%3e%3c/use%3e%3c/mask%3e%3cuse id='check-Shape' fill='%234886E4' fill-rule='nonzero' xlink:href='%23check-path-1'%3e%3c/use%3e%3cg id='check-Group' mask='url(%23check-mask-2)' fill='%234886E4'%3e%3crect id='Rectangle' x='0' y='0' width='24' height='24'%3e%3c/rect%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
            }
          }
        }
      }
      .MuiSwitch-thumb {
        width: 24px;
        height: 24px;
        background-color: ${designColors.grey_3};
        box-shadow: none;
        transition-duration: 0.3s;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          transition: all 0.3s ease;
          top: 6px;
          left: 6px;
          background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath d='M4.70710678%2c12.2928932 C4.31658249%2c11.9023689 3.68341751%2c11.9023689 3.29289322%2c12.2928932 C2.90236893%2c12.6834175 2.90236893%2c13.3165825 3.29289322%2c13.7071068 L8.29289322%2c18.7071068 C8.68341751%2c19.0976311 9.31658249%2c19.0976311 9.70710678%2c18.7071068 L20.7071068%2c7.70710678 C21.0976311%2c7.31658249 21.0976311%2c6.68341751 20.7071068%2c6.29289322 C20.3165825%2c5.90236893 19.6834175%2c5.90236893 19.2928932%2c6.29289322 L9%2c16.5857864 L4.70710678%2c12.2928932 Z' id='check-path-1'%3e%3c/path%3e%3c/defs%3e%3cg id='check-Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='check'%3e%3cmask id='check-mask-2' fill='white'%3e%3cuse xlink:href='%23check-path-1'%3e%3c/use%3e%3c/mask%3e%3cuse id='check-Shape' fill='white' fill-rule='nonzero' xlink:href='%23check-path-1'%3e%3c/use%3e%3cg id='check-Group' mask='url(%23check-mask-2)' fill='white'%3e%3crect id='Rectangle' x='0' y='0' width='24' height='24'%3e%3c/rect%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
        }
      }
      .MuiSwitch-track {
        border-radius: 15px;
        background-color: ${designColors.white};
        opacity: 1;
        transition-duration: 0.5s;
        border: 1px solid ${designColors.grey_3};
      }
    }
  `,
  error: css`
    .MuiSvgIcon-root path {
      fill: ${designColors.error};
    }
  `,
};
