import {SalesChannels, ShopProductSearchInput, ShopProductStatus} from "~/api/generated";
import {getEnumValues} from "~/utils/enums";

import {useBackendFiltering} from "../useBackendFiltering";

const allowedFilters: (keyof ShopProductSearchInput)[] = ["search", "status", "salesChannel"];

export const searchParamsToAppliedWebshopProductsFilters = (searchParams: URLSearchParams): ShopProductSearchInput => {
  const nextAppliedWebshopProductsFilters = ([...searchParams] as [keyof ShopProductSearchInput, any][])
    .filter(([searchParamKey, searchParamValue]) => {
      switch (searchParamKey) {
        case "search":
          return typeof searchParamValue === "string" && searchParamValue.length > 0;
        case "status":
          return getEnumValues(ShopProductStatus).includes(searchParamValue);
        case "salesChannel":
          return getEnumValues(SalesChannels).includes(searchParamValue);
        default:
          return false;
      }
    })
    .reduce((filters, [searchParamKey, searchParamValue]) => {
      return {
        ...filters,
        [searchParamKey]: ["status", "salesChannel"].includes(searchParamKey)
          ? [...(filters[searchParamKey] || []), searchParamValue]
          : searchParamValue,
      };
    }, {} as ShopProductSearchInput);

  return nextAppliedWebshopProductsFilters;
};

export const useBackendWebshopProductsFiltering = (): [
  ShopProductSearchInput,
  (filters: ShopProductSearchInput) => void,
] => {
  const [appliedFilters, onFiltersChange] = useBackendFiltering<ShopProductSearchInput>(
    allowedFilters,
    searchParamsToAppliedWebshopProductsFilters,
  );

  return [appliedFilters, onFiltersChange];
};
