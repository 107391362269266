import {css} from "@emotion/css";

export const styles = {
  optionWrapper: css`
    svg,
    img {
      margin-right: 10px;
      margin-top: -3px;
    }
  `,
};
