import {useController} from "react-hook-form";
import TextField from "@mui/material/TextField";
import get from "lodash.get";
import {cx} from "@emotion/css";
import React, {ChangeEventHandler, useCallback} from "react";
import {InputAdornment} from "@mui/material";

import {CommonInputType, ControlledInputProps} from "../_common/types";
import {commonStyles} from "../_common/styles";
import {BmInputHelperText} from "../_common/InputHelperText";

export interface BmTextareaProps extends CommonInputType {
  className?: string;
  value?: string;
  focusOnMount?: boolean;
  minRows?: number;
  charLimit?: number;

  onChange: ChangeEventHandler<HTMLTextAreaElement>;
}

export const BmTextarea = React.forwardRef<HTMLTextAreaElement, BmTextareaProps>(function BmTextarea(
  {
    className,
    startAdornment,
    endAdornment,
    focusOnMount,
    minRows = 4,
    value,
    helperText,
    charLimit,

    ...restProps
  },
  ref,
) {
  return (
    <TextField
      {...restProps}
      InputProps={{
        startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
        endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
      }}
      inputProps={{ref, maxLength: charLimit}}
      className={cx(commonStyles.input(), className)}
      variant="outlined"
      fullWidth={true}
      multiline
      autoFocus={focusOnMount}
      // because value shouldn't be "null"
      value={value || ""}
      minRows={minRows}
      helperText={<BmInputHelperText helperText={helperText} charLimit={charLimit} value={value} />}
    />
  );
});

export interface BmControlledTextareaProps
  extends ControlledInputProps,
    Omit<BmTextareaProps, "onChange" | "value" | "error"> {
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

export const BmControlledTextarea: React.FC<BmControlledTextareaProps> = ({
  name,
  control,
  rules,
  defaultValue,
  helperText = "",
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleOnChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      onChange?.(e);
      field.onChange(e);
    },
    [field, onChange],
  );

  return (
    <BmTextarea
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
