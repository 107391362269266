import {IIconBase} from "~/components/icons/_common/types";

import {styles} from "./CountryOption.styles";

export interface CountryOptionProps {
  FlagComponent: React.ComponentType<IIconBase>;
  countryCode: string;
  children: React.ReactNode;
}

export const CountryOption: React.FC<CountryOptionProps> = ({FlagComponent, countryCode, children}) => {
  return (
    <span className={styles.optionWrapper}>
      <FlagComponent id={`${countryCode}-flag-icon`} size="lg" />
      {children}
    </span>
  );
};
