import {Redirect} from "react-router-dom";

import {AuthRight} from "~/api/generated";
import TaxWarehouseReport from "~/pages/reports/TaxWarehouseReport";

import {
  HOME_PATH,
  TERMS_CONDITIONS_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
  RESET_PASSWORD_PATH,
  RESET_PASSWORD_SENT_PATH,
  RESET_PASSWORD_CONFIRM_PATH,
  RESET_PASSWORD_SUCCESS_PATH,
  COMPANY_INFO_ROOT_PATH,
  COMPANY_INFO_EDIT_PATH,
  PAYMENT_INFO_ROOT_PATH,
  PAYMENT_INFO_EDIT_PATH,
  ORDERS_PATH,
  ORDER_NEW_PATH,
  ORDER_EDIT_PATH,
  BEVERAGE_NEW_PATH,
  BEVERAGE_PREVIEW_PATH,
  BEVERAGE_EDIT_PATH,
  BUNDLE_NEW_PATH,
  BUNDLE_PREVIEW_PATH,
  BUNDLE_EDIT_PATH,
  CUSTOMERS_PATH,
  CUSTOMER_NEW_PATH,
  CUSTOMER_PREVIEW_PATH,
  CUSTOMER_EDIT_PATH,
  WEBSHOP_PRODUCTS_PATH,
  WEBSHOP_PRODUCTS_ROOT_PATH,
  WEBSHOP_PRODUCT_PREVIEW_PATH,
  SHIPPING_RATES_ROOT_PATH,
  SHIPPING_RATES_PREVIEW_PATH,
  PICKING_LIST_PATH,
  READY_FOR_PICKUP_PATH,
  WEBSHOP_PRODUCT_NEW_PATH,
  WEBSHOP_PRODUCT_EDIT_PATH,
  BANNER_ROOT_PATH,
  BANNERS_PATH,
  PROMO_BANNER_EDIT_PATH,
  PROMO_BANNER_NEW_PATH,
  LOCATIONS_ROOT_PATH,
  LOCATIONS_EDIT_PATH,
  LOCATIONS_NEW_PATH,
  LOCATIONS_PREVIEW_PATH,
  EXTRA_PREVIEW_PATH,
  EXTRA_EDIT_PATH,
  EXTRA_NEW_PATH,
  INVITE_PATH,
  WEBSHOP_PRODUCT_META_EDIT_PATH,
  ANNOUNCEMENT_BAR_NEW_PATH,
  ANNOUNCEMENT_BAR_EDIT_PATH,
  DISCOUNT_ROOT_PATH,
  DISCOUNTS_PATH,
  DISCOUNT_NEW_PATH,
  DISCOUNT_EDIT_PATH,
  COLLECTION_ROOT_PATH,
  COLLECTIONS_PATH,
  COLLECTION_NEW_PATH,
  COLLECTION_PREVIEW_PATH,
  COLLECTION_EDIT_PATH,
  COLLECTION_EDIT_META_PATH,
  CONFIRM_STOCK_MOVEMENT_PATH,
  STOCK_MOVEMENTS_NEW_PATH,
  STOCK_MOVEMENTS_ROOT_PATH,
  STOCK_MOVEMENTS_EDIT_PATH,
  STOCK_MOVEMENTS_PREVIEW_PATH,
  CONFIGURATION_ROOT_PATH,
  CONFIGURATION_COUNTRY_PREVIEW_PATH,
  CONFIGURATION_EDIT_PATH,
  CONFIGURATION_COUNTRY_EDIT_PATH,
  USERS_PATH,
  USERS_INVITATION_PREVIEW_PATH,
  USERS_INVITATION_NEW_PATH,
  ORDER_TABS_PATH,
  ORDER_PREVIEW_PATH,
  FINANCIAL_STATEMENTS_PATH,
  NOTES_FOR_SETTLEMENT_PATH,
  INVITE_SETUP_PATH,
  BALANCE_PATH,
  SHIPPING_RATES_EDIT_PATH,
  TERMS_TO_UPDATE_PATH,
  WAREHOUSES_ROOT_PATH,
  LISTINGS_PATH,
  BUSINESS_TOOLS_PATH,
  COMMERCE_RATES_ROOT_PATH,
  FULFILLMENT_RULES_ROOT_PATH,
  BRANDS_PATH,
  BRAND_NEW_PATH,
  BRAND_EDIT_PATH,
  CUSTOMER_BUSINESS_MEMBER_PREVIEW_PATH,
  CREATE_REPORT_PATH,
  SHIPPING_PRICE_CALCULATOR_PATH,
  VAT_CALCULATOR_PATH,
  PRICE_CALCULATOR_PREVIEW_PATH,
  PRICE_CALCULATOR_NEW_PATH,
  PRICE_CALCULATOR_EDIT_PATH,
  PRODUCTS_PATH,
  PRICE_GROUPS_PATH as PRICING_GROUPS_PATH,
  PRICE_GROUP_NEW_PATH as PRICING_GROUP_NEW_PATH,
  PRICE_GROUP_PREVIEW_PATH as PRICING_GROUP_PREVIEW_PATH,
  PRICE_GROUP_EDIT_PATH as PRICING_GROUP_EDIT_PATH,
  ORDER_TRACEZILLA_DOCUMENTS_PATH,
  SERVICE_PAGE_PATH,
  DISABLED_CUSTOMERS_PATH,
  TAX_WATEHOUSE_REPORT_PATH,
} from "./paths";
import {Route} from "./types";
import {createLazyPageComponent, createGuestRoute, createSharedAccessRoute, createPrivateRoute} from "./utils";

const FulfillmentRules = createLazyPageComponent("~/pages/fulfillment/fulfillmentRules/FulfillmentRules");
const Login = createLazyPageComponent("~/pages/login/Login");
const SignUp = createLazyPageComponent("~/pages/signUp/SignUp");
const ResetPassword = createLazyPageComponent("~/pages/resetPassword/ResetPassword");
const SetNewPassword = createLazyPageComponent("~/pages/resetPassword/SetNewPassword");
const ResetPasswordSent = createLazyPageComponent("~/pages/resetPassword/ResetPasswordSent");
const SetNewPasswordSuccessfully = createLazyPageComponent("~/pages/resetPassword/SetNewPasswordSuccessfully");
const Orders = createLazyPageComponent("~/pages/orders/Orders");
const OrderNew = createLazyPageComponent("~/pages/orders/OrderNew");
const OrderEdit = createLazyPageComponent("~/pages/orders/OrderEdit");
const OrderPreview = createLazyPageComponent("~/pages/orders/OrderPreview");
const OrderTracezillaDocuments = createLazyPageComponent("~/pages/orders/OrderTracezillaDocuments");
const Customers = createLazyPageComponent("~/pages/customers/Customers");
const DisabledCustomers = createLazyPageComponent("~/pages/customers/DisabledCustomers");
const CustomerNew = createLazyPageComponent("~/pages/customers/CustomerNew");
const CustomerEdit = createLazyPageComponent("~/pages/customers/CustomerEdit");
const CustomerPreview = createLazyPageComponent("~/pages/customers/CustomerPreview");
const BusinessMemberPreview = createLazyPageComponent("~/pages/customers/BusinessMemberPreview");
const Products = createLazyPageComponent("~/pages/products/rootProducts/Products");
const BeverageNew = createLazyPageComponent("~/pages/products/beverages/BeverageNew");
const BeverageEdit = createLazyPageComponent("~/pages/products/beverages/BeverageEdit");
const BeveragePreview = createLazyPageComponent("~/pages/products/beverages/BeveragePreview");
const BundleNew = createLazyPageComponent("~/pages/products/bundles/BundleNew");
const BundleEdit = createLazyPageComponent("~/pages/products/bundles/BundleEdit");
const BundlePreview = createLazyPageComponent("~/pages/products/bundles/BundlePreview");
const ExtraNew = createLazyPageComponent("~/pages/products/extras/ExtraNew");
const ExtraEdit = createLazyPageComponent("~/pages/products/extras/ExtraEdit");
const ExtraPreview = createLazyPageComponent("~/pages/products/extras/ExtraPreview");
const Reports = createLazyPageComponent("~/pages/reports/Reports");
const RootWebshopProducts = createLazyPageComponent("~/pages/markets/webshop/RootWebshopProducts");
const WebshopProducts = createLazyPageComponent("~/pages/markets/webshop/WebshopProducts");
const WebshopProductNew = createLazyPageComponent("~/pages/markets/webshop/WebshopProductNew");
const WebshopProductEdit = createLazyPageComponent("~/pages/markets/webshop/WebshopProductEdit");
const WebshopProductPreview = createLazyPageComponent("~/pages/markets/webshop/WebshopProductPreview");
const WebshopProductMetaEdit = createLazyPageComponent("~/pages/markets/webshop/WebshopProductMetaEdit");
const RootBanners = createLazyPageComponent("~/pages/markets/banners/RootBanners");
const BannerPreview = createLazyPageComponent("~/pages/markets/banners/BannerPreview");
const PromoBannerEdit = createLazyPageComponent("~/pages/markets/banners/PromoBannerEdit");
const PromoBannerNew = createLazyPageComponent("~/pages/markets/banners/PromoBannerNew");
const AnnouncementBarNew = createLazyPageComponent("~/pages/markets/banners/AnnouncementBarNew");
const AnnouncementBarEdit = createLazyPageComponent("~/pages/markets/banners/AnnouncementBarEdit");
const RootCollections = createLazyPageComponent("~/pages/markets/collections/RootCollections");
const Collections = createLazyPageComponent("~/pages/markets/collections/Collections");
const CollectionEdit = createLazyPageComponent("~/pages/markets/collections/CollectionEdit");
const CollectionNew = createLazyPageComponent("~/pages/markets/collections/CollectionNew");
const CollectionPreview = createLazyPageComponent("~/pages/markets/collections/CollectionPreview");
const CollectionMetaEdit = createLazyPageComponent("~/pages/markets/collections/CollectionMetaEdit");
const RootDiscounts = createLazyPageComponent("~/pages/markets/discounts/RootDiscounts");
const Discounts = createLazyPageComponent("~/pages/markets/discounts/Discounts");
const DiscountNew = createLazyPageComponent("~/pages/markets/discounts/DiscountNew");
const DiscountEdit = createLazyPageComponent("~/pages/markets/discounts/DiscountEdit");
const RootShipping = createLazyPageComponent("~/pages/markets/shippingRates/RootShipping");
const Shipping = createLazyPageComponent("~/pages/markets/shippingRates/Shipping");
const ConfirmStockMovement = createLazyPageComponent("~/pages/fulfillment/stockMovement/ConfirmStockMovement");
const StockMovements = createLazyPageComponent("~/pages/fulfillment/stockMovement/StockMovements");
const StockMovementNew = createLazyPageComponent("~/pages/fulfillment/stockMovement/StockMovementNew");
const StockMovementEdit = createLazyPageComponent("~/pages/fulfillment/stockMovement/StockMovementEdit");
const StockMovementPreview = createLazyPageComponent("~/pages/fulfillment/stockMovement/StockMovementPreview");
const ConfirmInvitation = createLazyPageComponent("~/pages/invite/ConfirmInvitation");
const ServicePage = createLazyPageComponent("~/pages/settings/servicePage/ServicePage");
const CompanyPreview = createLazyPageComponent("~/pages/settings/company/CompanyPreview");
const CompanyEdit = createLazyPageComponent("~/pages/settings/company/CompanyEdit");
const Users = createLazyPageComponent("~/pages/settings/users/Users");
const InvitationPreview = createLazyPageComponent("~/pages/settings/users/InvitationPreview");
const InvitationNew = createLazyPageComponent("~/pages/settings/users/InvitationNew");
const PaymentPreview = createLazyPageComponent("~/pages/settings/payment/PaymentPreview");
const PaymentEdit = createLazyPageComponent("~/pages/settings/payment/PaymentEdit");
const Configuration = createLazyPageComponent("~/pages/settings/configuration/Configuration");
const ConfigurationEdit = createLazyPageComponent("~/pages/settings/configuration/ConfigurationEdit");
const ConfigurationShopEdit = createLazyPageComponent("~/pages/settings/configuration/ConfigurationShopEdit");
const LocationsPreview = createLazyPageComponent("~/pages/settings/locations/LocationsPreview");
const LocationNew = createLazyPageComponent("~/pages/settings/locations/LocationNew");
const LocationEdit = createLazyPageComponent("~/pages/settings/locations/LocationEdit");
const Brands = createLazyPageComponent("~/pages/settings/brands/Brands");
const BrandNew = createLazyPageComponent("~/pages/settings/brands/BrandNew");
const BrandEdit = createLazyPageComponent("~/pages/settings/brands/BrandEdit");
const TermsToUpdate = createLazyPageComponent("~/pages/termsToUpdate/TermsToUpdate");
const TermsAndConditions = createLazyPageComponent("~/pages/termsAndConditions/TermsAndConditions");
const PickingList = createLazyPageComponent("~/pages/pickingList/PickingList");
const ReadyForPickup = createLazyPageComponent("~/pages/readyForPickup/ReadyForPickup");
const NotFound = createLazyPageComponent("~/pages/notFound/NotFound");
const Warehouses = createLazyPageComponent("~/pages/fulfillment/warehouses/Warehouses");
const Listings = createLazyPageComponent("~/pages/listings/Listings");
const Commerce = createLazyPageComponent("~/pages/markets/commerce/Commerce");
const BusinessTools = createLazyPageComponent("~/pages/businessTools/BusinessTools");
const ShippingPriceCalculator = createLazyPageComponent("~/pages/businessTools/ShippingPriceCalculatorPage");
const VATCalculator = createLazyPageComponent("~/pages/businessTools/VATCalculatorPage");
const PriceCalculatorPreviewPage = createLazyPageComponent("~/pages/businessTools/PriceCalculatorPreviewPage");
const PriceCalculatorNewPage = createLazyPageComponent("~/pages/businessTools/PriceCalculatorNewPage");
const PriceCalculatorEditPage = createLazyPageComponent("~/pages/businessTools/PriceCalculatorEditPage");
const PricingGroups = createLazyPageComponent("~/pages/pricingGroups/PricingGroups");
const PricingGroupPreview = createLazyPageComponent("~/pages/pricingGroups/PricingGroupPreview");
const PricingGroupNew = createLazyPageComponent("~/pages/pricingGroups/PricingGroupNew");
const PricingGroupEdit = createLazyPageComponent("~/pages/pricingGroups/PricingGroupEdit");

const publicRoutes: Route[] = [
  createGuestRoute(Login, LOGIN_PATH),
  createGuestRoute(SignUp, SIGNUP_PATH),
  createGuestRoute(ResetPassword, RESET_PASSWORD_PATH),
  createGuestRoute(ResetPasswordSent, RESET_PASSWORD_SENT_PATH),
  createGuestRoute(SetNewPassword, RESET_PASSWORD_CONFIRM_PATH, {exact: false, strict: false}),
  createGuestRoute(SetNewPasswordSuccessfully, RESET_PASSWORD_SUCCESS_PATH),
  createSharedAccessRoute(PickingList, PICKING_LIST_PATH),
  createSharedAccessRoute(ReadyForPickup, READY_FOR_PICKUP_PATH),
  createSharedAccessRoute(ConfirmStockMovement, CONFIRM_STOCK_MOVEMENT_PATH),
  createSharedAccessRoute(ConfirmInvitation, INVITE_SETUP_PATH),
  createSharedAccessRoute(ConfirmInvitation, INVITE_PATH),
  createSharedAccessRoute(TermsAndConditions, TERMS_CONDITIONS_PATH),
];

const privateRoutes: Route[] = [
  createPrivateRoute(Redirect, HOME_PATH, {to: ORDERS_PATH}),
  createPrivateRoute(BusinessMemberPreview, CUSTOMER_BUSINESS_MEMBER_PREVIEW_PATH, {accessRight: AuthRight.CustomerRead}),
  createPrivateRoute(Orders, ORDERS_PATH, {accessRight: AuthRight.OrderRead}),
  createPrivateRoute(Orders, ORDER_TABS_PATH, {accessRight: AuthRight.OrderRead}),
  createPrivateRoute(OrderNew, ORDER_NEW_PATH, {accessRight: AuthRight.OrderWrite}),
  createPrivateRoute(OrderEdit, ORDER_EDIT_PATH, {accessRight: AuthRight.OrderWrite}),
  createPrivateRoute(OrderPreview, ORDER_PREVIEW_PATH, {accessRight: AuthRight.OrderRead}),
  createPrivateRoute(OrderTracezillaDocuments, ORDER_TRACEZILLA_DOCUMENTS_PATH, {accessRight: AuthRight.OrderRead}),
  createPrivateRoute(Products, PRODUCTS_PATH, {accessRight: AuthRight.ProductRead}),
  createPrivateRoute(BeverageNew, BEVERAGE_NEW_PATH, {accessRight: AuthRight.ProductWrite}),
  createPrivateRoute(BeveragePreview, BEVERAGE_PREVIEW_PATH, {accessRight: AuthRight.ProductRead}),
  createPrivateRoute(BeverageEdit, BEVERAGE_EDIT_PATH, {accessRight: AuthRight.ProductWrite}),
  createPrivateRoute(BundleNew, BUNDLE_NEW_PATH, {accessRight: AuthRight.ProductWrite}),
  createPrivateRoute(BundlePreview, BUNDLE_PREVIEW_PATH, {accessRight: AuthRight.ProductRead}),
  createPrivateRoute(BundleEdit, BUNDLE_EDIT_PATH, {accessRight: AuthRight.ProductWrite}),
  createPrivateRoute(ExtraNew, EXTRA_NEW_PATH, {accessRight: AuthRight.ProductWrite}),
  createPrivateRoute(ExtraPreview, EXTRA_PREVIEW_PATH, {accessRight: AuthRight.ProductRead}),
  createPrivateRoute(ExtraEdit, EXTRA_EDIT_PATH, {accessRight: AuthRight.ProductWrite}),
  createPrivateRoute(Customers, CUSTOMERS_PATH, {accessRight: AuthRight.CustomerRead}),
  createPrivateRoute(DisabledCustomers, DISABLED_CUSTOMERS_PATH, {accessRight: AuthRight.CustomerRead}),
  createPrivateRoute(PricingGroups, PRICING_GROUPS_PATH, {accessRight: AuthRight.PricingGroupRead}),
  createPrivateRoute(CustomerNew, CUSTOMER_NEW_PATH, {accessRight: AuthRight.CustomerWrite}),
  createPrivateRoute(PricingGroupNew, PRICING_GROUP_NEW_PATH, {accessRight: AuthRight.PricingGroupWrite}),
  createPrivateRoute(CustomerPreview, CUSTOMER_PREVIEW_PATH, {accessRight: AuthRight.CustomerRead}),
  createPrivateRoute(PricingGroupPreview, PRICING_GROUP_PREVIEW_PATH, {accessRight: AuthRight.PricingGroupRead}),
  createPrivateRoute(CustomerEdit, CUSTOMER_EDIT_PATH, {accessRight: AuthRight.CustomerWrite}),
  createPrivateRoute(PricingGroupEdit, PRICING_GROUP_EDIT_PATH, {accessRight: AuthRight.PricingGroupWrite}),
  createPrivateRoute(Reports, BALANCE_PATH, {accessRight: AuthRight.FinanceRead}),
  createPrivateRoute(Reports, NOTES_FOR_SETTLEMENT_PATH, {accessRight: AuthRight.FinanceRead}),
  createPrivateRoute(Reports, FINANCIAL_STATEMENTS_PATH, {accessRight: AuthRight.FinanceRead}),
  createPrivateRoute(Reports, CREATE_REPORT_PATH, {accessRight: AuthRight.ReportRead}),
  createPrivateRoute(TaxWarehouseReport, TAX_WATEHOUSE_REPORT_PATH, {accessRight: AuthRight.ReportRead}),
  createPrivateRoute(Commerce, COMMERCE_RATES_ROOT_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(RootWebshopProducts, WEBSHOP_PRODUCTS_ROOT_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(WebshopProducts, WEBSHOP_PRODUCTS_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(WebshopProductNew, WEBSHOP_PRODUCT_NEW_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(WebshopProductPreview, WEBSHOP_PRODUCT_PREVIEW_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(WebshopProductEdit, WEBSHOP_PRODUCT_EDIT_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(WebshopProductMetaEdit, WEBSHOP_PRODUCT_META_EDIT_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(RootCollections, COLLECTION_ROOT_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(Collections, COLLECTIONS_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(CollectionNew, COLLECTION_NEW_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(CollectionPreview, COLLECTION_PREVIEW_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(CollectionEdit, COLLECTION_EDIT_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(CollectionMetaEdit, COLLECTION_EDIT_META_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(RootDiscounts, DISCOUNT_ROOT_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(Discounts, DISCOUNTS_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(DiscountEdit, DISCOUNT_EDIT_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(DiscountNew, DISCOUNT_NEW_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(RootBanners, BANNER_ROOT_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(BannerPreview, BANNERS_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(AnnouncementBarNew, ANNOUNCEMENT_BAR_NEW_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(AnnouncementBarEdit, ANNOUNCEMENT_BAR_EDIT_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(PromoBannerEdit, PROMO_BANNER_EDIT_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(PromoBannerNew, PROMO_BANNER_NEW_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(RootShipping, SHIPPING_RATES_ROOT_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(Shipping, SHIPPING_RATES_EDIT_PATH, {accessRight: AuthRight.CommerceWrite}),
  createPrivateRoute(Shipping, SHIPPING_RATES_PREVIEW_PATH, {accessRight: AuthRight.CommerceRead}),
  createPrivateRoute(Listings, LISTINGS_PATH, {accessRight: AuthRight.SettingsRead}),
  createPrivateRoute(StockMovements, STOCK_MOVEMENTS_ROOT_PATH, {accessRight: AuthRight.FulfillmentRead}),
  createPrivateRoute(StockMovementNew, STOCK_MOVEMENTS_NEW_PATH, {accessRight: AuthRight.FulfillmentWrite}),
  createPrivateRoute(StockMovementPreview, STOCK_MOVEMENTS_PREVIEW_PATH, {accessRight: AuthRight.FulfillmentRead}),
  createPrivateRoute(StockMovementEdit, STOCK_MOVEMENTS_EDIT_PATH, {accessRight: AuthRight.FulfillmentWrite}),
  createPrivateRoute(Warehouses, WAREHOUSES_ROOT_PATH, {accessRight: AuthRight.FulfillmentRead}),
  createPrivateRoute(FulfillmentRules, FULFILLMENT_RULES_ROOT_PATH, {accessRight: AuthRight.FulfillmentRead}),
  createPrivateRoute(PaymentPreview, PAYMENT_INFO_ROOT_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(PaymentEdit, PAYMENT_INFO_EDIT_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(CompanyPreview, COMPANY_INFO_ROOT_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(CompanyEdit, COMPANY_INFO_EDIT_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(Configuration, CONFIGURATION_ROOT_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(ConfigurationEdit, CONFIGURATION_EDIT_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(Configuration, CONFIGURATION_COUNTRY_PREVIEW_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(ConfigurationShopEdit, CONFIGURATION_COUNTRY_EDIT_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(Users, USERS_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(InvitationNew, USERS_INVITATION_NEW_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(InvitationPreview, USERS_INVITATION_PREVIEW_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(LocationsPreview, LOCATIONS_ROOT_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(LocationNew, LOCATIONS_NEW_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(Redirect, LOCATIONS_PREVIEW_PATH, {to: LOCATIONS_ROOT_PATH, accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(LocationEdit, LOCATIONS_EDIT_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(Brands, BRANDS_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(BrandNew, BRAND_NEW_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(BrandEdit, BRAND_EDIT_PATH, {accessRight: AuthRight.SettingsWrite}),
  createPrivateRoute(BusinessTools, BUSINESS_TOOLS_PATH, {accessRight: AuthRight.BusinessTools}),
  createPrivateRoute(ShippingPriceCalculator, SHIPPING_PRICE_CALCULATOR_PATH, {accessRight: AuthRight.BusinessTools}),
  createPrivateRoute(VATCalculator, VAT_CALCULATOR_PATH, {accessRight: AuthRight.BusinessTools}),
  createPrivateRoute(PriceCalculatorPreviewPage, PRICE_CALCULATOR_PREVIEW_PATH, {accessRight: AuthRight.BusinessTools}),
  createPrivateRoute(PriceCalculatorNewPage, PRICE_CALCULATOR_NEW_PATH, {accessRight: AuthRight.BusinessTools}),
  createPrivateRoute(PriceCalculatorEditPage, PRICE_CALCULATOR_EDIT_PATH, {accessRight: AuthRight.BusinessTools}),
  createPrivateRoute(TermsToUpdate, TERMS_TO_UPDATE_PATH),
  createPrivateRoute(ServicePage, SERVICE_PAGE_PATH),
];

export const routes: Route[] = [...publicRoutes, ...privateRoutes, createSharedAccessRoute(NotFound, "*")];
