import {useController} from "react-hook-form";
import TextField from "@mui/material/TextField";

import {CommonInputType, ControlledInputProps} from "../_common/types";
import {commonStyles} from "../_common/styles";

interface BmControlledHiddenInputProps extends CommonInputType, ControlledInputProps {}

export const BmControlledHiddenInput: React.FC<BmControlledHiddenInputProps> = ({name, control, defaultValue}) => {
  const {field} = useController({name, control, defaultValue});

  return <TextField {...field} className={commonStyles.hidden} />;
};
