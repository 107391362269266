import {cx} from "@emotion/css";
import {ReactComponent as SvgEye} from "assets/svg/eye.svg";

import {designColors} from "~/ui";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmEyeIcon: React.FC<IIconBase> = ({id, size = "md", color = designColors.black, className}) => {
  return <SvgEye id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
