import {css} from "@emotion/css";

import {designColors, designTokens} from "~/ui";

export const commonStyles = {
  commonSelect: (isDirty?: boolean) => css`
    &.MuiInputBase-root {
      background-color: ${designColors.white};
      &.Mui-focused {
        background-color: ${designColors.grey_1};

        .MuiOutlinedInput-notchedOutline {
          border-color: ${designColors.grey_3};
          border-width: 1px;
        }

        .MuiSelect-nativeInput {
          padding: 0;
          border: none;
        }
      }

      .MuiInputBase-input {
        min-height: 20px;
        padding: 11px 12px;

        font-weight: ${designTokens.fontWeight.semiBold};
        line-height: 20px;

        .MuiBox-root {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        ${isDirty &&
        css`
          padding-right: 46px !important;
          &:after {
            content: "";
            position: absolute;
            right: 34px;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${designColors.primary};
          }
        `}
      }

      .MuiSelect-icon {
        top: 10px;
      }
    }
  `,
};
