import {
  Toolbar,
  HeadingLevelButtonGroup,
  BasicFormattingButtonGroup,
  ToggleBulletListButton,
  ToggleBlockquoteButton,
  CommandButtonGroup,
} from "@remirror/react";

import {styles} from "./RichTextToolbar.styles";

interface BmRichTextToolbarProps {}

export const BmRichTextToolbar: React.FC<BmRichTextToolbarProps> = () => {
  return (
    <Toolbar
      sx={{
        flexWrap: "wrap",
        p: 1,
        borderRadius: "4px 4px 0px 0px",
        borderBottom: "1px solid #DBDBDB",
        background: "#F5F5F5",
        boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.20)",
      }}
      className={styles.root}
      useFlexGap
    >
      <HeadingLevelButtonGroup />
      <BasicFormattingButtonGroup />
      <CommandButtonGroup>
        <ToggleBulletListButton />
      </CommandButtonGroup>
      <CommandButtonGroup>
        <ToggleBlockquoteButton />
      </CommandButtonGroup>
    </Toolbar>
  );
};
