import MenuItem from "@mui/material/MenuItem";
import {ListItemIcon, ListItemText} from "@mui/material";

import {BmPlusIcon} from "~/components/icons/simpleIcons";

import {autocompleteButtonStyles} from "./AutocompleteButton.styles";

export interface BmAutocompleteButtonProps {
  title: string;
  onClick?: VoidFunction;
}

export const BmAutocompleteButton: React.FC<BmAutocompleteButtonProps> = ({onClick, title}) => {
  return (
    <MenuItem onClick={onClick} className={autocompleteButtonStyles.button}>
      <ListItemIcon>
        <BmPlusIcon id="plus-icon" />
      </ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </MenuItem>
  );
};
