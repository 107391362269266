// all svg icons of flags should be taken from http://flagicons.lipis.dev/

import {cx} from "@emotion/css";
import {Box} from "@mui/material";

import {flagIconStyles} from "../styles";
import {IIconBase} from "../../_common/types";

export interface GenericFlagProps extends IIconBase {
  flagUrl: string;
}

export const GenericFlag: React.FC<GenericFlagProps> = ({id, size = "lg", className, flagUrl}) => {
  return <Box component="img" id={id} className={cx(flagIconStyles(size), className)} src={flagUrl} alt="flag" />;
};
