import {useController} from "react-hook-form";
import React, {useCallback, useMemo, useState} from "react";
import Grid from "@mui/material/Grid";
import get from "lodash.get";
import {countryDialcode, flagUrl} from "@bemakers/countries";

import {CountryCode} from "~/constants/countryCode";
import {BmDynamicSelect, CountryOption} from "~/components/inputs/Select";
import {ICountry} from "~/model/helperTypes/ICountries";
import {useDidUpdate} from "~/hooks";
import {getCountryCodeByPhoneNumber} from "~/utils/countries";
import {getFlagComponent} from "~/components/icons/flagIcons";

import {ControlledInputProps} from "../../_common/types";
import {findCountryByCode, getCountryCodeOptions, getInitCountry} from "./private/helpers";
import {PhoneInput} from "./private/PhoneInput/PhoneInput";

export interface BmComplexPhoneInputProps {
  name: string;
  value: string;
  countries: ICountry[];
  defaultCountry?: CountryCode;
  label: string;
  countryCodeLabel?: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  className?: string;
  onChange: (value: string) => void;
}

export const BmComplexPhoneInput = React.forwardRef<HTMLInputElement, BmComplexPhoneInputProps>(
  function BmComplexPhoneInput(
    {name, value, countries, defaultCountry, label, countryCodeLabel, error, helperText, disabled, className, onChange},
    ref,
  ) {
    const phoneCountryCode = getCountryCodeByPhoneNumber(value);
    const [country, setCountry] = useState<ICountry>(getInitCountry(phoneCountryCode, defaultCountry, countries));
    const countryCodeOptions = useMemo(() => {
      const nextCountryCodeOptions = getCountryCodeOptions(countries);
      if (!phoneCountryCode) return nextCountryCodeOptions;
      if (countries.map(({COUNTRY_CODE}) => COUNTRY_CODE).includes(phoneCountryCode)) return nextCountryCodeOptions;

      return [
        ...nextCountryCodeOptions,
        {
          value: phoneCountryCode,
          label: (
            <CountryOption
              FlagComponent={getFlagComponent(phoneCountryCode, flagUrl(phoneCountryCode))}
              countryCode={phoneCountryCode as CountryCode}
            >
              {" "}
              +{countryDialcode(phoneCountryCode)}
            </CountryOption>
          ),
        },
      ];
    }, [countries, phoneCountryCode]);

    const nationalNumberValue = useMemo(() => {
      const nationalNumberRegExp = new RegExp(`^\\+${country.DIAL_CODE}\\s*(.+)`);
      const [, nationalNumber = ""] = value.match(nationalNumberRegExp) || [];
      return nationalNumber;
    }, [country, value]);

    const changeCountryCode = useCallback(
      (country: CountryCode) => {
        const newCountry = findCountryByCode(country)!;
        setCountry(newCountry);
        onChange(`+${newCountry.DIAL_CODE}`);
      },
      [onChange],
    );

    const handleChangeCountryCode = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        changeCountryCode(event.target.value as CountryCode);
      },
      [changeCountryCode],
    );

    const handleChangePhoneNumber = useCallback(
      (value: string) => {
        onChange(`+${country.DIAL_CODE} ${value}`);
      },
      [country.DIAL_CODE, onChange],
    );

    useDidUpdate(() => {
      if (phoneCountryCode) return;
      if (!defaultCountry) return;
      changeCountryCode(defaultCountry);
      // don't add `phoneCountryCode` to dependencies
    }, [changeCountryCode, defaultCountry]);

    return (
      <Grid container columnSpacing={4}>
        <Grid item xs={6}>
          <BmDynamicSelect
            name="countryCode"
            label={countryCodeLabel}
            options={countryCodeOptions}
            value={country.COUNTRY_CODE}
            onChange={handleChangeCountryCode}
          />
        </Grid>
        <Grid item xs={6}>
          <PhoneInput
            ref={ref}
            key={country.COUNTRY_CODE}
            className={className}
            name={name}
            value={nationalNumberValue}
            label={label}
            error={error}
            helperText={helperText}
            disabled={disabled}
            countryCallingCode={country.DIAL_CODE}
            onChange={handleChangePhoneNumber}
          />
        </Grid>
      </Grid>
    );
  },
);

export interface BmControlledComplexPhoneInputProps
  extends ControlledInputProps,
    Omit<BmComplexPhoneInputProps, "onChange" | "value"> {
  className?: string;
  onChange?: (value: string) => void;
}

export const BmControlledComplexPhoneInput: React.FC<BmControlledComplexPhoneInputProps> = ({
  name,
  control,
  rules,
  defaultValue,
  helperText,
  onChange,
  ...restProps
}) => {
  const {
    // extract onChange to prevent render loop for BmComplexPhoneInput in `useDidUpdate`
    field: {onChange: onFieldChange, ...field},
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleChange = useCallback(
    (e) => {
      onChange?.(e);
      onFieldChange(e);
    },
    [onFieldChange, onChange],
  );

  return (
    <BmComplexPhoneInput
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      value={field.value || ""}
      onChange={handleChange}
    />
  );
};
