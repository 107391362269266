import {css} from "@emotion/css";

import {designColors, designTokens} from "~/ui";

export const styles = {
  root: css`
    .MuiFormLabel-root {
      font-weight: ${designTokens.fontWeight.semiBold};
      font-style: normal;
    }
    .MuiOutlinedInput-root {
      font-weight: ${designTokens.fontWeight.semiBold};
      &.Mui-focused {
        background-color: ${designColors.grey_1};
        .MuiOutlinedInput-notchedOutline {
          border-color: ${designColors.grey_3};
          border-width: 1px;
        }
      }
    }
    .MuiSelect-select {
      padding: 12px;

      .MuiBox-root {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .MuiList-root {
      li {
        font-size: ${designTokens.fontSize.sm};
        font-weight: ${designTokens.fontWeight.medium};
        padding: 12px 14px;
        padding-right: 30px;
      }
    }
  `,
};
