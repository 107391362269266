import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  label: css`
    &.MuiFormControlLabel-root {
      position: relative;
      min-height: 24px;

      margin-left: -6px;
    }
    .MuiTypography-root {
      padding-left: 35px;
    }
  `,
  checkbox: css`
    &.MuiButtonBase-root.MuiCheckbox-root {
      position: absolute;
      padding: 6px;
    }
    &.Mui-disabled svg {
      *[stroke^="\#"] {
        stroke: rgba(0, 0, 0, 0.26);
      }
    }
    &.Mui-disabled.Mui-checked svg {
      *[stroke^="\#"] {
        stroke: ${designColors.white};
      }
      *[fill^="\#"] {
        fill: rgba(0, 0, 0, 0.26);
      }
    }
  `,
  input: css`
    .MuiBox-root > div {
      margin-top: 12px;
      margin-bottom: 6px;
    }
  `,
  error: css`
    &.MuiCheckbox-root svg {
      *[stroke^="\#"] {
        stroke: ${designColors.error};
      }
    }
    &.Mui-checked svg {
      *[stroke^="\#"] {
        stroke: ${designColors.white};
      }
      *[fill^="\#"] {
        fill: ${designColors.error};
      }
    }
  `,
  helperText: css`
    margin-left: 28px !important;
    margin-top: -6px !important;
  `,
  checkboxContent: (error: boolean) => css`
    &.MuiPaper-root {
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;

      &:before {
        display: none;
      }

      &.Mui-expanded {
        margin: 0;
      }
    }
    .MuiAccordionSummary-root {
      min-height: auto;
      padding: 0;

      &.Mui-expanded {
        min-height: 46px;
      }
    }
    .MuiAccordionSummary-content {
      flex-direction: column;
      margin: 3px 0;

      &.Mui-expanded {
        margin: 0;
      }
    }
    .MuiCollapse-wrapper {
      padding-left: 10px;
      padding-bottom: 12px;
    }
    .MuiAccordionDetails-root {
      padding: 12px;
      ${error
        ? css`
            border-left: 2px solid ${designColors.error};
          `
        : css`
            border-left: 2px solid ${designColors.blue_2};
          `}
    }
  `,
};
