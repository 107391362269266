import {css} from "@emotion/css";
import {rgba} from "polished";

import {designColors} from "~/ui";

export const styles = {
  root: (isFocused: boolean, isEmpty: boolean, error: boolean) => css`
    &.remirror-theme {
      border-radius: 4px;

      ${isEmpty
        ? css`
            border: 1px solid ${designColors.grey_3};
          `
        : css`
            border: 1px solid ${rgba(designColors.black, 0.87)};
          `}

      &:hover {
        border-color: ${rgba(designColors.black, 0.87)};
      }

      ${isFocused &&
      css`
        &,
        &:hover {
          border-color: ${designColors.primary};
          box-shadow: 0 0 0 1px ${designColors.primary};
        }
      `}

      ${error &&
      css`
        &,
        &:hover {
          border-color: ${designColors.error};
        }
      `}

      ${error &&
      isFocused &&
      css`
        &,
        &:hover {
          box-shadow: 0 0 0 1px ${designColors.error};
        }
      `}
    }
    &.remirror-theme .remirror-editor {
      background-color: ${designColors.white};
      border: none;

      &,
      &:focus,
      &:active {
        box-shadow: none;
      }
    }
    &.remirror-theme .remirror-editor {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      > p,
      blockquote > p {
        margin-bottom: 1rem;
      }
    }
    &.remirror-theme .remirror-editor-wrapper {
      padding-top: 0;
    }
  `,
};
