import {useCallback, useEffect, useMemo, useRef, useState} from "react";

import {useDidUpdate, useFixedSearchParams, useLocalStorage, useDidMount} from "~/hooks";
import {PageInfo} from "~/api/generated";

import {ITablePaginationCallbacks, ITablePaginationParams, PaginationDirectionType} from "./ITablePagination";
import {DEFAULT_PER_PAGE, perPageProtector} from "./TablePagination";

type UseTablePaginationFromSearchParamsReturnTuple = [ITablePaginationParams, ITablePaginationCallbacks];

export const useTablePaginationFromSearchParams = (
  pageInfo: PageInfo | undefined,
): UseTablePaginationFromSearchParamsReturnTuple => {
  const skipUpdatingLocalParams = useRef(false);
  const [searchParams, setSearchParams] = useFixedSearchParams();
  const [localSearchParams, setLocalSearchParams] = useState(searchParams);
  const [storedPerPage, storePerPage] = useLocalStorage("perPage");

  const startCursorParam = localSearchParams.get("startCursor");
  const endCursorParam = localSearchParams.get("endCursor");

  const paginationParams = useMemo(() => {
    return {
      perPage: perPageProtector(storedPerPage),
      startCursor: startCursorParam || undefined,
      endCursor: endCursorParam || undefined,
    };
  }, [storedPerPage, startCursorParam, endCursorParam]);

  const onPageChange = useCallback(
    (direction: PaginationDirectionType, nextCursor: string) => {
      setSearchParams((prevSearchParams) => {
        const nextSearchParams = [...prevSearchParams].filter(([key]) => !["startCursor", "endCursor"].includes(key));
        return [...nextSearchParams, direction === "next" ? ["endCursor", nextCursor] : ["startCursor", nextCursor]];
      });
    },
    [setSearchParams],
  );

  const onRowsPerPageChange = useCallback(
    (nextRowsPerPage: number) => {
      storePerPage(String(nextRowsPerPage));
    },
    [storePerPage],
  );

  useDidMount(() => {
    if (!storedPerPage) return;
    const protectedStoredPerPage = perPageProtector(storedPerPage);
    if (protectedStoredPerPage === DEFAULT_PER_PAGE) return;
    onRowsPerPageChange(protectedStoredPerPage);
  });

  useDidUpdate(() => {
    if (skipUpdatingLocalParams.current) {
      skipUpdatingLocalParams.current = false;
      return;
    }
    setLocalSearchParams(searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (!pageInfo) return;
    if (!searchParams.get("startCursor")) return;
    if (pageInfo.hasPreviousPage) return;

    skipUpdatingLocalParams.current = true;
    setSearchParams((prevSearchParams) => [...prevSearchParams].filter(([key]) => !["startCursor"].includes(key)));
  }, [searchParams, pageInfo, setSearchParams]);

  return [paginationParams, {onPageChange, onRowsPerPageChange}];
};
