import {css} from "@emotion/css";

import {designTokens} from "~/ui";

export const styles = {
  root: css`
    .MuiFormHelperText-root {
      margin-left: 0;
    }
  `,
  label: css`
    &.MuiInputLabel-root {
      &:not(.MuiInputLabel-shrink) {
        font-weight: ${designTokens.fontWeight.semiBold};
        color: rgba(0, 0, 0, 0.87);
        transform: translate(12px, 11px) scale(1);
      }
    }
  `,
};
