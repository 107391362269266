import {format} from "date-fns";

import {MakerReportableSummaryFragment} from "~/api/generated";
import {IDynamicSelectOption} from "~/components/inputs";

export const getYearOptions = (makerReporttables: MakerReportableSummaryFragment[]): IDynamicSelectOption[] =>
  makerReporttables.slice(3).reduce((acc, makerReporttable) => {
    if (!acc.some((option) => option.value === makerReporttable.year)) {
      acc.push({
        label: makerReporttable.year,
        value: makerReporttable.year,
      });
    }
    return acc;
  }, [] as IDynamicSelectOption[]);

export const geMonthOptions = (makerReporttables: MakerReportableSummaryFragment[]): IDynamicSelectOption[] =>
  makerReporttables.slice(3).reduce((acc, makerReporttable) => {
    if (!acc.some((option) => option.value === makerReporttable.period)) {
      acc.push({
        label: format(new Date(makerReporttable.reports[0].fromDate), "MMMM"),
        value: format(new Date(makerReporttable.reports[0].fromDate), "MMMM"),
      });
    }
    return acc;
  }, [] as IDynamicSelectOption[]);
