import {Box, Grid, SxProps, Theme} from "@mui/material";
import get from "lodash.get";
import {useCallback} from "react";
import {useController} from "react-hook-form";

import emptySrc from "~/assets/img/man.png";
import {BmHelperText} from "~/components/helperText";

import {ControlledInputProps} from "../_common/types";
import {BmSelectorCard} from "./private/SelectorCard/SelectorCard";
import {ISelectorOption} from "./ISelector";

export interface BmSelectorFieldProps {
  className?: string;
  sx?: SxProps<Theme>;
  cardSx?: SxProps<Theme>;
  options: ISelectorOption[];
  name: string;
  value?: string | null;
  error?: boolean;
  helperText?: string;
  onChange: (value: string | null) => void;
}

export const BmSelectorField: React.FC<BmSelectorFieldProps> = ({
  className,
  sx,
  cardSx,
  options,
  name,
  value,
  error,
  helperText,
  onChange,
}) => {
  const handleChange = useCallback(
    (nextValue: string) => {
      onChange(nextValue === value ? null : nextValue);
    },
    [onChange, value],
  );

  return (
    <Box id={name} className={className} sx={{width: "100%", ...sx}}>
      {options.length ? (
        <Grid container spacing={3} flexWrap="wrap">
          {options.map((option, index) => (
            <Grid key={index} item xs={12} md={6}>
              <BmSelectorCard {...option} sx={cardSx} selected={option.value === value} onClick={handleChange} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <img src={emptySrc} alt="empty" />
      )}
      {helperText && <BmHelperText error={error}>{helperText}</BmHelperText>}
    </Box>
  );
};

export interface BmControlledSelectorFieldProps
  extends ControlledInputProps,
    Omit<BmSelectorFieldProps, "onChange" | "value" | "error"> {
  onChange?: (value: string | null) => void;
}

export const BmControlledSelectorField: React.FC<BmControlledSelectorFieldProps> = ({
  name,
  defaultValue,
  control,
  rules,
  helperText,
  onChange,
  ...restProps
}) => {
  const {
    field: {ref: _, ...field},
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleOnChange = useCallback(
    (value: string | null) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmSelectorField
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
