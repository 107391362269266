// packages/remirror__preset-wysiwyg/src/wysiwyg-preset.ts
import {BlockquoteExtension} from "@remirror/extension-blockquote";
import {BoldExtension, BoldOptions} from "@remirror/extension-bold";
import {DropCursorExtension, DropCursorOptions} from "@remirror/extension-drop-cursor";
import {GapCursorExtension} from "@remirror/extension-gap-cursor";
import {HardBreakExtension} from "@remirror/extension-hard-break";
import {HeadingExtension, HeadingOptions} from "@remirror/extension-heading";
import {ItalicExtension} from "@remirror/extension-italic";
import {LinkExtension, LinkOptions} from "@remirror/extension-link";
import {BulletListExtension, ListItemExtension} from "@remirror/extension-list";
import {ShortcutsExtension} from "@remirror/extension-shortcuts";
import {TrailingNodeExtension, TrailingNodeOptions} from "@remirror/extension-trailing-node";
import {UnderlineExtension} from "@remirror/extension-underline";
import {FindOptions, FindExtension} from "@remirror/extension-find";
import {PlaceholderExtension, PlaceholderOptions} from "@remirror/react";

interface WysiwygOptions
  extends BoldOptions,
    DropCursorOptions,
    FindOptions,
    HeadingOptions,
    LinkOptions,
    TrailingNodeOptions,
    PlaceholderOptions {}

var DEFAULT_OPTIONS = {
  ...BoldExtension.defaultOptions,
  ...DropCursorExtension.defaultOptions,
  ...FindExtension.defaultOptions,
  ...TrailingNodeExtension.defaultOptions,
  ...HeadingExtension.defaultOptions,
};
function wysiwygPreset(options: WysiwygOptions = {}) {
  options = {...DEFAULT_OPTIONS, ...options};
  const gapCursorExtension = new GapCursorExtension();
  const hardBreakExtension = new HardBreakExtension();
  const boldExtension = new BoldExtension({weight: options.weight});
  const italicExtension = new ItalicExtension();
  const underlineExtension = new UnderlineExtension();
  const blockquoteExtension = new BlockquoteExtension();
  const bulletListExtension = new BulletListExtension();
  const listItemExtension = new ListItemExtension();
  const shortcutsExtension = new ShortcutsExtension();
  const {selectTextOnClick} = options;
  const linkExtension = new LinkExtension({autoLink: true, selectTextOnClick});
  const {color, width} = options;
  const dropCursorExtension = new DropCursorExtension({
    color,
    width,
  });
  const {defaultLevel, levels} = options;
  const headingExtension = new HeadingExtension({defaultLevel, levels});
  const {alwaysFind} = options;
  const findExtension = new FindExtension({
    alwaysFind,
  });
  const {disableTags, ignoredNodes, nodeName} = options;
  const trailingNodeExtension = new TrailingNodeExtension({
    disableTags,
    ignoredNodes,
    nodeName,
  });
  const placeholderExtension = new PlaceholderExtension({placeholder: options.placeholder});

  return [
    // Plain
    dropCursorExtension,
    gapCursorExtension,
    findExtension,
    shortcutsExtension,
    trailingNodeExtension,
    placeholderExtension,
    // Nodes
    hardBreakExtension,
    blockquoteExtension,
    headingExtension,
    bulletListExtension,
    listItemExtension,
    // Marks
    boldExtension,
    italicExtension,
    underlineExtension,
    linkExtension,
  ];
}
export {wysiwygPreset};
