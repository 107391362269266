import {BeverageInput, ProductImageFragment} from "~/api/generated";
import {uniqueIdGenerator} from "~/utils/uniqueIdGenerator";

import {IImageItem} from "./types";

export const convertFromProductImages = (images: ProductImageFragment[]): IImageItem[] =>
  images.map((image) => ({
    id: image.id,
    src: image.srcUrl,
    alt: image.alt || "",
    entity: {
      isNew: false,
      id: image.id,
      srcUrl: image.srcUrl,
    },
  }));

export const convertToProductImages = (imageList: IImageItem[]): BeverageInput["images"] =>
  imageList.map(({entity: {isNew, srcFile, id}}) => (isNew ? {srcFile} : {id}));

export const convertFromImageFileList = (imageFileList: File[]): IImageItem[] =>
  imageFileList.map((file) => ({
    id: uniqueIdGenerator(),
    src: URL.createObjectURL(file),
    alt: file.name,
    entity: {
      isNew: true,
      srcFile: file,
      alt: file.name,
    },
  }));
