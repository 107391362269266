import {css} from "@emotion/css";

import {designConstants, designTokens} from "~/ui";

import {ChipType} from "./IChip";

type Colors = {
  textColor: string;
  bgColor: string;
  borderColor: string;
};

export const commonStyles = {
  chip: (colors: Colors, variant?: ChipType["variant"]) => css`
    &.MuiChip-root {
      height: auto;
      min-height: 24px;
      width: fit-content;

      border-radius: ${designConstants.borderRadius};

      background-color: ${colors.bgColor};

      color: ${colors.textColor};
      font-size: ${designTokens.fontSize.base};
      font-weight: ${designTokens.fontWeight.medium};
      line-height: 20px;

      .MuiChip-deleteIcon {
        color: ${colors.textColor};
        margin-top: 1px;
        margin-left: 4px;
        width: 12px;
        height: 12px;
        &:hover {
          color: ${colors.textColor};
        }
      }

      ${variant === "outlined" &&
      css`
        &.MuiChip-outlined {
          border: 1px solid ${colors.borderColor};
        }
      `}

      .MuiChip-label {
        padding: 1px 6px;
        white-space: break-spaces;
      }
    }
  `,
};
