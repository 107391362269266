import {SxProps, Theme} from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MuiSelect, {SelectChangeEvent} from "@mui/material/Select";
import {useCallback, useMemo} from "react";
import {cx} from "@emotion/css";
import {useController} from "react-hook-form";
import get from "lodash.get";
import Box from "@mui/material/Box";

import {BmHelperText} from "~/components/helperText";
import {noop} from "~/utils/noop";
import {BmChevronIcon} from "~/components/icons/simpleIcons";
import {useOpenClose} from "~/hooks";

import {ControlledInputProps} from "../../_common/types";
import {BmCheckbox} from "../../Checkbox/Checkbox";
import {styles} from "./CheckboxedSelect.styles";
import {commonStyles} from "../_common/styles";

type OnChangeType = (event: SelectChangeEvent<string[]>, child: React.ReactNode) => void;

export interface ICheckboxedSelectOption {
  label: string;
  id: string;
  checked: boolean;
  entity?: any;
}

export interface BmCheckboxedSelectProps {
  className?: string;
  sx?: SxProps<Theme>;
  id?: string;
  helperText?: string;
  noOptionsText?: string;
  error?: boolean;
  label: string;
  name: string;
  value: ICheckboxedSelectOption[];
  onChange: (value: ICheckboxedSelectOption[]) => void;
}

export const BmCheckboxedSelect: React.FC<BmCheckboxedSelectProps> = ({
  className,
  sx,
  id,
  helperText,
  noOptionsText,
  error,
  label,
  name,
  value: options,
  onChange,
}) => {
  const [isFocused, focus, unfocus] = useOpenClose(false);

  const normalizedValue = options.filter(({checked}) => checked).map((option) => JSON.stringify(option));

  const handleChange: OnChangeType = useCallback(
    (event) => {
      const {value} = event.target;
      // this normalization taken from MUI documentation for similar component
      const selectedOptions = typeof value === "string" ? value.split(",") : value;

      const nextOptions = options.map((option) => ({
        ...option,
        checked: selectedOptions.map((selectedOption) => JSON.parse(selectedOption).id).includes(option.id),
      }));

      onChange(nextOptions);
    },
    [onChange, options],
  );

  const isDirty = useMemo(() => options.some(({checked}) => checked), [options]);

  return (
    <FormControl error={error} className={cx(styles.root, className)} sx={{width: "100%", ...sx}}>
      <InputLabel id={name} className={styles.label} shrink={isFocused || isDirty}>
        {label}
      </InputLabel>
      <MuiSelect
        className={cx(commonStyles.commonSelect(!!isDirty))}
        labelId={name}
        id={id}
        multiple
        value={normalizedValue}
        onChange={handleChange}
        onOpen={focus}
        onClose={unfocus}
        IconComponent={BmChevronIcon}
        input={<OutlinedInput label={label} notched={isFocused || isDirty} />}
        renderValue={(selectedOptions) => (
          <Box>{selectedOptions.map((option) => JSON.parse(option).label).join(", ")}</Box>
        )}
      >
        {options.length > 0 ? (
          options.map((option) => (
            <MenuItem key={option.id} value={JSON.stringify(option)}>
              <BmCheckbox checked={option.checked} label="" onChange={noop} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>
            <ListItemText primary={noOptionsText} />
          </MenuItem>
        )}
      </MuiSelect>
      {helperText && <BmHelperText>{helperText}</BmHelperText>}
    </FormControl>
  );
};

export interface BmControlledCheckboxedSelectProps
  extends ControlledInputProps,
    Omit<BmCheckboxedSelectProps, "onChange" | "value"> {
  onChange?: (value: ICheckboxedSelectOption[]) => void;
}

export const BmControlledCheckboxedSelect: React.FC<BmControlledCheckboxedSelectProps> = ({
  name,
  control,
  rules,
  helperText = "",
  onChange,
  ...restProps
}) => {
  const {
    field: {ref: _, ...field},
    fieldState: {error},
  } = useController({name, control, rules});

  const handleOnChange = useCallback(
    (value: ICheckboxedSelectOption[]) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmCheckboxedSelect
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
