import React, {ChangeEventHandler, useCallback, useState} from "react";
import {useController} from "react-hook-form";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import get from "lodash.get";
import {cx} from "@emotion/css";
import {rgba} from "polished";

import {BmVisibilityIcon, BmVisibilityOffIcon} from "~/components/icons/simpleIcons";
import {useToggle} from "~/hooks";
import {noop} from "~/utils/noop";
import {designColors} from "~/ui";

import {CommonInputType, ControlledInputProps} from "../_common/types";
import {commonStyles} from "../_common/styles";

export interface BmPasswordInputProps extends CommonInputType {
  className?: string;

  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const BmPasswordInput = React.forwardRef<HTMLInputElement, BmPasswordInputProps>(function BmPasswordInput(
  {className, disabled, ...restProps},
  ref,
) {
  const [showPassword, toggleShowPassword] = useToggle(false);
  const [isAutoFill, setIsAutoFill] = useState(false);

  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  return (
    <TextField
      {...restProps}
      type={showPassword ? "text" : "password"}
      className={cx(commonStyles.input(), className)}
      variant="outlined"
      fullWidth={true}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={disabled ? noop : toggleShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <BmVisibilityIcon id="visibility-icon" color={rgba(designColors.black, 0.4)} />
              ) : (
                <BmVisibilityOffIcon id="visibility-off-icon" color={rgba(designColors.black, 0.4)} />
              )}
            </IconButton>
          </InputAdornment>
        ),
        onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
          e.animationName === "mui-auto-fill" && setIsAutoFill(true);
        },
        onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) =>
          e.animationName === "mui-auto-fill-cancel" && setIsAutoFill(false),
        onFocus: () => setIsAutoFill(false),
      }}
      InputLabelProps={{shrink: isAutoFill || undefined}}
      inputProps={{ref}}
    />
  );
});

interface BmControlledPasswordInputProps
  extends ControlledInputProps,
    Omit<BmPasswordInputProps, "onChange" | "value" | "error"> {
  onChange?: (value: string) => void;
}

export const BmControlledPasswordInput: React.FC<BmControlledPasswordInputProps> = ({
  name,
  helperText,
  control,
  rules,
  defaultValue = "",
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleChange = useCallback(
    (e) => {
      onChange?.(e);
      field.onChange(e);
    },
    [field, onChange],
  );

  return (
    <BmPasswordInput
      {...field}
      {...restProps}
      onChange={handleChange}
      helperText={get(error, "message", helperText)}
      error={!!error}
    />
  );
};
