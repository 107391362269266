import {cx} from "@emotion/css";
import {ReactComponent as SvgArrowLeftLeft} from "assets/svg/arrow-left.svg";

import {designColors} from "~/ui";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmArrowLeftIcon: React.FC<IIconBase> = ({id, size = "lg", color = designColors.black, className}) => {
  return <SvgArrowLeftLeft id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
