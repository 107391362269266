import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  imgWrapper: (imgMaxWidth: string) => css`
    margin-left: auto;
    max-width: ${imgMaxWidth};
    padding: 15px;

    border: 1px solid ${designColors.grey_2};

    background-color: ${designColors.grey_1};
  `,
};
