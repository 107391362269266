import {Box, Button, ButtonGroup, SxProps} from "@mui/material";
import get from "lodash.get";
import React, {useCallback} from "react";
import {useController} from "react-hook-form";

import {BmHelperText} from "~/components/helperText";
import {noop} from "~/utils/noop";
import {BmTooltip} from "~/components/tooltip";

import {ControlledInputProps} from "../_common/types";
import {ISegmentedControlOption} from "./ISegmentedControls";
import {styles} from "./SegmentedControls.styles";

export interface BmSegmentedControlsProps {
  name: string;
  value?: string;
  options: ISegmentedControlOption[];
  error?: boolean;
  helperText?: React.ReactNode;
  sx?: SxProps;
  onChange: (value: string) => void;
}

export const BmSegmentedControls = React.forwardRef<HTMLInputElement, BmSegmentedControlsProps>(function BmCheckbox(
  {value, options, error, helperText, sx, onChange},
  ref,
) {
  return (
    <Box sx={{width: "100%"}}>
      <ButtonGroup variant="outlined" className={styles.buttonGroup} ref={ref} sx={sx}>
        {options.map((option) => (
          <BmTooltip key={option.value} title={option.tooltipTitle || ""}>
            <Button
              className={styles.option(value === option.value, !!error)}
              disabled={option.disabled}
              onClick={option.disabled ? noop : () => onChange(option.value)}
            >
              {option.label}
            </Button>
          </BmTooltip>
        ))}
      </ButtonGroup>
      {helperText && <BmHelperText error={error}>{helperText}</BmHelperText>}
    </Box>
  );
});

export interface BmControlledSegmentedControlsProps
  extends ControlledInputProps,
    Omit<BmSegmentedControlsProps, "onChange" | "value" | "error"> {
  onChange?: (value: string) => void;
}

export const BmControlledSegmentedControls: React.FC<BmControlledSegmentedControlsProps> = ({
  name,
  defaultValue,
  control,
  rules,
  helperText,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleOnChange = useCallback(
    (value: string) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmSegmentedControls
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
