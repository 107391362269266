import {SxProps, Theme} from "@mui/material/styles";
import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import {useController} from "react-hook-form";
import React, {useCallback} from "react";
import get from "lodash.get";
import {cx} from "@emotion/css";

import {ISelectOption} from "~/components/inputs";
import {BmChevronIcon} from "~/components/icons/simpleIcons";
import {BmHelperText} from "~/components/helperText";

import {ControlledInputProps} from "../../_common/types";
import {styles} from "./DateOptionSelect.styles";
import {commonStyles} from "../_common/styles";

export interface BmDateOptionSelectProps {
  sx?: SxProps<Theme>;
  className?: string;
  helperText?: string;
  error?: boolean;
  isDirty?: boolean;
  label?: string;
  value?: object;
  options: ISelectOption[];
  customDateOption?: React.ReactNode;
  onChange: (value: object) => void;
}

export const BmDateOptionSelect: React.FC<BmDateOptionSelectProps> = ({
  sx,
  className,
  helperText,
  error,
  isDirty,
  label,
  value,
  options,
  customDateOption,
  onChange,
}) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange(JSON.parse(event.target.value));
    },
    [onChange],
  );

  return (
    <FormControl error={error} className={cx(className)} sx={{width: "100%", ...sx}}>
      <MuiSelect
        displayEmpty
        value={value ? JSON.stringify(value) : ""}
        onChange={handleChange}
        variant="outlined"
        fullWidth={true}
        className={cx(commonStyles.commonSelect(!!isDirty), styles.select)}
        IconComponent={BmChevronIcon}
        MenuProps={{disablePortal: true}}
      >
        <MenuItem value="" className={styles.placeholder}>
          {label}
        </MenuItem>
        {options.map((option, index) => {
          if (!!customDateOption && index === options.length - 1) return null;
          return (
            <MenuItem key={option.label} value={JSON.stringify(option.entity)}>
              <Box dangerouslySetInnerHTML={{__html: option.label}} />
            </MenuItem>
          );
        })}
        {customDateOption}
      </MuiSelect>
      {helperText && <BmHelperText>{helperText}</BmHelperText>}
    </FormControl>
  );
};

export interface BmControlledDateOptionSelectProps
  extends ControlledInputProps,
    Omit<BmDateOptionSelectProps, "onChange" | "value"> {
  onChange?: (value: object | "") => void;
}

export const BmControlledDateOptionSelect: React.FC<BmControlledDateOptionSelectProps> = ({
  name,
  control,
  rules,
  helperText = "",
  onChange,
  ...restProps
}) => {
  const {
    field: {ref: _, ...field},
    fieldState: {error},
  } = useController({name, control, rules});

  const handleOnChange = useCallback(
    (value: object | "") => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmDateOptionSelect
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
