import {css} from "@emotion/css";

import {designColors} from "~/ui";

export const styles = {
  image: (objectFit: CSSStyleDeclaration["objectFit"]) => css`
    max-width: 100%;
    max-height: 100%;
    height: auto;
    object-position: center;
    object-fit: ${objectFit};
  `,
  imageWrapper: (withShadow: boolean) => css`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    background-color: ${designColors.white};

    ${withShadow &&
    css`
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    `}

    &:hover {
      .MuiIconButton-root {
        visibility: visible;
      }
    }
  `,
  imageRemove: (isSorting: boolean) => css`
    &.MuiIconButton-root {
      position: absolute;
      visibility: hidden ${isSorting && "!important"};
      top: 10px;
      right: 7px;
      background-color: rgba(255, 255, 255, 0.5);
      font-size: 0;

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  `,
};
