import MuiTooltip from "@mui/material/Tooltip";
import {cx} from "@emotion/css";

import {ITooltip} from "./ITooltip";
import {styles} from "./Tooltip.styles";

export const BmTooltip: React.FC<ITooltip> = ({className, title, open, onClose, placement = "top", children, sx}) => {
  return (
    <MuiTooltip
      arrow
      disableFocusListener
      title={title}
      open={open}
      onClose={onClose}
      placement={placement}
      componentsProps={{tooltip: {sx: sx}}}
      classes={{popper: cx(styles.tooltip, className)}}
    >
      {children}
    </MuiTooltip>
  );
};
