import {rem} from "polished";
import {curry, __} from "ramda";

import {remSize} from "./designConstants";

export const bmPxToRem = curry(rem)(__, remSize);

interface IDesignTokens {
  fontFamily: string;
  fontSize: {
    base: string;
    xxs: string;
    xs: string;
    xsm: string;
    sm: string;
    smd: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
  };
  fontWeight: {
    regular: number;
    medium: number;
    semiBold: number;
    bold: number;
    extraBold: number;
  };
  lineHeight: {
    none: number;
    tight: number;
    normal: number;
    loose: number;
  };
  layer: {
    hidden: number;
    back: number;
    lowest: number;
    lower: number;
    low: number;
    middle: number;
    high: number;
    higher: number;
    highest: number;
    front: number;
  };
}

export const designTokens: IDesignTokens = {
  fontFamily: `'Inter', sans-serif;`,
  fontSize: {
    base: bmPxToRem(remSize),
    xxs: bmPxToRem("10px"),
    xs: bmPxToRem("11px"),
    xsm: bmPxToRem("12px"),
    sm: bmPxToRem("13px"),
    smd: bmPxToRem("14px"),
    md: bmPxToRem("15px"),
    lg: bmPxToRem("20px"),
    xl: bmPxToRem("24px"),
    xxl: bmPxToRem("30px"),
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  lineHeight: {
    none: 1,
    tight: 1.25,
    normal: 1.4,
    loose: 1.8,
  },
  layer: {
    hidden: -1,
    back: 1,
    lowest: 2,
    lower: 3,
    low: 4,
    middle: 5,
    high: 6,
    higher: 7,
    highest: 8,
    front: 9,
  },
};
