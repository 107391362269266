import {ConsentKind, CustomerFilter, CustomerKind} from "~/api/generated";
import {CUSTOMER_COUNTRIES} from "~/constants/countries";
import {getEnumValues} from "~/utils/enums";

import {useBackendFiltering} from "../useBackendFiltering";

const allowedFilters: (keyof CustomerFilter)[] = ["name", "kind", "country", "consents", "tags"];

export const searchParamsToAppliedCustomersFilters = (searchParams: URLSearchParams): CustomerFilter => {
  const nextAppliedCustomersFilters = ([...searchParams] as [keyof Omit<CustomerFilter, "disabled">, any][])
    .filter(([searchParamKey, searchParamValue]) => {
      switch (searchParamKey) {
        case "name":
          return typeof searchParamValue === "string" && searchParamValue.length > 0;
        case "kind":
          return getEnumValues(CustomerKind).includes(searchParamValue as CustomerKind);
        case "country":
          return CUSTOMER_COUNTRIES.map(({COUNTRY_CODE}) => COUNTRY_CODE).includes(searchParamValue);
        case "consents":
          return getEnumValues(ConsentKind).includes(searchParamValue as ConsentKind);
        case "tags":
          return typeof searchParamValue === "string" && searchParamValue.length > 0;
        default:
          return false;
      }
    })
    .reduce(
      (filters, [searchParamKey, searchParamValue]) => {
        return {
          ...filters,
          [searchParamKey]: ["kind", "country", "consents", "tags"].includes(searchParamKey)
            ? [...(filters[searchParamKey] || []), searchParamValue]
            : searchParamValue,
        };
      },
      {} as Omit<CustomerFilter, "disabled">,
    );

  return nextAppliedCustomersFilters;
};

export const useBackendCustomersFiltering = (): [CustomerFilter, (filters: CustomerFilter) => void] => {
  const [appliedFilters, onFiltersChange] = useBackendFiltering<CustomerFilter>(
    allowedFilters,
    searchParamsToAppliedCustomersFilters,
  );

  return [appliedFilters, onFiltersChange];
};
