export const HOME_PATH = "/";
export const TERMS_CONDITIONS_PATH = "/terms-and-conditions";
export const TERMS_TO_UPDATE_PATH = "/terms-to-update";

export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/sign-up";

export const INVITE_PATH = "/invitations/:token";
export const INVITE_SETUP_PATH = "/invitations/setup";

//These full paths are required for the Stripe
export const INVITE_SETUP_SUCCESS_PATH = "https://app.bemakers.com/invitations/setup?success=true";
export const INVITE_SETUP_CANCEL_PATH = "https://app.bemakers.com/invitations/setup?cancel=true";
export const STG_INVITE_SETUP_SUCCESS_PATH = "https://app.staging-bemakers.com/invitations/setup?success=true";
export const STG_INVITE_SETUP_CANCEL_PATH = "https://app.staging-bemakers.com/invitations/setup?cancel=true";

export const PAYMENT_INFO_SUCCESS_PATH = "https://app.bemakers.com/payment-info?success=true";
export const PAYMENT_INFO_CANCEL_PATH = "https://app.bemakers.com/payment-info?cancel=true";
export const STG_PAYMENT_INFO_SUCCESS_PATH = "https://app.staging-bemakers.com/payment-info?success=true";
export const STG_PAYMENT_INFO_CANCEL_PATH = "https://app.staging-bemakers.com/payment-info?cancel=true";

export const RESET_PASSWORD_PATH = "/reset-password";
export const RESET_PASSWORD_SENT_PATH = "/reset-password/sent";
export const RESET_PASSWORD_CONFIRM_PATH = "/reset-password/confirm/:token";
export const RESET_PASSWORD_SUCCESS_PATH = "/reset-password/success";

export const PICKING_LIST_PATH = "/picking-list/:token";

export const CONFIRM_STOCK_MOVEMENT_PATH = "/stock-movement/confirm/:token";

export const READY_FOR_PICKUP_PATH = "/ready-for-pickup/:token";

export const ORDERS_PATH = "/orders";
export const ORDER_TABS_PATH = "/orders/tabs/:orderTab";
export const ORDER_NEW_PATH = "/orders/new";
export const ORDER_PREVIEW_PATH = "/orders/:orderId";
export const ORDER_EDIT_PATH = "/orders/:orderId/edit";
export const ORDER_TRACEZILLA_DOCUMENTS_PATH = "/tracezilla/:tracezillaOrderId/docs";

export const PRODUCTS_PATH = "/products";

export const BEVERAGE_PREVIEW_PATH = "/products/beverages/:productId";
export const BEVERAGE_NEW_PATH = "/products/beverages/new";
export const BEVERAGE_EDIT_PATH = "/products/beverages/:productId/edit";

export const BUNDLE_PREVIEW_PATH = "/products/bundles/:productId";
export const BUNDLE_NEW_PATH = "/products/bundles/new";
export const BUNDLE_EDIT_PATH = "/products/bundles/:productId/edit";

export const EXTRA_NEW_PATH = "/products/extras/new";
export const EXTRA_PREVIEW_PATH = "/products/extras/:productId";
export const EXTRA_EDIT_PATH = "/products/extras/:productId/edit";

export const CUSTOMERS_PATH = "/customers";
export const DISABLED_CUSTOMERS_PATH = "/disabled-customers";
export const CUSTOMER_PREVIEW_PATH = "/customers/:customerId";
export const CUSTOMER_NEW_PATH = "/customers/new";
export const CUSTOMER_EDIT_PATH = "/customers/:customerId/edit";
export const CUSTOMER_BUSINESS_MEMBER_PREVIEW_PATH = "/customers/:customerId/user/:businessMemberId";

export const PRICE_GROUPS_PATH = "/customers/price-groups";
export const PRICE_GROUP_PREVIEW_PATH = "/customers/price-groups/:priceGroupId";
export const PRICE_GROUP_NEW_PATH = "/customers/price-groups/new";
export const PRICE_GROUP_EDIT_PATH = "/customers/price-groups/:priceGroupId/edit";

export const BALANCE_PATH = "/reports/balance";
export const NOTES_FOR_SETTLEMENT_PATH = "/reports/notes-for-settlement";
export const FINANCIAL_STATEMENTS_PATH = "/reports/financial-statements";
export const CREATE_REPORT_PATH = "/reports/create-report";
export const TAX_WATEHOUSE_REPORT_PATH = "/reports/tax-warehouse-report";

// commerce - start
export const WEBSHOP_PRODUCTS_ROOT_PATH = "/store-product";
export const WEBSHOP_PRODUCTS_PATH = "/store-product/:country";
export const WEBSHOP_PRODUCT_NEW_PATH = "/store-product/:country/new";
export const WEBSHOP_PRODUCT_PREVIEW_PATH = "/store-product/:country/:shopProductId";
export const WEBSHOP_PRODUCT_EDIT_PATH = "/store-product/:country/:shopProductId/edit";
export const WEBSHOP_PRODUCT_META_EDIT_PATH = "/store-product/:country/:shopProductId/editMeta";

export const COLLECTION_ROOT_PATH = "/collection";
export const COLLECTIONS_PATH = "/collection/:country";
export const COLLECTION_NEW_PATH = "/collection/:country/new";
export const COLLECTION_PREVIEW_PATH = "/collection/:country/:collectionId";
export const COLLECTION_EDIT_PATH = "/collection/:country/:collectionId/edit";
export const COLLECTION_EDIT_META_PATH = "/collection/:country/:collectionId/editMeta";

export const DISCOUNT_ROOT_PATH = "/discount";
export const DISCOUNTS_PATH = "/discount/:country";
export const DISCOUNT_NEW_PATH = "/discount/:country/new";
export const DISCOUNT_EDIT_PATH = "/discount/:country/:discountId/edit";

export const BANNER_ROOT_PATH = "/banner";
export const BANNERS_PATH = "/banner/:country";
export const PROMO_BANNER_NEW_PATH = "/banner/:country/promo-banner/new";
export const PROMO_BANNER_EDIT_PATH = "/banner/:country/promo-banner/:bannerId/edit";
export const ANNOUNCEMENT_BAR_NEW_PATH = "/banner/:country/announcement-bar/new";
export const ANNOUNCEMENT_BAR_EDIT_PATH = "/banner/:country/announcement-bar/:announcementId/edit";

export const SHIPPING_RATES_ROOT_PATH = "/shipping-rates";
export const SHIPPING_RATES_PREVIEW_PATH = "/shipping-rates/:country";
export const SHIPPING_RATES_EDIT_PATH = "/shipping-rates/:country/edit";

export const COMMERCE_RATES_ROOT_PATH = "/commerce";
// commerce - end

// fulfillment - start
export const STOCK_MOVEMENTS_ROOT_PATH = "/stock-movements";
export const STOCK_MOVEMENTS_NEW_PATH = "/stock-movements/new";
export const STOCK_MOVEMENTS_PREVIEW_PATH = "/stock-movements/:stockMovementId";
export const STOCK_MOVEMENTS_EDIT_PATH = "/stock-movements/:stockMovementId/edit";

export const WAREHOUSES_ROOT_PATH = "/warehouses";

export const FULFILLMENT_RULES_ROOT_PATH = "/fulfillment-rules";
// fulfillment - end

// listings - start
export const LISTINGS_PATH = "/listings";
// listings - end

// settings - start
export const SERVICE_PAGE_PATH = "/service-page";

export const COMPANY_INFO_ROOT_PATH = "/company";
export const COMPANY_INFO_EDIT_PATH = "/company/edit";

export const USERS_PATH = "/users";
export const USERS_INVITATION_NEW_PATH = "/users/invitation/new";
export const USERS_INVITATION_PREVIEW_PATH = "/users/invitation/:invitationId";

export const PAYMENT_INFO_ROOT_PATH = "/payment-info";
export const PAYMENT_INFO_EDIT_PATH = "/payment-info/edit";

export const CONFIGURATION_ROOT_PATH = "/configuration";
export const CONFIGURATION_EDIT_PATH = "/configuration/edit";
export const CONFIGURATION_COUNTRY_PREVIEW_PATH = "/configuration/:country";
export const CONFIGURATION_COUNTRY_EDIT_PATH = "/configuration/:country/edit";

export const LOCATIONS_ROOT_PATH = "/locations";
export const LOCATIONS_NEW_PATH = "/locations/new";
export const LOCATIONS_PREVIEW_PATH = "/locations/:locationId";
export const LOCATIONS_EDIT_PATH = "/locations/:locationId/edit";

export const BRANDS_PATH = "/brands";
export const BRAND_NEW_PATH = "/brands/new";
export const BRAND_EDIT_PATH = "/brands/:brandId/edit";
// settings - end

// business tools - start
export const BUSINESS_TOOLS_PATH = "/business-tools";
export const SHIPPING_PRICE_CALCULATOR_PATH = "/business-tools/shipping-price-calculator";
export const VAT_CALCULATOR_PATH = "/business-tools/vat-calculator";
export const PRICE_CALCULATOR_PREVIEW_PATH = "/business-tools/price-calculator";
export const PRICE_CALCULATOR_NEW_PATH = "/business-tools/price-calculator/new";
export const PRICE_CALCULATOR_EDIT_PATH = "/business-tools/price-calculator/:calculatorId/edit";
// business tools - end

// other
export const NOT_FOUND_PATH = "/not-found";
