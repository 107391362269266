import {equals, omit} from "ramda";
import {useCallback, useEffect, useMemo, useState} from "react";

import {useFixedSearchParams, usePrevious} from "~/hooks";
import {AnyObject} from "~/model/helperTypes/generic";

export const useBackendFiltering = <T extends AnyObject>(
  allowedFilters: string[],
  appliedFiltersProtector: (searchParams: URLSearchParams) => T,
): [T, (filters: T) => void] => {
  const [searchParams, setSearchParams] = useFixedSearchParams();
  const appliedFilters = useMemo(() => appliedFiltersProtector(searchParams), [appliedFiltersProtector, searchParams]);
  const prevAppliedFilters = usePrevious(appliedFilters);
  const [localAppliedFilters, setLocalAppliedFilters] = useState(appliedFilters);

  const handleFiltersChange = useCallback(
    (nextFilters: T) => {
      setSearchParams((prevSearchParams) => {
        const nextSearchParams = {
          ...omit(allowedFilters, Object.fromEntries(prevSearchParams)),
          ...nextFilters,
        };
        return omit(["endCursor", "startCursor"], nextSearchParams);
      });
    },
    [allowedFilters, setSearchParams],
  );

  useEffect(() => {
    if (equals(appliedFilters, prevAppliedFilters)) return;
    setLocalAppliedFilters(appliedFilters);
  }, [appliedFilters, prevAppliedFilters]);

  return [localAppliedFilters, handleFiltersChange];
};
