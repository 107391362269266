import React, {useCallback} from "react";
import {get, useController} from "react-hook-form";
import {cx} from "@emotion/css";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteRenderInputParams,
  TextField,
  MenuItem,
  List as MuiList,
  Stack,
  InputAdornment,
  Box,
} from "@mui/material";
import {pickBy} from "ramda";

import {BmSearchIcon} from "~/components/icons/simpleIcons";

import {ControlledInputProps} from "../_common/types";
import {IAutocompleteOption, BmAutocompleteProps} from "./IAutocomplete";
import {BmAutocompleteButton} from "./private/AutocompleteButton/AutocompleteButton";
import {styles} from "./Autocomplete.styles";
import {commonStyles} from "../_common/styles";

export const BmAutocomplete = React.forwardRef<HTMLDivElement, BmAutocompleteProps>(function BmAutocomplete(
  {
    label,
    helperText,
    forcePopupIcon = true,
    buttonTitle,
    value,
    error,
    isOpen,
    focusOnMount,
    className,
    optionClassName,
    listClassName,
    options,
    freeSolo,
    renderValue,
    startAdornment,
    endAdornment,
    disabled,
    onButtonClick,
    isOptionEqualToValue = (option, value) => value?.id === option.id,
    onSelectOption,
    onInputChange,
    onOpen,
    onClose,
    groupBy,
    ...restProps
  },
  ref,
) {
  return (
    <MuiAutocomplete
      {...restProps}
      className={cx(styles.autocomplete, className)}
      open={isOpen}
      freeSolo={freeSolo}
      openOnFocus
      blurOnSelect
      ref={ref}
      value={value || null}
      options={options}
      onChange={(_, value) => onSelectOption(value)}
      onInputChange={(_, value) => onInputChange?.(value)}
      filterOptions={(x) => x}
      isOptionEqualToValue={isOptionEqualToValue}
      onOpen={onOpen as any}
      onClose={onClose as any}
      groupBy={options.some(({groupBy}) => !!groupBy) ? groupBy : undefined}
      popupIcon={<BmSearchIcon id="autocomplete-search-icon" size="xs" />}
      forcePopupIcon={forcePopupIcon}
      disabled={disabled}
      PaperComponent={({children}) => (
        <MuiList className={cx(styles.list, listClassName)} onMouseDown={(e) => e.preventDefault()}>
          {children}
          {buttonTitle && <BmAutocompleteButton onClick={onButtonClick} title={buttonTitle} />}
        </MuiList>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={label}
          value={renderValue}
          helperText={helperText}
          error={error}
          variant="outlined"
          fullWidth={true}
          className={cx(commonStyles.input())}
          autoFocus={focusOnMount}
          InputProps={{
            ...params.InputProps,
            ...pickBy((val) => val !== undefined, {
              startAdornment: startAdornment ? (
                <InputAdornment position="start">{startAdornment}</InputAdornment>
              ) : undefined,
              endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined,
            }),
          }}
        />
      )}
      renderOption={(props, option, {inputValue}) => {
        const labelParts = parse(
          option.label,
          match(option.label, inputValue, {insideWords: true, findAllOccurrences: true}),
        );
        const detailsParts = option.details
          ? parse(option.details, match(option.details, inputValue, {insideWords: true, findAllOccurrences: true}))
          : [];
        return (
          <MenuItem {...props} className={cx(styles.option, optionClassName)} key={option.id}>
            <Stack direction={option.imagePosition === "left" ? "row-reverse" : "row"} spacing={1} alignItems="center">
              <span className={styles.optionLabel(option.disabled)}>
                {labelParts.map((part, index) => (
                  <span key={index} className={cx(part.highlight && styles.highlight)}>
                    {part.text}
                  </span>
                ))}
              </span>
              <span className={styles.optionImage}>{option.image}</span>
            </Stack>
            <Box>
              {detailsParts.map((part, index) => (
                <span key={index} className={cx(styles.details, part.highlight && styles.highlight)}>
                  {part.text}
                </span>
              ))}
            </Box>
          </MenuItem>
        );
      }}
    />
  );
});

export interface BmControlledAutocompleteProps
  extends ControlledInputProps,
    Omit<BmAutocompleteProps, "onSelectOption" | "value" | "error"> {
  onSelectOption?: (value: IAutocompleteOption | null) => void;
}

export const BmControlledAutocomplete: React.FC<BmControlledAutocompleteProps> = ({
  name,
  control,
  rules,
  onSelectOption,
  helperText,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules});

  const handleSelectOption = useCallback(
    (value) => {
      onSelectOption?.(value);
      field.onChange(value);
    },
    [field, onSelectOption],
  );

  return (
    <BmAutocomplete
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onSelectOption={handleSelectOption}
    />
  );
};
