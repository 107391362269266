import {css} from "@emotion/css";

import {designColors, designConstants, designTokens, designUtils} from "~/ui";

export const styles = {
  imagesSection: css`
    margin-bottom: 1.5rem;
  `,
  dropzone: (isDragHover: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: 235px;
    padding: 1rem;

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CFD1D4' stroke-width='2' stroke-dasharray='4%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    background-color: ${isDragHover ? designColors.blue_1 : designColors.white};
    border-radius: ${designConstants.borderRadius};
  `,
  title: css`
    ${designUtils.baseFontStyles};
    font-weight: ${designTokens.fontWeight.semiBold};
  `,
  helperText: css`
    margin-bottom: 1rem;
  `,
};
