import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Box, SxProps, Theme} from "@mui/material";

import {BmCommonTitleSmall} from "~/components/titles";
import {BmPrimaryButton, BmWhiteButton} from "~/components/buttons";
import {noop} from "~/utils/noop";

export interface BmEmptyLayoutProps {
  sx?: SxProps<Theme>;
  title?: string;
  text?: string;
  emptyPictureSrc?: string;
  primaryButtonName?: string;
  primaryButtonClick?: VoidFunction;
  secondaryButtonName?: string;
  secondaryButtonClick?: VoidFunction;
  underLayoutNode?: React.ReactNode;
}

export const BmEmptyLayout: React.FC<BmEmptyLayoutProps> = ({
  sx,
  title,
  text,
  emptyPictureSrc,
  primaryButtonName,
  primaryButtonClick = noop,
  secondaryButtonName,
  secondaryButtonClick,
  underLayoutNode,
}) => {
  return (
    <Stack sx={{p: {xs: 0, sm: 4, md: 8}, maxWidth: "729px", alignItems: "flex-start", ...sx}} spacing={8} useFlexGap>
      <Stack display="block" spacing={2}>
        {title && <BmCommonTitleSmall>{title}</BmCommonTitleSmall>}
        {text && <Typography variant="body1">{text}</Typography>}
        <Stack spacing={2} direction="row" useFlexGap>
          {primaryButtonName && <BmPrimaryButton onClick={primaryButtonClick}>{primaryButtonName}</BmPrimaryButton>}
          {secondaryButtonName && <BmWhiteButton onClick={secondaryButtonClick}>{secondaryButtonName}</BmWhiteButton>}
        </Stack>
        {underLayoutNode}
      </Stack>
      {emptyPictureSrc && <Box component="img" src={emptyPictureSrc} alt="empty" sx={{maxWidth: "100%"}} />}
    </Stack>
  );
};
