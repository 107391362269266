import {DOMOutputSpec, DOMSerializer, Fragment, Node, Schema} from "@remirror/pm/model";

type NodesType = {[key: string]: (node: Node) => DOMOutputSpec};

interface EnhancedFragment extends Fragment {
  content?: EnhancedNode[];
}

interface EnhancedNode extends Omit<Node, "content"> {
  content: EnhancedFragment;
}

const recomposeNodeChildren = (nodes: NodesType, node: EnhancedNode): DOMOutputSpec => {
  const outputNode = nodes[node.type.name](node) as any;
  if (typeof outputNode === "string") return outputNode;
  const [tag, attrs] = outputNode;
  if (node.type.name === "paragraph") {
    return node.textContent;
  }
  return [
    tag,
    attrs,
    ...(node.content.content || []).reduce((accum, node, index, originalArray) => {
      let nextAccum = [...accum, recomposeNodeChildren(nodes, node)];
      if (originalArray.length - 1 === index) return nextAccum;
      if (node.type.name !== "paragraph") return nextAccum;
      if (originalArray[index + 1].type.name !== "paragraph") return nextAccum;
      nextAccum.push(["br", {}]);
      return nextAccum;
    }, [] as DOMOutputSpec[]),
  ];
};

export const getCustomSerializer = (schema: Schema) => {
  const {nodes, marks} = DOMSerializer.fromSchema(schema);
  return new DOMSerializer(
    {
      ...nodes,
      paragraph(node: Node): DOMOutputSpec {
        const outputSpec = nodes["paragraph"](node) as any;
        if (node.content.size === 0) {
          const [tag, attrs] = outputSpec;
          return [tag, attrs, ["br", {}]];
        }
        return outputSpec;
      },
      listItem(node: Node): DOMOutputSpec {
        return recomposeNodeChildren(nodes, node as EnhancedNode);
      },
    },
    marks,
  );
};
