import {css} from "@emotion/css";
import {rgba} from "polished";

import {designColors, designTokens, designUtils} from "~/ui";

export const styles = {
  root: (selected?: boolean, disabled?: boolean, invalid?: boolean) => css`
    position: relative;
    min-height: 96px;
    height: 100%;
    text-align: left;

    ${selected
      ? css`
          border: 1px solid ${designColors.primary};
          border-radius: 3px;
          box-shadow: inset 0 0 0 2px ${designColors.primary};
          background-color: ${designColors.white};
        `
      : css`
          border: 1px solid ${rgba(designColors.black, 0.23)};
          border-radius: 3px;
          background-color: ${designColors.off_white};
          box-shadow: 0px 2px 0px 0px ${rgba(designColors.black, 0.15)};
        `}
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
    }
    ${disabled &&
    css`
      opacity: 0.4;
    `}
    ${invalid &&
    css`
      border: 1px solid ${designColors.error};
      box-shadow: inset 0 0 0 2px ${designColors.error};
    `}
  `,
  title: css`
    &.MuiTypography-h6 {
      ${designUtils.baseFontStyles};
      color: ${designColors.colorInk};
      font-weight: ${designTokens.fontWeight.bold};
    }
  `,
  subtitle: css`
    &.MuiTypography-subtitle1 {
      ${designUtils.baseFontStyles};
      color: ${designColors.colorInk};
      font-weight: ${designTokens.fontWeight.regular};
    }
  `,
  tooltip: css`
    .MuiTooltip-tooltip {
      top: 50px;
    }
  `,
};
