import {css} from "@emotion/css";
import {rgba} from "polished";

import {designColors, designConstants, designTokens, designUtils} from "~/ui";
import {theme} from "~/ui/theme";

export const commonStyles = {
  input: (isEndAdornment?: boolean) => css`
    .MuiFormLabel-root {
      ${designUtils.baseFontStyles};
      color: ${designColors.colorInk};
      font-style: italic;
      padding-right: 2px;
      transform: translate(14px, 12px) scale(1);

      ${isEndAdornment &&
      css`
        max-width: calc(100% - 24px - 28px);
      `}

      &.MuiFormLabel-filled {
        font-style: normal;
        padding-right: 0;
      }

      &.MuiInputLabel-shrink {
        transform: translate(14px, -9px) scale(0.75);
      }
    }

    .MuiInputBase-root {
      padding: 11px 12px;
      border-radius: ${designConstants.borderRadius};
      border-color: ${designColors.grey_5};
      background-color: ${designColors.white};

      .MuiInputBase-input {
        ${designUtils.baseFontStyles};
        color: ${designColors.colorInk};
        height: 20px;
        padding: 0;

        scroll-margin: 60px;
        scroll-snap-align: start;

        ${theme.breakpoints.down("sm")} {
          scroll-margin: 100px;
        }
      }
    }
    .MuiInputBase-root.Mui-error {
      .MuiInputBase-input {
        color: ${designColors.error};
      }
    }

    label[data-shrink="true"]:not(.Mui-focused, .Mui-error)
      + .MuiInputBase-formControl
      .MuiOutlinedInput-notchedOutline {
      border-color: ${designColors.black};
    }
    .MuiFormHelperText-root {
      color: ${designColors.grey_5};
      font-size: ${designTokens.fontSize.xsm};
      font-weight: ${designTokens.fontWeight.regular};
      line-height: 1.25rem;
      margin-left: 0;
      margin-right: 0;
    }
    .MuiInputAdornment-root {
      color: ${rgba(designColors.black, 0.4)};
    }

    &.MuiFormControl-root .Mui-disabled {
      &,
      & .MuiInputAdornment-root * {
        color: ${designColors.black};
      }

      &:not(.MuiInputBase-input) {
        opacity: 0.4;
      }
      &.MuiInputBase-input {
        -webkit-text-fill-color: ${designColors.black};
      }
    }
  `,
  hidden: css`
    &.MuiFormControl-root {
      display: none;
    }
  `,
};
