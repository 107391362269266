import {css} from "@emotion/css";

import {designColors, designConstants, designTokens} from "~/ui";

export const styles = {
  tooltip: css`
    &.MuiTooltip-popper[data-popper-placement*="top"] {
      .MuiTooltip-tooltip {
        margin-bottom: 6px;
      }
    }
    .MuiTooltip-tooltip {
      font-size: ${designTokens.fontSize.sm};
      line-height: 1.5;
      background-color: ${designColors.black};
      text-align: center;
      max-width: 250px;
      padding: 6px 12px;
      border-radius: ${designConstants.borderRadius};

      &[data-popper-placement*="top"] {
        margin-bottom: 6px;
      }
    }

    .MuiTooltip-arrow {
      color: ${designColors.black};
    }
  `,
};
