import {omit} from "ramda";
import {isValid} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";

import {DeliveryMethod, OrderFilter, OrderKind, OrderPaymentStatus, OrderSource, OrderStatus} from "~/api/generated";
import {CUSTOMER_COUNTRIES} from "~/constants/countries";
import {getEnumValues} from "~/utils/enums";

import {useBackendFiltering} from "../useBackendFiltering";

const allowedFilters: (keyof OrderFilter)[] = [
  "fromDate",
  "toDate",
  "kind",
  "country",
  "source",
  "paymentStatus",
  "status",
  "deliveryMethod",
  "tags",
  "search",
];

export const searchParamsToAppliedOrdersFilters = (searchParams: URLSearchParams): OrderFilter => {
  const nextAppliedOrdersFilters = ([...searchParams] as [keyof OrderFilter, any][])
    .filter(([searchParamKey, searchParamValue]) => {
      switch (searchParamKey) {
        case "fromDate":
        case "toDate":
          return isValid(utcToZonedTime(searchParamValue, "GMT"));
        case "kind":
          return getEnumValues(OrderKind).includes(searchParamValue);
        case "country":
          return CUSTOMER_COUNTRIES.map(({COUNTRY_CODE}) => COUNTRY_CODE).includes(searchParamValue);
        case "source":
          return getEnumValues(OrderSource).includes(searchParamValue);
        case "paymentStatus":
          return getEnumValues(OrderPaymentStatus).includes(searchParamValue);
        case "status":
          return getEnumValues(OrderStatus).includes(searchParamValue);
        case "deliveryMethod":
          return getEnumValues(DeliveryMethod).includes(searchParamValue);
        case "tags":
          return typeof searchParamValue === "string" && searchParamValue.length > 0;
        case "search":
          return typeof searchParamValue === "string" && searchParamValue.length > 0;
        default:
          return false;
      }
    })
    .reduce((filters, [searchParamKey, searchParamValue]) => {
      return {
        ...filters,
        [searchParamKey]: ["kind", "country", "source", "paymentStatus", "status", "tags", "deliveryMethod"].includes(
          searchParamKey,
        )
          ? [...(filters[searchParamKey] || []), searchParamValue]
          : searchParamValue,
      };
    }, {} as OrderFilter);

  const {fromDate, toDate} = nextAppliedOrdersFilters;
  return [fromDate, toDate].every(Boolean)
    ? nextAppliedOrdersFilters
    : omit(["fromDate", "toDate"], nextAppliedOrdersFilters);
};

export const useBackendOrdersFiltering = (): [OrderFilter, (filters: OrderFilter) => void] => {
  const [appliedFilters, onFiltersChange] = useBackendFiltering<OrderFilter>(
    allowedFilters,
    searchParamsToAppliedOrdersFilters,
  );

  return [appliedFilters, onFiltersChange];
};
