import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {snapCenterToCursor} from "@dnd-kit/modifiers";
import {sortableKeyboardCoordinates, arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";

import {IImageItem} from "../types";
import {ImageItem} from "../ImageItem/ImageItem";
import {styles} from "./ImageList.styles";

export interface ImageListProps {
  imageList: IImageItem[];
  removeImage: (imageIndex: number) => void;
  reorderImages: (imageList: IImageItem[]) => void;
}

export const ImageList: React.FC<ImageListProps> = ({imageList, removeImage, reorderImages}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    if (!over) return;
    if (active.id === over.id) return;

    const itemsIds = imageList.map(({id}) => id);
    const oldIndex = itemsIds.indexOf(active.id as string);
    const newIndex = itemsIds.indexOf(over.id as string);

    reorderImages(arrayMove(imageList, oldIndex, newIndex));
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragEnd={handleDragEnd}
      modifiers={[snapCenterToCursor]}
    >
      <SortableContext items={imageList} strategy={verticalListSortingStrategy}>
        <div className={styles.imageListContainer}>
          {imageList.map((image, index) => (
            <ImageItem key={image.id} image={image} removeImage={() => removeImage(index)} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};
