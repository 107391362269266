import {SxProps, Theme, ToggleButton, ToggleButtonGroup as MuiToggleButtonGroup} from "@mui/material";
import {useCallback} from "react";
import {useController} from "react-hook-form";

import {ControlledInputProps} from "../_common/types";
import {styles} from "./ToggleButtonGroup.styles";

export interface IToggleButtonOption {
  label: string;
  value: string;
}

export interface BmToggleButtonGroupProps {
  name: string;
  value?: string | null;
  options: IToggleButtonOption[];
  error?: boolean;
  ariaLabel?: string;
  sx?: SxProps<Theme>;
  onSelectOption: (value: string | null) => void;
}

export const BmToggleButtonGroup: React.FC<BmToggleButtonGroupProps> = ({
  options,
  ariaLabel,
  value,
  onSelectOption,
}) => {
  return (
    <MuiToggleButtonGroup
      value={value}
      aria-label={ariaLabel}
      exclusive
      onChange={(_, value) => onSelectOption(value)}
      className={styles.group}
    >
      {options.map((option) => (
        <ToggleButton key={option.label} value={option.value} className={styles.button}>
          {option.label}
        </ToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
};

export interface BmControlledToggleButtonGroupProps
  extends ControlledInputProps,
    Omit<BmToggleButtonGroupProps, "onSelectOption" | "value" | "error"> {
  onSelectOption?: (value: string | null) => void;
}

export const BmControlledToggleButtonGroup: React.FC<BmControlledToggleButtonGroupProps> = ({
  name,
  defaultValue,
  control,
  rules,
  onSelectOption,
  ...restProps
}) => {
  const {
    field: {ref: _, ...field},
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleSelectOption = useCallback(
    (value) => {
      onSelectOption?.(value);
      field.onChange(value);
    },
    [field, onSelectOption],
  );

  return <BmToggleButtonGroup {...field} {...restProps} error={!!error} onSelectOption={handleSelectOption} />;
};
