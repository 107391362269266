import {useController} from "react-hook-form";
import {useDropzone} from "react-dropzone";
import Box from "@mui/material/Box";

import {BmWhiteButton} from "~/components/buttons";
import {useDidUpdate} from "~/hooks";
import {BmHelperText} from "~/components/helperText";
import {designTokens} from "~/ui";

import {ControlledInputProps} from "../../_common/types";
import {styles} from "./UploadMultipleImages.styles";
import {IImageItem} from "./private/types";
import {useUploadMultipleImages} from "./private/useUploadMultipleImages";
import {ImageList} from "./private/ImageList/ImageList";

export interface BmUploadMultipleImagesProps {
  imageListTitle?: string;
  imageListDescription: string;
  uploadButtonText: string;
  uploadDescription?: string;
  initImageList?: IImageItem[];
  onChange: (imageList: IImageItem[]) => void;
}

export const BmUploadMultipleImages: React.FC<BmUploadMultipleImagesProps> = ({
  imageListTitle,
  imageListDescription,
  uploadButtonText,
  uploadDescription,
  initImageList = [],
  onChange,
}) => {
  const [imageList, {addAcceptedImages, removeImage, reorderImages}] = useUploadMultipleImages(initImageList);

  const {isDragActive, getRootProps, getInputProps} = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      if (!acceptedFiles.length) return;
      addAcceptedImages(acceptedFiles as File[]);
    },
  });

  useDidUpdate(() => {
    onChange(imageList);
  }, [onChange, imageList]);

  return (
    <Box>
      <aside className={styles.imagesSection}>
        {imageListTitle && <h4 className={styles.title}>{imageListTitle}</h4>}
        <BmHelperText sx={{fontSize: designTokens.fontSize.md, mb: 2.5}}>{imageListDescription}</BmHelperText>
        {!!imageList.length && (
          <ImageList imageList={imageList} removeImage={removeImage} reorderImages={reorderImages} />
        )}
      </aside>
      <Box {...getRootProps({className: styles.dropzone(isDragActive)})}>
        <input {...getInputProps()} />
        <BmWhiteButton>{uploadButtonText}</BmWhiteButton>
        <BmHelperText sx={{mb: 2.5}}>{uploadDescription}</BmHelperText>
      </Box>
    </Box>
  );
};

export interface BmControlledUploadMultipleImagesProps
  extends ControlledInputProps,
    Omit<BmUploadMultipleImagesProps, "onChange"> {}

export const BmControlledUploadMultipleImages: React.FC<BmControlledUploadMultipleImagesProps> = ({
  name,
  control,
  rules,
  ...restProps
}) => {
  const {
    field: {ref: _, value, ...field},
  } = useController({name, control, rules});

  return <BmUploadMultipleImages {...field} {...restProps} initImageList={value} />;
};
