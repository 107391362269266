import {cx} from "@emotion/css";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import get from "lodash.get";
import React, {ChangeEventHandler, useCallback, useMemo, useRef, useState} from "react";
import {useController} from "react-hook-form";
import {isNil} from "ramda";

import {useCombinedRefs, useDidUpdate} from "~/hooks";
import {BmTooltip} from "~/components/tooltip";

import {ControlledInputProps} from "../../../_common/types";
import {BmTextInput, BmTextInputProps} from "../../TextInput";
import {styles} from "./ResetableTextInput.styles";

export interface BmResetableTextInputProps extends Omit<BmTextInputProps, "endAdornment"> {
  // if value is set on BE
  systemValue?: string | null;
  resetButtonTooltip?: string;
}

export const BmResetableTextInput = React.forwardRef<HTMLInputElement, BmResetableTextInputProps>(
  function BmResetableTextInput({className, value, systemValue, resetButtonTooltip = "", onChange, ...props}, ref) {
    const innerRef = useRef(null);
    const combinedRef = useCombinedRefs<HTMLInputElement>(innerRef, ref);
    const [localValue, setLocalValue] = useState(value || systemValue);

    const isValueEqualSystemValue = useMemo(() => {
      if (!localValue) return false;
      return localValue === systemValue;
    }, [localValue, systemValue]);

    const handleResetClick = useCallback(() => {
      if (!combinedRef?.current) return;

      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")!.set!;
      nativeInputValueSetter.call(combinedRef.current, systemValue);

      const onChangeEvent = new Event("input", {bubbles: true});
      combinedRef.current.dispatchEvent(onChangeEvent);
    }, [combinedRef, systemValue]);

    useDidUpdate(() => {
      setLocalValue(value!);
    }, [value, setLocalValue]);

    return (
      <BmTextInput
        {...props}
        ref={combinedRef}
        className={cx(isValueEqualSystemValue && styles.greyText, className)}
        value={value || localValue || ""}
        onChange={onChange}
        endAdornment={
          !isNil(systemValue) &&
          !isValueEqualSystemValue && (
            <BmTooltip title={resetButtonTooltip}>
              <IconButton aria-label="reset value to init" edge="end" onClick={handleResetClick}>
                <CachedIcon id="reset-icon" />
              </IconButton>
            </BmTooltip>
          )
        }
      />
    );
  },
);

export interface BmControlledResetableTextInputProps
  extends ControlledInputProps,
    Omit<BmResetableTextInputProps, "onChange" | "value" | "error"> {
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const BmControlledResetableTextInput: React.FC<BmControlledResetableTextInputProps> = ({
  name,
  control,
  rules,
  defaultValue,
  helperText = "",
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      onChange?.(e);
      field.onChange(e);
    },
    [field, onChange],
  );

  return (
    <BmResetableTextInput
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
