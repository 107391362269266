// all svg icons of flags should be taken from http://flagicons.lipis.dev/

import {cx} from "@emotion/css";

import {ReactComponent as SvgINTLFlag} from "~/assets/svg/flags/intl.svg";
import {designColors} from "~/ui";

import {flagIconStyles} from "../styles";
import {IIconBase} from "../../_common/types";
import {commonStyles} from "../../_common/styles";

export const INTLFlag: React.FC<IIconBase> = ({id, size = "lg", color = designColors.black, className}) => {
  return <SvgINTLFlag id={id} className={cx(flagIconStyles(size), commonStyles.color(color), className)} />;
};
