import React, {useCallback} from "react";

import {BmTextInput} from "../../../../TextInput";
import {formatPhoneNumber} from "./helpers";

interface PhoneInputProps {
  className?: string;
  name: string;
  value?: string; // phone number without countryCallingCode
  label: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  countryCallingCode: string; // e.g. 46 for sweden
  onChange: (value: string) => void;
}

export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(function PhoneInput(
  {className, name, value, label, error, helperText, disabled, countryCallingCode, onChange},
  ref,
) {
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(formatPhoneNumber(event.target.value, countryCallingCode));
    },
    [countryCallingCode, onChange],
  );

  return (
    <BmTextInput
      ref={ref}
      className={className}
      name={name}
      value={value ? formatPhoneNumber(value, countryCallingCode) : undefined}
      label={label}
      error={error}
      helperText={helperText}
      disabled={disabled}
      onChange={handleInputChange}
    />
  );
});
