import {css} from "@emotion/css";

import {designTokens} from "./designTokens";

export const designUtils = {
  transition: (speed: number = 0.3) => `all ${speed}s ease`,
  baseFontStyles: css`
    font-size: ${designTokens.fontSize.base};
    line-height: ${designTokens.lineHeight.normal};
  `,
  textOverflow: css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
};

export const getScrollbarWidth = () => {
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  outer.style.width = "100px";
  document.body.appendChild(outer);

  const inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  document.body.removeChild(outer);

  return scrollbarWidth;
};
