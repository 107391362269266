import {TFunction} from "react-i18next";

import {MakerReportableSummaryFragment} from "~/api/generated";
import {IToggleButtonOption} from "~/components/inputs";

export enum SalesReportsEnum {
  Older = "OLDER",
}

export enum SalesReportViewEnum {
  Simple = "Simple",
  Detailed = "Detailed",
}

export const getPeriodOptions = (
  t: TFunction<"translations">,
  makerReporttables: MakerReportableSummaryFragment[],
  newPeriod: string | null,
): IToggleButtonOption[] => {
  const options = makerReporttables.slice(0, 3).map(({period}) => ({
    label: period,
    value: period,
  }));

  const isOther = makerReporttables.length > 3;
  const other = {label: t("labels.older"), value: SalesReportsEnum.Older};

  return newPeriod
    ? [...options, {label: newPeriod, value: newPeriod}, ...(isOther ? [other] : [])]
    : [...options, ...(isOther ? [other] : [])];
};

export const getSalesReportViewOptions = (t: TFunction<"translations">) => [
  {
    label: t("labels.simple"),
    value: SalesReportViewEnum.Simple,
  },
  {
    label: t("labels.detailed"),
    value: SalesReportViewEnum.Detailed,
  },
];
