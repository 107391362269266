import {useTranslation} from "react-i18next";
import {useCallback} from "react";
import {Stack} from "@mui/material";

import {BmInfoChip} from "~/components/chips";
import {BmPrimaryLink} from "~/components/links";

import {FilteringType} from "../types";

interface BmAppliedFiltersProps extends FilteringType {
  className?: string;
  isClearAllFiltersVisible?: boolean;
}

export const BmAppliedFilters: React.FC<BmAppliedFiltersProps> = ({
  className,
  isClearAllFiltersVisible = true,
  chipsData,
  onDeleteFilters,
}) => {
  const {t} = useTranslation();

  const handleDeleteFilter = useCallback(
    (type: string) => {
      onDeleteFilters([type]);
    },
    [onDeleteFilters],
  );

  const handleDeleteAllFilters = useCallback(() => {
    onDeleteFilters(chipsData.map(({type}) => type));
  }, [chipsData, onDeleteFilters]);

  return (
    <Stack direction="row" flexWrap="wrap" gap={1.5} className={className}>
      {chipsData.map(({label, type}) => (
        <BmInfoChip key={type} label={label} onDelete={handleDeleteFilter.bind(null, type)} />
      ))}
      {isClearAllFiltersVisible && (
        <BmPrimaryLink
          underline="none"
          weight="semiBold"
          onClick={handleDeleteAllFilters}
          sx={{lineHeight: "24px !important"}}
        >
          {t("buttons.clear_filters")}
        </BmPrimaryLink>
      )}
    </Stack>
  );
};
