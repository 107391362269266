import {css} from "@emotion/css";

export const styles = {
  title: css`
    &.MuiTypography-root {
      margin-bottom: 5px;
    }
  `,
  labelWrapper: css`
    margin-bottom: 2rem;
  `,
  image: css`
    width: 100%;
    aspect-ratio: 1.91 / 1;
    object-fit: cover;
  `,
};
