import {isValid} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import {omit} from "ramda";

import {SettlementFilter} from "~/api/generated";

import {useBackendFiltering} from "../useBackendFiltering";

const allowedFilters: (keyof SettlementFilter)[] = ["fromDate", "toDate"];

export const searchParamsToAppliedSettlementsFilters = (searchParams: URLSearchParams): SettlementFilter => {
  const nextAppliedSettlementsFilters = ([...searchParams] as [keyof SettlementFilter, any][])
    .filter(([searchParamKey, searchParamValue]) => {
      switch (searchParamKey) {
        case "fromDate":
        case "toDate":
          return isValid(utcToZonedTime(searchParamValue, "GMT"));
        default:
          return false;
      }
    })
    .reduce((filters, [searchParamKey, searchParamValue]) => {
      return {
        ...filters,
        [searchParamKey]: searchParamValue,
      };
    }, {} as SettlementFilter);

  const {fromDate, toDate} = nextAppliedSettlementsFilters;
  return [fromDate, toDate].every(Boolean)
    ? nextAppliedSettlementsFilters
    : omit(["fromDate", "toDate"], nextAppliedSettlementsFilters);
};

export const useBackendSettlementsFiltering = (): [SettlementFilter, (filters: SettlementFilter) => void] => {
  const [appliedFilters, onFiltersChange] = useBackendFiltering<SettlementFilter>(
    allowedFilters,
    searchParamsToAppliedSettlementsFilters,
  );

  return [appliedFilters, onFiltersChange];
};
