import {css} from "@emotion/css";
import {rgba} from "polished";

import {designColors} from "~/ui";

export const styles = {
  root: css`
    .MuiOutlinedInput-root {
      align-items: flex-start;
    }
    .MuiInputAdornment-root {
      margin-top: 10px;
    }
  `,
  greyText: css`
    .MuiOutlinedInput-root {
      color: ${rgba(designColors.black, 0.4)};
    }
  `,
};
