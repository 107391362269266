import React, {useCallback} from "react";
import {TextField, TextFieldProps} from "@mui/material";
import {SxProps, Theme} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import get from "lodash.get";
import {useController} from "react-hook-form";
import {cx} from "@emotion/css";

import {CommonInputType, ControlledInputProps} from "../../_common/types";
import {commonStyles} from "../../_common/styles";
import {styles} from "./DesktopDatePicker.styles";

export interface BmDesktopDatePickerProps extends CommonInputType {
  value: Date | null;
  inputFormat?: string;
  minDate?: any;
  maxDate?: any;
  inputClassName?: string;
  inputSx?: SxProps<Theme>;

  onChange: (value: Date | null, keyboardInputValue?: string | undefined) => void;
}

export const BmDesktopDatePicker = React.forwardRef<HTMLInputElement, BmDesktopDatePickerProps>(
  function BmDesktopDatePicker(
    {value, inputFormat = "dd.MM.yyyy", helperText, name, error, inputClassName, inputSx, onChange, ...props},
    ref,
  ) {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          {...props}
          inputFormat={inputFormat}
          value={value || null}
          onChange={onChange}
          renderInput={(params: TextFieldProps) => (
            <TextField
              {...params}
              ref={ref}
              name={name}
              className={cx(commonStyles.input(), styles.textField, inputClassName)}
              sx={inputSx}
              helperText={helperText}
              error={error}
              fullWidth={true}
            />
          )}
        />
      </LocalizationProvider>
    );
  },
);

export interface BmControlledDesktopDatePickerProps
  extends ControlledInputProps,
    Omit<BmDesktopDatePickerProps, "onChange" | "value" | "error"> {
  onChange?: (value: Date | null, keyboardInputValue?: string | undefined) => void;
}

export const BmControlledDesktopDatePicker: React.FC<BmControlledDesktopDatePickerProps> = ({
  name,
  control,
  rules,
  helperText,
  defaultValue,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleChange = useCallback(
    (value) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmDesktopDatePicker
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleChange}
    />
  );
};
