import React from "react";
import {Box, ClickAwayListener} from "@mui/material";

import {ITooltip} from "../../ITooltip";
import {BmTooltip} from "../../Tooltip";

export interface BmControlledTooltipProps extends Omit<ITooltip, "onClose"> {
  onClose: VoidFunction;
}

export const BmControlledTooltip: React.FC<BmControlledTooltipProps> = ({
  title,
  placement,
  open,
  onOpen,
  onClose,
  children,
}) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box onMouseEnter={onOpen} onMouseLeave={onClose}>
        <BmTooltip title={title} open={open} placement={placement}>
          <Box>{children}</Box>
        </BmTooltip>
      </Box>
    </ClickAwayListener>
  );
};
