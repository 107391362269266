import {curryN, __} from "ramda";
import MuiCheckbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiFormGroup from "@mui/material/FormGroup";
import {useController} from "react-hook-form";
import React, {useCallback} from "react";
import get from "lodash.get";
import {produce} from "immer";
import {cx} from "@emotion/css";
import Stack from "@mui/material/Stack";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

import {BmHelperText} from "~/components/helperText";
import {BmCheckboxCheckedIcon, BmCheckboxEmptyIcon} from "~/components/icons/simpleIcons";

import {CommonInputType, ControlledInputProps} from "../_common/types";
import {styles} from "./CheckboxGroup.styles";
import {ICheckboxGroupOption, ICheckboxGroupOptionsMetaData} from "./ICheckboxGroup";

type OnChangeType = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
type CurriedOnChangeType = (event: any, checked: any, index: number) => OnChangeType;

export interface BmCheckboxGroupCollapseProps extends CommonInputType {
  value: ICheckboxGroupOption[];
  optionsMetaData?: ICheckboxGroupOptionsMetaData;
  error?: boolean;
  helperText?: string;
  onChange: (value: ICheckboxGroupOption[]) => void;
}

export const BmCheckboxGroupCollapse = React.forwardRef<HTMLFieldSetElement, BmCheckboxGroupCollapseProps>(
  function BmCheckboxGroupCollapse(
    {className, name, value: options, optionsMetaData, error, helperText, onChange},
    ref,
  ) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChange = useCallback(
      curryN(3, (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, index: number) => {
        onChange(
          produce(options, (draftOptions) => {
            draftOptions[index]["checked"] = checked;
          }),
        );
      }) as any as CurriedOnChangeType,
      [onChange, options],
    );

    return (
      <FormControl
        error={error}
        component="fieldset"
        variant="standard"
        className={cx(styles.input, className)}
        ref={ref}
        name={name}
      >
        <MuiFormGroup>
          <Stack mt={1} spacing={1}>
            {options.map(({name, label, checked, helperText, disabled}, index) => {
              const isExpanded = checked && !!optionsMetaData?.[name]?.underLabel;
              return (
                <Accordion key={name} expanded={isExpanded} className={styles.checkboxContent(!!error)}>
                  <AccordionSummary aria-controls={`${name}-content`} id={`${name}-header`}>
                    <FormControlLabel
                      className={styles.label}
                      control={
                        <MuiCheckbox
                          name={name}
                          checked={checked}
                          icon={<BmCheckboxEmptyIcon id="checkbox-empty" />}
                          checkedIcon={<BmCheckboxCheckedIcon id="checkbox-checked" />}
                          onChange={handleChange(__, __, index)}
                          className={cx(styles.checkbox, error && styles.error)}
                          disabled={disabled}
                        />
                      }
                      label={label}
                      onClick={(e) => e.stopPropagation()}
                      onFocus={(e) => e.stopPropagation()}
                    />
                  </AccordionSummary>
                  {isExpanded && <AccordionDetails>{optionsMetaData[name].underLabel}</AccordionDetails>}
                  {helperText && <BmHelperText className={styles.helperText}>{helperText}</BmHelperText>}
                </Accordion>
              );
            })}
          </Stack>
        </MuiFormGroup>
        {helperText && <BmHelperText>{helperText}</BmHelperText>}
      </FormControl>
    );
  },
);

export interface BmControlledCheckboxGroupCollapseProps
  extends ControlledInputProps,
    Omit<BmCheckboxGroupCollapseProps, "onChange" | "value" | "error"> {
  onChange?: (value: Object) => void;
}

export const BmControlledCheckboxGroupCollapse: React.FC<BmControlledCheckboxGroupCollapseProps> = ({
  name,
  control,
  rules,
  helperText,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules});

  const handleOnChange = useCallback(
    (value: Object) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmCheckboxGroupCollapse
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
