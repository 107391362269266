import {css} from "@emotion/css";

export const styles = {
  imageColumn: (isDragging: boolean) => css`
    height: 120px;
    cursor: grab;
    transform-origin: 0 0;

    ${isDragging &&
    css`
      transform-origin: 50%;
      z-index: 1;
    `}

    &:first-child {
      height: 252px;
      grid-row-start: span 2;
      grid-column-start: span 2;
    }
  `,
};
