import {RangeKeyDict} from "react-date-range";

export type BmDateRangeValue = {
  fromDate: Date | undefined;
  toDate: Date | undefined;
};

export const convertBmDateRangeValueToRangeKeyDict = ({fromDate, toDate}: BmDateRangeValue): RangeKeyDict => {
  return {selection: {startDate: fromDate, endDate: toDate, key: "selection"}};
};

export const convertRangeKeyDictToBmDateRangeValue = ({
  selection: {startDate, endDate},
}: RangeKeyDict): BmDateRangeValue => {
  return {fromDate: startDate, toDate: endDate};
};
