import {useMemo} from "react";

import {useIsMobile} from "~/hooks";
import {BmContextMenuWithArrowButton, BmContextMenuWithIconButton, IContextMenu} from "~/components/contextMenu";

export const BmTableOptions: React.FC<IContextMenu> = ({className, children, ...restProps}) => {
  const [, isMobile] = useIsMobile();
  const ContextMenuComponent = useMemo(
    () => (isMobile ? BmContextMenuWithArrowButton : BmContextMenuWithIconButton),
    [isMobile],
  );

  return (
    <ContextMenuComponent {...restProps} className={className}>
      {children}
    </ContextMenuComponent>
  );
};
