import {css} from "@emotion/css";

import {designColors, designTokens, designUtils} from "~/ui";

export const staticSelectStyles = {
  select: css`
    &.MuiOutlinedInput-root {
      background-color: ${designColors.white};
      font-weight: ${designTokens.fontWeight.semiBold};

      &.Mui-focused {
        background-color: ${designColors.grey_1};
        .MuiOutlinedInput-notchedOutline {
          border-color: ${designColors.grey_3};
          border-width: 1px;
        }
      }
    }

    &.MuiInputBase-root .MuiInputBase-input {
      min-height: 20px;
      padding: 11px 12px;
      line-height: 20px;
    }

    .MuiSelect-icon {
      top: calc(50% - 12px);
    }
    .MuiList-root {
      li {
        &:not(.Mui-disabled) {
          ${designUtils.baseFontStyles};
          padding: 12px 24px;
        }
        &:not(:last-child, .Mui-disabled) {
          border-bottom: 1px solid ${designColors.grey_2};
        }
      }
    }
  `,
  placeholder: css`
    &.Mui-disabled {
      height: 0;
      min-height: 0;
      padding: 0;
      overflow: hidden;
      opacity: 0;
    }
  `,
};
