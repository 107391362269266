import {css} from "@emotion/css";
import {rgba} from "polished";

import {designColors} from "~/ui";

export const styles = {
  greyText: css`
    .MuiOutlinedInput-root {
      color: ${rgba(designColors.black, 0.4)};
    }
  `,
};
