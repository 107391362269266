import get from "lodash.get";
import React, {useCallback} from "react";
import {useController} from "react-hook-form";

import {ControlledInputProps} from "../../_common/types";
import {BmAutocomplete} from "../Autocomplete";
import {BmAutocompleteProps, IAutocompleteOption} from "../IAutocomplete";

export interface BmAutocompleteWithStringifiedValueProps extends Omit<BmAutocompleteProps, "onSelectOption"> {
  value: string | null | undefined;
  onSelectOption: (value: string | null) => void;
}

export const BmAutocompleteWithStringifiedValue = React.forwardRef<
  HTMLDivElement,
  BmAutocompleteWithStringifiedValueProps
>(function BmAutocompleteWithStringifiedValue({value, onSelectOption, ...props}, ref) {
  const handleSelectOption = useCallback(
    (value: string | IAutocompleteOption | null) => {
      const nextValue = typeof value === "string" ? value : value?.id;
      onSelectOption(nextValue ?? null);
    },
    [onSelectOption],
  );
  return (
    <BmAutocomplete
      {...props}
      ref={ref}
      value={props.options.find(({id}) => id === value)?.label}
      onSelectOption={handleSelectOption}
      freeSolo
    />
  );
});

export interface BmControlledAutocompleteWithStringifiedValueProps
  extends ControlledInputProps,
    Omit<BmAutocompleteProps, "onSelectOption" | "value" | "error"> {
  onSelectOption?: (value: string | null) => void;
}

export const BmControlledAutocompleteWithStringifiedValue: React.FC<
  BmControlledAutocompleteWithStringifiedValueProps
> = ({name, control, rules, onSelectOption, helperText, ...restProps}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules});

  const handleSelectOption = useCallback(
    (value) => {
      onSelectOption?.(value);
      field.onChange(value);
    },
    [field, onSelectOption],
  );

  return (
    <BmAutocompleteWithStringifiedValue
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onSelectOption={handleSelectOption}
    />
  );
};
