import {useController} from "react-hook-form";
import React, {HTMLAttributes, useCallback} from "react";
import NumberFormat, {FormatInputValueFunction} from "react-number-format";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import get from "lodash.get";
import {cx} from "@emotion/css";

import {CommonInputType, ControlledInputProps} from "../_common/types";
import {commonStyles} from "../_common/styles";
import {BmInputHelperText} from "../_common/InputHelperText";

export interface BmNumberInputProps extends Omit<CommonInputType, "type"> {
  value: number | null | "";
  allowNegative?: boolean;
  decimalSeparator?: string;
  decimalScale?: number;
  alwaysShowDecimals?: boolean;
  inputMode?: HTMLAttributes<any>["inputMode"];
  focusOnMount?: boolean;
  error?: boolean;
  format?: string | FormatInputValueFunction | undefined;
  onChange: (value: number | "") => void;
}

export const BmNumberInput = React.forwardRef<HTMLInputElement, BmNumberInputProps>(function BmNumberInput(
  {
    className,
    value,
    helperText,
    decimalSeparator = ",",
    decimalScale = 0,
    allowNegative = false,
    alwaysShowDecimals = false,
    inputMode = "decimal",
    focusOnMount,
    startAdornment,
    endAdornment,
    format,

    onChange,
    ...restProps
  },
  ref,
) {
  let formattedValue: string | number | null | undefined = value;
  if (alwaysShowDecimals && formattedValue) {
    formattedValue = Number(formattedValue).toFixed(decimalScale).replace(".", decimalSeparator);
  }

  const handleChange = useCallback(
    (e) => {
      let value = e.target.value;
      value = value && Number(value.replace(",", "."));
      if (isNaN(value)) value = "";
      onChange(value);
    },
    [onChange],
  );

  return (
    <NumberFormat
      {...restProps}
      customInput={TextField}
      value={formattedValue}
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      decimalSeparator={decimalSeparator}
      allowedDecimalSeparators={[",", "."]}
      InputProps={{
        startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
        endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
      }}
      inputProps={{autoComplete: "off", inputMode, ref, maxLength: 19}}
      fullWidth={true}
      className={cx(commonStyles.input(!!endAdornment), className)}
      variant="outlined"
      onChange={handleChange}
      autoFocus={focusOnMount}
      format={format}
      helperText={helperText && <BmInputHelperText helperText={helperText} />}
    />
  );
});

export interface BmControlledNumberInputProps
  extends ControlledInputProps,
    Omit<BmNumberInputProps, "onChange" | "value" | "error"> {
  className?: string;
  onChange?: (value: number | "") => void;
}

export const BmControlledNumberInput: React.FC<BmControlledNumberInputProps> = ({
  name,
  control,
  rules,
  defaultValue,
  helperText = "",
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleChange = useCallback(
    (e) => {
      onChange?.(e);
      field.onChange(e);
    },
    [field, onChange],
  );

  return (
    <BmNumberInput
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleChange}
    />
  );
};
