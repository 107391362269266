import Box from "@mui/material/Box";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Control, useWatch} from "react-hook-form";

import {BmWhiteButton} from "~/components/buttons";
import {BmControlledUploadImage} from "~/components/inputs";
import {BmFormTitle} from "~/components/titles";
import {BmDefaultImage} from "~/components/images";
import {BmHelperText} from "~/components/helperText";
import {designTokens} from "~/ui";

import {styles} from "./UploadSocialMediaImage.styles";

export interface BmUploadSocialMediaImageProps {
  title?: string;
  label: React.ReactNode;
  uploadBtnText: string;
  name: string;
  control: Control<any>;
  rules?: object;
  imageUrl?: string | null;
  onRemoveClick: VoidFunction;
}

export const BmUploadSocialMediaImage: React.FC<BmUploadSocialMediaImageProps> = ({
  title,
  label,
  uploadBtnText,
  name,
  control,
  rules,
  imageUrl,
  onRemoveClick,
}) => {
  const uploadImageField = useWatch({control, name});
  const [localImageUrl, setLocalImageUrl] = useState(imageUrl);

  const imageSrc = useMemo(() => {
    return uploadImageField ? URL.createObjectURL(uploadImageField) : localImageUrl;
  }, [localImageUrl, uploadImageField]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick();
    setLocalImageUrl("");
  }, [onRemoveClick]);

  useEffect(() => {
    setLocalImageUrl(imageUrl);
  }, [imageUrl]);

  return (
    <Box>
      {title && <BmFormTitle className={styles.title}>{title}</BmFormTitle>}
      <Box className={styles.labelWrapper}>
        <BmHelperText sx={{fontSize: designTokens.fontSize.md}}>
          {typeof label === "string" && <Box component="span" dangerouslySetInnerHTML={{__html: label}} />}
          {typeof label === "object" && label !== null && label}
        </BmHelperText>
      </Box>
      {!imageSrc && (
        <BmWhiteButton>
          <BmControlledUploadImage name={name} control={control} rules={rules} buttonText={uploadBtnText} />
        </BmWhiteButton>
      )}
      {imageSrc && (
        <BmDefaultImage
          src={imageSrc}
          alt="social media image"
          onRemoveClick={handleRemoveClick}
          imgClassName={styles.image}
        />
      )}
    </Box>
  );
};
