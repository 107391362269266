import {Box} from "@mui/material";

import {styles} from "./TaxWarehouseList.styles";

export interface TaxWarehousListProps {
  html: string;
}

export const TaxWarehousList: React.FC<TaxWarehousListProps> = ({html}) => {
  return <Box dangerouslySetInnerHTML={{__html: html}} className={styles.table} />;
};
