import {produce} from "immer";
import {useCallback, useReducer} from "react";

import {convertFromImageFileList} from "./converters";
import {IImageItem} from "./types";

enum ActionType {
  SET_LIST = "SET_LIST",
  ADD_LIST = "ADD_LIST",
  REMOVE_ITEM = "REMOVE_ITEM",
}

type SetImageListActionType = {
  type: ActionType.SET_LIST;
  payload: {
    imageList: IImageItem[];
  };
};

type AddImageListActionType = {
  type: ActionType.ADD_LIST;
  payload: {
    imageList: IImageItem[];
  };
};

type RemoveImageItemActionType = {
  type: ActionType.REMOVE_ITEM;
  payload: {
    imageIndex: number;
  };
};

type StateType = {imageList: IImageItem[]};
type ReducerActionType = SetImageListActionType | AddImageListActionType | RemoveImageItemActionType;

const uploadMultipleImagesReducer = (state: StateType, action: ReducerActionType): StateType => {
  switch (action.type) {
    case ActionType.SET_LIST: {
      const {imageList} = action.payload;
      return {imageList};
    }
    case ActionType.ADD_LIST: {
      const {imageList} = action.payload;
      return {imageList: [...state.imageList, ...imageList]};
    }
    case ActionType.REMOVE_ITEM: {
      const {imageIndex} = action.payload;
      return {
        imageList: produce(state.imageList, (draftImageList) => {
          draftImageList.splice(imageIndex, 1);
        }),
      };
    }
  }
};

type UseUploadMultipleImagesCallbacksType = {
  addAcceptedImages: (imageFileList: File[]) => void;
  removeImage: (index: number) => void;
  reorderImages: (reorderedImegeList: IImageItem[]) => void;
};

type UseUploadMultipleImagesReturnTuple = [IImageItem[], UseUploadMultipleImagesCallbacksType];

export const useUploadMultipleImages = (initImageList: IImageItem[]): UseUploadMultipleImagesReturnTuple => {
  const [{imageList}, dispatch] = useReducer(uploadMultipleImagesReducer, {imageList: initImageList});

  const addAcceptedImages = useCallback((imageFileList: File[]) => {
    const imageList = convertFromImageFileList(imageFileList);
    dispatch({
      type: ActionType.ADD_LIST,
      payload: {imageList},
    });
  }, []);

  const removeImage = useCallback((index: number) => {
    dispatch({
      type: ActionType.REMOVE_ITEM,
      payload: {imageIndex: index},
    });
  }, []);

  const reorderImages = useCallback((reorderedImegeList: IImageItem[]) => {
    dispatch({
      type: ActionType.SET_LIST,
      payload: {imageList: reorderedImegeList},
    });
  }, []);

  return [imageList, {addAcceptedImages, removeImage, reorderImages}];
};
