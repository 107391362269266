import React, {useCallback, useMemo} from "react";
import {useController} from "react-hook-form";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import get from "lodash.get";

import {BmWhiteButton} from "~/components/buttons";
import {BmUploadImage} from "~/components/inputs";
import {BmFormTitle} from "~/components/titles";
import {BmDefaultImage} from "~/components/images";
import {BmHelperText} from "~/components/helperText";

import {ControlledInputProps} from "../../_common/types";
import {styles} from "./UploadLogo.styles";

export interface BmUploadLogoProps {
  id?: string;
  name: string;
  value?: File | null;
  label: string;
  uploadBtnText: string;
  imgMaxWidth: string;
  error?: boolean;
  helperText: React.ReactNode;
  onChange: (value: File | null) => void;
}

export const BmUploadLogo = React.forwardRef<HTMLInputElement, BmUploadLogoProps>(function BmUploadLogo(
  {id, name, value, label, uploadBtnText, imgMaxWidth, helperText, error, onChange},
  ref,
) {
  const imageSrc = useMemo(() => (value ? URL.createObjectURL(value) : undefined), [value]);

  const handleRemoveClick = useCallback(() => {
    onChange(null);
  }, [onChange]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <BmFormTitle marginBottom={1}>{label}</BmFormTitle>
        <Box mb={2.5}>
          <BmHelperText error={error}>{helperText}</BmHelperText>
        </Box>
        <BmWhiteButton>
          <BmUploadImage ref={ref} id={id} name={name} buttonText={uploadBtnText} onChange={onChange} />
        </BmWhiteButton>
      </Grid>
      <Grid item xs={6} height={imageSrc ? "140px" : "auto"}>
        {imageSrc && (
          <BmDefaultImage
            src={imageSrc}
            alt="logo"
            wrapperClassName={styles.imgWrapper(imgMaxWidth)}
            onRemoveClick={handleRemoveClick}
            withShadow={false}
          />
        )}
      </Grid>
    </Grid>
  );
});

interface BmControlledUploadLogoProps extends ControlledInputProps, Omit<BmUploadLogoProps, "onChange" | "error"> {
  onChange?: (value: File | null) => void;
}

export const BmControlledUploadLogo: React.FC<BmControlledUploadLogoProps> = ({
  name,
  control,
  rules,
  helperText,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules});

  const handleOnChange = useCallback(
    (value: File | null) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmUploadLogo
      {...field}
      {...restProps}
      error={!!error}
      helperText={get(error, "message", helperText)}
      onChange={handleOnChange}
    />
  );
};
