import {css} from "@emotion/css";

import {designColors} from "~/ui";
import {getHoverColor} from "~/utils/colors";

export const styles = {
  tertiaryButton: css`
    &.MuiButton-root {
      color: ${designColors.colorInk};
      background-color: ${designColors.grey_1};

      &:hover {
        background-color: ${getHoverColor(designColors.grey_1, "darker")};
      }
      &,
      &:hover {
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
      }
    }
    &.MuiLoadingButton-loading {
      min-width: 100px;
    }
  `,
};
