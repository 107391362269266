import {curryN, __} from "ramda";
import MuiCheckbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiFormGroup from "@mui/material/FormGroup";
import {useController} from "react-hook-form";
import React, {useCallback} from "react";
import get from "lodash.get";
import {produce} from "immer";
import {cx} from "@emotion/css";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import {BmHelperText} from "~/components/helperText";
import {BmCheckboxCheckedIcon, BmCheckboxEmptyIcon} from "~/components/icons/simpleIcons";

import {styles} from "./CheckboxGroup.styles";
import {ICheckboxGroupOption, ICheckboxGroupOptionsMetaData} from "./ICheckboxGroup";
import {CommonInputType, ControlledInputProps} from "../_common/types";

type OnChangeType = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
type CurriedOnChangeType = (event: any, checked: any, index: number) => OnChangeType;

export interface BmCheckboxGroupProps extends CommonInputType {
  value: ICheckboxGroupOption[];
  optionsMetaData?: ICheckboxGroupOptionsMetaData;
  error?: boolean;
  helperText?: string;
  onChange: (value: ICheckboxGroupOption[]) => void;
}

export const BmCheckboxGroup = React.forwardRef<HTMLFieldSetElement, BmCheckboxGroupProps>(function BmCheckboxGroup(
  {className, name, value: options, optionsMetaData, error, helperText, onChange},
  ref,
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    curryN(3, (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, index: number) => {
      onChange(
        produce(options, (draftOptions) => {
          draftOptions[index]["checked"] = checked;
        }),
      );
    }) as any as CurriedOnChangeType,
    [onChange, options],
  );

  return (
    <FormControl
      error={error}
      component="fieldset"
      variant="standard"
      className={cx(styles.input, className)}
      ref={ref}
      name={name}
    >
      <MuiFormGroup>
        <Stack mt={1} spacing={1}>
          {options.map(({name, label, checked, helperText, disabled}, index) => (
            <Box key={name}>
              <FormControlLabel
                className={styles.label}
                control={
                  <MuiCheckbox
                    name={name}
                    checked={checked}
                    icon={<BmCheckboxEmptyIcon id="checkbox-empty" />}
                    checkedIcon={<BmCheckboxCheckedIcon id="checkbox-checked" />}
                    onChange={handleChange(__, __, index)}
                    className={cx(styles.checkbox, error && styles.error)}
                    disabled={disabled}
                  />
                }
                label={label}
              />
              {checked && optionsMetaData?.[name]?.underLabel}
              {helperText && <BmHelperText className={styles.helperText}>{helperText}</BmHelperText>}
            </Box>
          ))}
        </Stack>
      </MuiFormGroup>
      {helperText && <BmHelperText>{helperText}</BmHelperText>}
    </FormControl>
  );
});

export interface BmControlledCheckboxGroupProps
  extends ControlledInputProps,
    Omit<BmCheckboxGroupProps, "onChange" | "value" | "error"> {
  onChange?: (value: Object) => void;
}

export const BmControlledCheckboxGroup: React.FC<BmControlledCheckboxGroupProps> = ({
  name,
  control,
  rules,
  helperText,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules});

  const handleOnChange = useCallback(
    (value: Object) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  return (
    <BmCheckboxGroup
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
