import {useController} from "react-hook-form";
import React, {ChangeEventHandler, useCallback} from "react";
import get from "lodash.get";

import {BmChevronIcon} from "~/components/icons/simpleIcons";

import {ControlledInputProps} from "../../../../_common/types";
import {styles} from "./SecondaryDynamicSelect.styles";
import {BmDynamicSelect, BmDynamicSelectProps} from "../../DynamicSelect";

export interface BmSecondaryDynamicSelectProps extends BmDynamicSelectProps {}

export const BmSecondaryDynamicSelect = React.forwardRef<HTMLInputElement, BmSecondaryDynamicSelectProps>(
  function BmSecondaryDynamicSelect({options, onChange, ...restProps}, ref) {
    return (
      <BmDynamicSelect
        {...restProps}
        options={options}
        onChange={onChange}
        ref={ref}
        SelectProps={{
          IconComponent: BmChevronIcon,
          MenuProps: {disablePortal: true},
        }}
        className={styles.root}
      />
    );
  },
);

export interface BmControlledSecondaryDynamicSelectProps
  extends ControlledInputProps,
    Omit<BmSecondaryDynamicSelectProps, "onChange" | "value" | "error"> {
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

export const BmControlledSecondaryDynamicSelect: React.FC<BmControlledSecondaryDynamicSelectProps> = ({
  name,
  helperText,
  control,
  rules,
  defaultValue,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleChange = useCallback(
    (e) => {
      onChange?.(e);
      field.onChange(e);
    },
    [field, onChange],
  );

  return (
    <BmSecondaryDynamicSelect
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleChange}
    />
  );
};
