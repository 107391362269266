import {useMemo} from "react";
import MuiChip from "@mui/material/Chip";
import {cx} from "@emotion/css";

import {designColors} from "~/ui";

import {ChipType} from "../IChip";
import {commonStyles} from "../Chip.styles";

export const BmErrorChip: React.FC<ChipType> = ({label, variant = "outlined", className}) => {
  const colors = useMemo(
    () => ({
      textColor: designColors.red_3,
      bgColor: designColors.red_1,
      borderColor: designColors.red_3,
    }),
    [],
  );
  return <MuiChip label={label} variant={variant} className={cx(commonStyles.chip(colors, variant), className)} />;
};
