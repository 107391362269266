import {css} from "@emotion/css";

export const styles = {
  dateRange: css`
    .rdrDateDisplayItem input {
      cursor: default;
    }
    .rdrDateDisplayItemActive {
      border: transparent;
    }
  `,
};
