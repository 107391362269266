import {css} from "@emotion/css";

import {designColors, designTokens} from "~/ui";

export const autocompleteButtonStyles = {
  button: css`
    &.MuiMenuItem-root {
      padding: 12px 15px;
      border-top: 1px solid ${designColors.grey_2};
    }
    .MuiListItemText-root {
      margin-left: -5px;
    }
    .MuiTypography-root {
      color: ${designColors.colorInk};
      font-size: ${designTokens.fontSize.sm};
      font-weight: ${designTokens.fontWeight.medium};
    }
  `,
};
