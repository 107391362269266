import {MakerMarket} from "~/api/generated";
import {ALL_COUNTRIES_DATA_OBJECT, EU_CURRENCY} from "~/constants/countries";
import {ICountry} from "~/model/helperTypes/ICountries";

export const getCountryData = (countryCode: string): ICountry => {
  const countryData = Object.values(ALL_COUNTRIES_DATA_OBJECT).find(({COUNTRY_CODE}) => countryCode === COUNTRY_CODE);
  if (!countryData)
    throw new Error(`getCountryData(): passed country code - ${countryCode} - is not supported by the platform`);
  return countryData;
};

export const getCountryName = (countryCode: string): string => {
  return getCountryData(countryCode).NAME;
};

export const getCountryCurrency = (countryCode: string): string => {
  if (countryCode === "OTHER") return EU_CURRENCY;
  return getCountryData(countryCode).CURRENCY;
};

export const getCountryCodeByPhoneNumber = (phoneNumber: string): string | undefined => {
  let trimedPhoneNumber = phoneNumber.trim();
  if (!trimedPhoneNumber.length) return undefined;

  const suitableCountry = Object.values(ALL_COUNTRIES_DATA_OBJECT).find(({DIAL_CODE}) => {
    return trimedPhoneNumber.substring(1, DIAL_CODE.length + 1) === DIAL_CODE;
  });
  if (!suitableCountry) return undefined;

  return suitableCountry.COUNTRY_CODE;
};

export const getCountriesByMarkets = (markets: MakerMarket[]) => {
  return Object.values(
    markets.reduce(
      (accum, market) => {
        accum[market.country] = market;
        return accum;
      },
      {} as {[key: string]: MakerMarket},
    ),
  );
};

export const getCountryFlag = (countryCode: string) => {
  return ALL_COUNTRIES_DATA_OBJECT[countryCode].FLAG;
};
