import React, {FC} from "react";
import MuiLink from "@mui/material/Link";
import MuiMenuItem from "@mui/material/MenuItem";
import MuiListItemIcon from "@mui/material/ListItemIcon";
import MuiListItemText from "@mui/material/ListItemText";

import {designColors} from "~/ui";

import {IContextMenuItem} from "../IContextMenu";
import {styles} from "./ContextMenuItem.styles";

export interface ContextMenuItemProps extends IContextMenuItem {
  component?: React.ElementType;
  type?: "button" | "submit";
  color?: string;
}

export const BmContextMenuItem: FC<ContextMenuItemProps> = ({
  title,
  icon,
  onClick,
  href,
  target,
  component = "button",
  type = "button",
  color = designColors.colorInk,
}) => {
  return (
    <MuiMenuItem className={styles.contextMenuitem(color)}>
      <MuiLink
        className={styles.contextMenuLink}
        onClick={onClick}
        component={component}
        underline="none"
        href={href}
        target={target}
        type={type}
      >
        {icon && <MuiListItemIcon>{icon}</MuiListItemIcon>}
        <MuiListItemText>{title}</MuiListItemText>
      </MuiLink>
    </MuiMenuItem>
  );
};
