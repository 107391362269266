import {isValid} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import {omit} from "ramda";

import {FinanceOverviewFilter, TransactionFilter, TransactionKind} from "~/api/generated";
import {getEnumValues} from "~/utils/enums";

import {useBackendFiltering} from "../useBackendFiltering";

const allowedFinanceOverviewFilters: (keyof FinanceOverviewFilter)[] = ["fromDate", "toDate"];
const allowedTransactionFilters: (keyof TransactionFilter)[] = ["fromDate", "toDate", "kind", "search"];

export const searchParamsToAppliedFinanceOverviewFilters = (searchParams: URLSearchParams): FinanceOverviewFilter => {
  const nextAppliedFinanceOverviewFilters = ([...searchParams] as [keyof FinanceOverviewFilter, any][])
    .filter(([searchParamKey, searchParamValue]) => {
      switch (searchParamKey) {
        case "fromDate":
        case "toDate":
          return isValid(utcToZonedTime(searchParamValue, "GMT"));
        default:
          return false;
      }
    })
    .reduce((filters, [searchParamKey, searchParamValue]) => {
      return {
        ...filters,
        [searchParamKey]: searchParamValue,
      };
    }, {} as FinanceOverviewFilter);

  const {fromDate, toDate} = nextAppliedFinanceOverviewFilters;
  return [fromDate, toDate].every(Boolean)
    ? nextAppliedFinanceOverviewFilters
    : omit(["fromDate", "toDate"], nextAppliedFinanceOverviewFilters);
};

export const searchParamsToAppliedTransactionsFilters = (searchParams: URLSearchParams): TransactionFilter => {
  const nextAppliedTransactionsFilters = ([...searchParams] as [keyof TransactionFilter, any][])
    .filter(([searchParamKey, searchParamValue]) => {
      switch (searchParamKey) {
        case "fromDate":
        case "toDate":
          return isValid(utcToZonedTime(searchParamValue, "GMT"));
        case "kind":
          return getEnumValues(TransactionKind).includes(searchParamValue);
        case "search":
          return typeof searchParamValue === "string" && searchParamValue.length > 0;
        default:
          return false;
      }
    })
    .reduce((filters, [searchParamKey, searchParamValue]) => {
      return {
        ...filters,
        [searchParamKey]: ["kind"].includes(searchParamKey)
          ? [...(filters[searchParamKey] || []), searchParamValue]
          : searchParamValue,
      };
    }, {} as TransactionFilter);

  const {fromDate, toDate} = nextAppliedTransactionsFilters;
  return [fromDate, toDate].every(Boolean)
    ? nextAppliedTransactionsFilters
    : omit(["fromDate", "toDate"], nextAppliedTransactionsFilters);
};

export const useBackendFinanceOverviewFiltering = (): [
  FinanceOverviewFilter,
  (filters: FinanceOverviewFilter) => void,
] => {
  const [appliedFilters, onFiltersChange] = useBackendFiltering<FinanceOverviewFilter>(
    allowedFinanceOverviewFilters,
    searchParamsToAppliedFinanceOverviewFilters,
  );

  return [appliedFilters, onFiltersChange];
};

export const useBackendTransactionsFiltering = (): [TransactionFilter, (filters: TransactionFilter) => void] => {
  const [appliedFilters, onFiltersChange] = useBackendFiltering<TransactionFilter>(
    allowedTransactionFilters,
    searchParamsToAppliedTransactionsFilters,
  );

  return [appliedFilters, onFiltersChange];
};
