import get from "lodash.get";
import React, {ChangeEventHandler, useCallback} from "react";
import {useController} from "react-hook-form";

import {ControlledInputProps} from "..";
import {BmRadioGroup} from "../RadioGroup";
import {BmDynamicSelect} from "../Select";

export interface RadioSelectOption {
  label: React.ReactNode;
  value: string;
}

export interface BmRadioSelectProps {
  id?: string;
  name: string;
  label?: string;
  className?: string;
  options: RadioSelectOption[];
  value?: string | null;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export const BmRadioSelect = React.forwardRef<HTMLInputElement, BmRadioSelectProps>(function BmRadioSelect(props, ref) {
  return props.options.length <= 5 ? <BmRadioGroup ref={ref} {...props} /> : <BmDynamicSelect ref={ref} {...props} />;
});

export interface BmControlledRadioSelectProps
  extends ControlledInputProps,
    Omit<BmRadioSelectProps, "onChange" | "value" | "error"> {
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const BmControlledRadioSelect: React.FC<BmControlledRadioSelectProps> = ({
  name,
  defaultValue,
  control,
  rules,
  helperText,
  onChange,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules, defaultValue});

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      field.onChange(e);
    },
    [field, onChange],
  );

  return (
    <BmRadioSelect
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onChange={handleOnChange}
    />
  );
};
