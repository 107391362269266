import MuiSelect from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {SelectInputProps} from "@mui/material/Select/SelectInput";

import {IBaseSelectProps, ISelectOption} from "~/components/inputs";
import {BmChevronIcon} from "~/components/icons/simpleIcons";

import {staticSelectStyles} from "./StaticSelect.styles";

export interface BmStaticSelectProps extends IBaseSelectProps {
  options: ISelectOption[];
  onChange?: SelectInputProps<string>["onChange"];
}

export const BmStaticSelect: React.FC<BmStaticSelectProps> = ({options, value, label, onChange, sx}) => {
  return (
    <MuiSelect
      displayEmpty
      value={value}
      onChange={onChange}
      variant="outlined"
      fullWidth={true}
      sx={sx}
      className={staticSelectStyles.select}
      IconComponent={BmChevronIcon}
      MenuProps={{disablePortal: true}}
    >
      <MenuItem disabled value="" className={staticSelectStyles.placeholder}>
        {label}
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.label} value={option.entity}>
          <Box dangerouslySetInnerHTML={{__html: option.label}} />
        </MenuItem>
      ))}
    </MuiSelect>
  );
};
