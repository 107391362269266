import {css} from "@emotion/css";

import {designTokens} from "~/ui";

export const styles = {
  select: css`
    .MuiList-root {
      li {
        &:not(.Mui-disabled) {
          font-size: ${designTokens.fontSize.sm};
          font-weight: ${designTokens.fontWeight.medium};
          padding: 12px 14px;
          padding-right: 30px;
        }
      }
    }
  `,
  placeholder: css`
    height: 0;
    min-height: 0 !important;
    padding: 0 !important;

    opacity: 0;
  `,
};
