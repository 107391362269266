import {AsYouType} from "libphonenumber-js";

export const formatPhoneNumber = (value: string, countryCallingCode: string) => {
  const inputValue = value.replace(/\D/g, ""); // Remove non-digit characters

  const asYouType = new AsYouType();
  const fullNumber = `+${countryCallingCode}${inputValue}`;
  const formattedNumber = asYouType.input(fullNumber);

  // Remove country calling code from the formatted number for display
  return formattedNumber.replace(`+${countryCallingCode}`, "").trim();
};
