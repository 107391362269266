import {cx} from "@emotion/css";

import {designColors} from "~/ui";
import {ReactComponent as SvgGlobe} from "~/assets/svg/globe.svg";

import {simpleIconStyles} from "../styles";
import {commonStyles} from "../../_common/styles";
import {IIconBase} from "../../_common/types";

export const BmGlobeIcon: React.FC<IIconBase> = ({id, size = "sm", color = designColors.black, className}) => {
  return <SvgGlobe id={id} className={cx(simpleIconStyles(size), commonStyles.color(color), className)} />;
};
