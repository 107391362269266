import Typography from "@mui/material/Typography";
import {Box, Stack, SxProps, Theme} from "@mui/material";
import React, {useCallback} from "react";

import {BmTooltip} from "~/components/tooltip";
import {BmUnstyledBaseButton} from "~/components/buttons";
import {getPaddingBySpacingSize} from "~/ui";

import {styles} from "./SelectorCard.styles";
import {ISelectorOption} from "../../ISelector";

interface BmSelectorCardProps extends ISelectorOption {
  sx?: SxProps<Theme>;
  onClick: (value: string) => void;
  selected?: boolean;
  disabled?: boolean;
  invalid?: boolean;
}

export const BmSelectorCard = React.forwardRef<HTMLButtonElement, BmSelectorCardProps>(function BmSelectorCard(
  {sx, value, selected, disabled, invalid, title, description, illustration, tooltipTitle = "", onClick},
  ref,
) {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <BmUnstyledBaseButton
      ref={ref}
      fullWidth
      onClick={handleClick}
      id={value}
      disabled={disabled}
      sx={{height: "100%", ...sx}}
    >
      <BmTooltip title={tooltipTitle} className={styles.tooltip}>
        <Stack
          className={styles.root(selected, disabled, invalid)}
          sx={{p: getPaddingBySpacingSize("md")}}
          spacing={3}
          direction="row"
          useFlexGap
        >
          <Box flex="auto">
            <Typography variant="h6" className={styles.title} sx={{mb: 1}}>
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              className={styles.subtitle}
              dangerouslySetInnerHTML={{__html: description}}
            />
          </Box>
          {illustration && (
            <Box flex="0" width="48px">
              {illustration}
            </Box>
          )}
        </Stack>
      </BmTooltip>
    </BmUnstyledBaseButton>
  );
});
