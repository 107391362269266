import React, {useCallback} from "react";
import {useController} from "react-hook-form";
import get from "lodash.get";
import debounce from "lodash.debounce";

import {noop} from "~/utils/noop";

import {ControlledInputProps} from "../../_common/types";
import {BmAutocomplete} from "../Autocomplete";
import {BmAutocompleteProps, IAutocompleteOption} from "../IAutocomplete";

export const BmDebouncedAutocomplete = React.forwardRef<HTMLDivElement, BmAutocompleteProps>(
  function BmDebouncedAutocomplete({onInputChange = noop, ...props}, ref) {
    return <BmAutocomplete {...props} ref={ref} onInputChange={debounce(onInputChange, 600)} />;
  },
);

export interface BmControlledDebouncedAutocompleteProps
  extends ControlledInputProps,
    Omit<BmAutocompleteProps, "onSelectOption" | "value" | "error"> {
  onSelectOption?: (value: IAutocompleteOption | null) => void;
}

export const BmControlledDebouncedAutocomplete: React.FC<BmControlledDebouncedAutocompleteProps> = ({
  name,
  control,
  rules,
  onSelectOption,
  helperText,
  ...restProps
}) => {
  const {
    field,
    fieldState: {error},
  } = useController({name, control, rules});

  const handleSelectOption = useCallback(
    (value) => {
      onSelectOption?.(value);
      field.onChange(value);
    },
    [field, onSelectOption],
  );

  return (
    <BmDebouncedAutocomplete
      {...field}
      {...restProps}
      helperText={get(error, "message", helperText)}
      error={!!error}
      onSelectOption={handleSelectOption}
    />
  );
};
