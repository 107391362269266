import {css} from "@emotion/css";

import {designColors, designTokens, designUtils} from "~/ui";

export const styles = {
  table: css`
    .frozen-report {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 1.5rem;
    }

    .fr-data:first-child {
      width: 100%;
      overflow-x: auto;

      h3 {
        display: none;
      }
    }

    .fr-data:last-child table {
      th,
      td {
        border-left: none;
        border-right: none;
      }
    }

    a {
      color: ${designColors.primary};
    }

    table {
      width: 100%;
      border-collapse: collapse;

      p {
        margin: 0;
      }
    }

    thead {
      tr {
        border-bottom: 1px solid ${designColors.grey_2};
      }
      th {
        white-space: nowrap;
      }
    }

    tbody tr {
      border-top: 1px solid ${designColors.grey_2};
      border-bottom: 1px solid ${designColors.grey_2};

      &:nth-child(2n) {
        background: ${designColors.white};
      }
      &:nth-child(2n + 1) {
        background: ${designColors.off_white};
      }
    }

    th,
    td {
      padding: 12px 6px;

      border-right: 1px solid ${designColors.grey_2};
      border-left: 1px solid ${designColors.grey_2};
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }

    td {
      ${designUtils.baseFontStyles};
      position: relative;
      font-weight: ${designTokens.fontWeight.regular};
      vertical-align: center;
    }

    th {
      background-color: ${designColors.grey_1};
      font-weight: ${designTokens.fontWeight.semiBold};
    }

    .text-left {
      text-align: left;
    }

    .text-right {
      text-align: right;
    }
  `,
};
